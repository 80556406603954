import React, { useState, useEffect, useRef, useContext } from 'react';
import { init } from 'filestack-js';
import 'cropperjs/dist/cropper.css';
import { AppContext, MainStateModal } from '../../context/Contex';
import style from './UploaderStyles.module.css'
import { windowDetails } from '../../constants/data';


const UploaderCameo = () => {

    const client = init('AbkDGv2FYSHi0mxqhYMgQz');

    const {hasErrors, isSaveAttempted, urlCameo, setUrlCameo, selectedtypeCameoOkienko} = useContext(MainStateModal)
    const {albumSize} = useContext(AppContext)

    const getRatio = () => {
        return windowDetails[`${albumSize.size_width * 2.5}x${albumSize.size_height * 2.5}`][selectedtypeCameoOkienko].dimensions;
    }

    const handleUpload = () => {
        const { width, height } = getRatio();
        
        const aspectRatioValue = width / height;
        
        const options = {
            accept: 'image/jpg',
            maxSize: 1500 * 300,
            onUploadDone: (res) => {
                setUrlCameo(res.filesUploaded[0].url);
                console.log('File Uploaded:', res.filesUploaded[0].url);
            },
            transformations: {
                crop: {
                     aspectRatio: aspectRatioValue,
                     force: true
                 },
            }
        };
        
        client.picker(options).open();
    };


    return (
        <div>
            <div>
                <button type="button" className = {style.buttonUploadLogo} onClick={handleUpload}>ZAŁADUJ PLIK</button> 
                {hasErrors && isSaveAttempted && urlCameo === '' && <p style={{ color: 'red', marginTop: '7px' }}>Musisz załadować zdjęcie!</p>}
            </div>
        </div>
    );
};

export default UploaderCameo;

