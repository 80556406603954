import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './CameoAndOkladka.module.css'
import { AppContext, MainStateModal } from '../../../../context/Contex';
import { baseFontSizesFor1000px, colorsOkladkaWizualizator, fontClassesWizualizator } from '../../../../constants/dataTloczenie/dataTloczenieOkladka';
import {  windowDetails } from '../../../../constants/data'

const CameoAndTloczenieWizualizator = () => {
    
    const {albumSize} = useContext(AppContext)
    const {selectedtypeCameoOkienko, urlCameo, selectedOpcjaOkienka,windowStyle, setWindowStyle, formData} = useContext(MainStateModal)
    
    const resizeObserver = useRef(null);
    const containerRefCameoandOkladka = useRef(null)
    const [imageStyleZRamka, setImageStyleZRamka] = useState('')
    const [paddingHalfCm, setPaddingHalfCm] = useState(0);

    const [okladkaRozmiarCzcionkiCombo1, setOkladkaRozmiarCzcionkiCombo1] = useState(null);
    const [okladkaRozmiarCzcionkiCombo2, setOkladkaRozmiarCzcionkiCombo2] = useState(null);

    const[szerokoscKontainerTloczenie, setSzerokoscKontainerTloczenie] = useState({width : ''})

    const [odstepTexstOkladka, setOdstepTexstOkladka] = useState(0);
    const [odstepOdGoryDlaTekstaTloczenie, setOdstepOdGoryDlaTekstaTloczenie] = useState({
        top: 0,
    })

    const calculateWindowDetails = (windowDetails, albumSizeKey, windowType, oneCM, marginModification = 0) => {
        if (!windowDetails[albumSizeKey] || !windowDetails[albumSizeKey][windowType]) {
            return {};
        }
    
        return {
            dimensions: {
                width: (windowDetails[albumSizeKey][windowType].dimensions.width) * oneCM,
                height: (windowDetails[albumSizeKey][windowType].dimensions.height) * oneCM,
            },
            margins: {
                top: (windowDetails[albumSizeKey][windowType].margins.top + marginModification) * oneCM,
                left: (windowDetails[albumSizeKey][windowType].margins.left + marginModification) * oneCM,
            },
        };
    };
    
    // const modifyDimensionsWithoutFrame = (dimensions, oneCM, frameModification) => {
    //     return {
    //         width: dimensions.width + frameModification * oneCM,
    //         height: dimensions.height + frameModification * oneCM,
    //     };
    // };
    
    const getDimensionsAndMargins = (windowDetails, windowType, albumSize, containerWidth, frameOption) => {
        const albumSizeInPX = albumSize.size_width * 2.50;  
        const albumSizeKey = `${albumSizeInPX}x${albumSizeInPX}`; 
        const oneCM = containerWidth / (albumSizeInPX + 1);
        const halfCm = oneCM / 2;
    
        let frameModification = 0;
        let marginModification = 0;
    
        let details = calculateWindowDetails(windowDetails, albumSizeKey, windowType, oneCM);
    
        if (frameOption === 'Bez ramki') {
            frameModification = 0; 
            // if (details.dimensions && details.margins) {
            //     details.dimensions = modifyDimensionsWithoutFrame(details.dimensions, oneCM, frameModification);
            //     details.margins = modifyDimensionsWithoutFrame(details.margins, oneCM, marginModification);
            // }
            windowDetails = calculateWindowDetails(albumSizeKey, windowType, oneCM + frameModification, marginModification);
        }
        
        if (frameOption === 'Z ramką') {
            frameModification = -1; 
        
            if (details.dimensions && details.margins) {
                const rozmiarOkienkaImg = {
                    width: details.dimensions.width - halfCm,
                    height: details.dimensions.height - halfCm,
                };
        
                const marginsImg = {
                    top: halfCm / 2,
                    left: halfCm / 2,
                };
        
                return { ...details, rozmiarOkienkaImg, marginsImg };
            }
        }
        
        return { ...details, halfCm };
    };
    //liczenie odstepu dla tekstu overprint od samej gory 
    const calculateOverprintPosition = (windowDetails, windowType, albumSize, containerWidth) => {
        const albumSizeInPX = albumSize.size_width * 2.50;  
        const albumSizeKey = `${albumSizeInPX}x${albumSizeInPX}`;
        const oneCM = containerWidth / (albumSizeInPX + 1);

        if (!windowDetails[albumSizeKey] || !windowDetails[albumSizeKey][windowType]) {
            return {}; 
        }
        const top = windowDetails[albumSizeKey][windowType].margins.top * oneCM + windowDetails[albumSizeKey][windowType].dimensions.height * oneCM + 1.5 * oneCM; 
        return {top: `${top}px`};
    };

    useEffect(() => {
        if(!containerRefCameoandOkladka.current) return
    
        const updateDimensions = () => {
            if(!containerRefCameoandOkladka.current) return
            
            const containerWidth = containerRefCameoandOkladka.current.offsetWidth;
            const { dimensions, margins, rozmiarOkienkaImg, marginsImg, halfCm } = getDimensionsAndMargins(windowDetails, selectedtypeCameoOkienko, albumSize, containerWidth, selectedOpcjaOkienka);
        
            setWindowStyle({
                width: `${dimensions.width}px`,
                height: `${dimensions.height}px`,
                marginTop: `${margins.top}px`,
                marginLeft: `${margins.left}px`,
            }); 
    
            setOdstepOdGoryDlaTekstaTloczenie(calculateOverprintPosition(windowDetails, selectedtypeCameoOkienko, albumSize, containerWidth));
    
            if (rozmiarOkienkaImg && marginsImg) {
                setImageStyleZRamka({
                    width: `${rozmiarOkienkaImg.width}px`,
                    height: `${rozmiarOkienkaImg.height}px`,
                    marginTop: `${marginsImg.top}px`,
                    marginLeft: `${marginsImg.left}px`,
                });
            } else {
                setImageStyleZRamka({
                    width: `calc(100% + ${2 * halfCm}px)`,
                    height: `calc(100% + ${2 * halfCm}px)`,
                    position: 'absolute',
                    top: `-${halfCm}px`,
                    left: `-${halfCm}px`,
                    
                });
            }
            setPaddingHalfCm(halfCm);
        }
        updateDimensions();
    
        
        if ('ResizeObserver' in window) {
            resizeObserver.current = new ResizeObserver(updateDimensions);
            resizeObserver.current.observe(containerRefCameoandOkladka.current);
        }
    
        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }
        };
    
    },[selectedtypeCameoOkienko, albumSize, selectedOpcjaOkienka, containerRefCameoandOkladka])
    


    useEffect(() => {
        if(!containerRefCameoandOkladka.current) return;
    
        const albumSizeInPX = albumSize.size_width * 2.50;
        const containerWidth = containerRefCameoandOkladka.current.offsetWidth;
        const oneCM = containerWidth / (albumSizeInPX + 1);
    
        const odstepOdGlownegoKontainera = 2;
        const calculatedOdstępContaineraContent = oneCM * odstepOdGlownegoKontainera
        const szerokoscKontaineraZContentem = containerWidth - calculatedOdstępContaineraContent
    
        setSzerokoscKontainerTloczenie({
            width: szerokoscKontaineraZContentem
        })
    
    }, [albumSize, containerRefCameoandOkladka, containerRefCameoandOkladka.current?.offsetWidth]);
    
    useEffect(() => {
        const updateContainerWidth = () => {
            if (containerRefCameoandOkladka.current) {
                const albumSizeInPX = albumSize.size_width * 2.50;
                const containerWidth = containerRefCameoandOkladka.current.offsetWidth;
                const oneCM = containerWidth / (albumSizeInPX + 1);
    
                let paddingFactorOkladka;
                switch (albumSizeInPX) {
                    case 30: paddingFactorOkladka = 3.5; break;
                    case 25: paddingFactorOkladka = 3; break;
                    case 20: paddingFactorOkladka = 2; break;
                    case 15: paddingFactorOkladka = 1; break;
                    case 10: paddingFactorOkladka = 1; break;
                    default: paddingFactorOkladka = 1;
                }
    
                const calculatedPaddingOkladka = oneCM * paddingFactorOkladka;
                setOdstepTexstOkladka(calculatedPaddingOkladka)
            }
        }
    
        updateContainerWidth();
    
        const resizeObserver = new ResizeObserver(updateContainerWidth);
        resizeObserver.observe(containerRefCameoandOkladka.current);
    
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [albumSize, containerRefCameoandOkladka]);


    useEffect(() => {
        const updateFontSizes = () => {
            const windowWidth = containerRefCameoandOkladka.current.offsetWidth;
            const albumKey = `${albumSize.size_height * 2.5}x${albumSize.size_width * 2.5}`;
            const scaleFactor = windowWidth / 1000;
    
            if (baseFontSizesFor1000px[albumKey]) {
                setOkladkaRozmiarCzcionkiCombo1(baseFontSizesFor1000px[albumKey][formData.selectedFont1] * scaleFactor);
                setOkladkaRozmiarCzcionkiCombo2(baseFontSizesFor1000px[albumKey][formData.selectedFont2] * scaleFactor);
            }
        };
    
        const currentRef = containerRefCameoandOkladka.current; 
    
        const resizeObserver = new ResizeObserver(updateFontSizes);
        if (currentRef) {
            resizeObserver.observe(currentRef);
        }
    
        window.addEventListener('resize', updateFontSizes);
    
        return () => {
            if (currentRef) {
                resizeObserver.unobserve(currentRef); 
            }
            window.removeEventListener('resize', updateFontSizes);
        };
    }, [albumSize, formData.selectedFont1, formData.selectedFont2, containerRefCameoandOkladka]);

    const colorClass = colorsOkladkaWizualizator[formData.selectedColor] || '';
    const fontClass1 = fontClassesWizualizator[formData.selectedFont1] || '';
    const fontClass2 = fontClassesWizualizator[formData.selectedFont2] || '';

    return (
        <>
            <div className={style.cameoTloczenie_container} ref={containerRefCameoandOkladka}>
                <div className={style.cameoAndTloczenieContentContainer} style={{width: `${szerokoscKontainerTloczenie.width}px`}}>
                    <div className={style.okienko_cameo} style={{...windowStyle,backgroundColor: selectedtypeCameoOkienko ? 'white' : '', overflow: selectedtypeCameoOkienko ? 'hidden' : '',}}>
                        {urlCameo ? 
                            <img className={style.img_Cameo} src={urlCameo} alt="Uploaded" style={imageStyleZRamka}/>
                            : selectedtypeCameoOkienko  ? <div style={{backgroundColor: 'white', width: '100%', height: '100%'}} /> : null
                        }
                    </div>
                    <div className={style.containerText} style={{...odstepOdGoryDlaTekstaTloczenie, marginBottom: `${odstepTexstOkladka}px`}}>
                        <div className={style[colorClass]}>
                            <p className={style[fontClass1]} style={{margin: '0px',fontSize: okladkaRozmiarCzcionkiCombo1 }}>{formData.line1}</p>
                            <p className={style[fontClass2]} style={{margin: '0px', fontSize: okladkaRozmiarCzcionkiCombo2 }}>{formData.line2}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    
};

export default CameoAndTloczenieWizualizator;


