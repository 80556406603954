import React, { useContext, useState } from 'react';
import style from './UploaderStyles.module.css';
import { AppContext, MainStateModal } from '../../context/Contex';
import { init } from 'filestack-js';
import CropperModal from '../../utilities/cropperModal/CropperModal';
import axios from 'axios';
import sha1 from 'sha1';


const UploaderPhotopanel = () => {

    const client = init('AbkDGv2FYSHi0mxqhYMgQz');

    const { hasErrors, isSaveAttempted, urlPhotopanel, setUrlPhotopanel } = useContext(MainStateModal);
    const { albumSize } = useContext(AppContext);

    const [isCropperOpen, setIsCropperOpen] = useState(false);
    const [tempImageUrl, setTempImageUrl] = useState('');  

    const generateHash = () => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}${String(currentDate.getMonth() + 1).padStart(2, '0')}${String(currentDate.getDate()).padStart(2, '0')}`;
        return sha1(`${formattedDate}QTalbumsFilestack`);
    };

    const sendFileInfoToServer = (filename, handle) => {
        axios.post("https://filestack.qtalbums.ovh/token_to_db.php", {
            hash: generateHash(),
            files: filename + '^' + handle,
            source: 'snapalbums'
        })
        .then(response => {
            console.log("File info sent successfully:", response.data);
        })
        .catch(error => {
            console.error("Error sending file info:", error);
        });
    };


    const handleUpload = () => {
        const options = {
            accept: 'image/jpg',
            maxSize: 2 * 2024 * 2024,
            onUploadDone: (res) => {
                setIsCropperOpen(true);
                setTempImageUrl(res.filesUploaded[0].url);  
                const file = res.filesUploaded[0];
                sendFileInfoToServer(file.filename, file.handle);
            },
            transformations: {
                crop: false,
                circle: false,
                rotate: false
            }
        };
        
        client.picker(options).open();
    };


    const handleCrop = (croppedImageUrl) => {
        setIsCropperOpen(false);
        setUrlPhotopanel(croppedImageUrl);
    };
/* {isPopupVisable.isPopupVisableWhenChangingOkienkoTypeLOGO && isStuidoLOGO && <Popup isVisible={isPopupVisable.isPopupVisableWhenChangingOkienkoTypeLOGO} text= { showTekstWhenChangedRozmiar ? `Wybrany rozmiar matrycy różni się od rozmiaru dodanego pliku. Dla wybranego rozmiaru matrycy odpowiedni rozmiar pliku to: ${sizesPictureToUpload[rodzajOkienkaStudioLOGO].maxSize}px` : ` Dodany plik nie odpowiada rozmiarom wybranej matrycy. Wybierz plik o rozmiarze: ${sizesPictureToUpload[rodzajOkienkaStudioLOGO].maxSize}px`} onClose={handleClosePopup} />} */

    return (
        <div>
            {isCropperOpen && <CropperModal isCropperOpen = {isCropperOpen}  setIsCropperOpen = {setIsCropperOpen} imageUrl={tempImageUrl} onCrop={handleCrop} />}

            <button className={style.buttonUploadLogo} type="button" onClick={handleUpload}>ZAŁADUJ PLIK</button>
            {hasErrors && isSaveAttempted && !urlPhotopanel && <p style={{ color: 'red', marginTop: '7px' }}>Musisz wybrać zdjęcie</p>}
        </div>
    );
};

export default UploaderPhotopanel;

