import  React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import style from './CameoOverprint.module.css'
import { AppContext, MainStateModal } from '../../../../context/Contex';
import { overprintColorsWizualizator } from '../../../../constants/dataOverprint/dataOverprint';
import {  windowDetails, fontsMappingWizualizator } from '../../../../constants/data'
import { useResponsiveFontSize } from '../../../../hooks/useResponsiveFontSize';


const CameoAndOverprintWizualizator = () => {
    
    const {albumSize} = useContext(AppContext)
    const {formData, sizePixels,selectedtypeCameoOkienko, urlCameo, selectedOpcjaOkienka,windowStyle, setWindowStyle, setIsPopupVisable, lineHeightOverprint} = useContext(MainStateModal)

    const [fontSizeLineOverprintCombo1, setFontSizeLineOverprintCombo1] = useState("20px");
    const [fontSizeLineOverprintCombo2, setFontSizeLineOverprintCombo2] = useState("20px");
    const [fontSizeLineOverprintCombo3, setFontSizeLineOverprintCombo3] = useState("20px");

    const [canShowPopupOverprintAndCameo, setCanShowPopupOverprintAndCameo] = useState(true);
    const containerRefOverprintAndCameoPopup = useRef(null);
    const textBlockRefOverprintAndCameoPopup= useRef(null);
    const resizeObserver = useRef(null);
    const containerRefCameoAndOverprint = useRef(null)
    
    const [imageStyleZRamka, setImageStyleZRamka] = useState('')
    const [paddingHalfCm, setPaddingHalfCm] = useState(0);
    // odstep gora 
    const [odstepOdGoryDlaTeksta, setOdstepOdGoryDlaTeksta] = useState({
        top: 0,
    })
    const [szerokoscKontaineraWithContens, setSzerokoscKontaineraWithContents] = useState({
        width:0
    })
    const [overprintAndCameRamkaOdstep, setOverprintAndCameRamkaOdstep] = useState({
        left: 0,
    });

    const calculateWindowDetails = (albumSizeKey, windowType, oneCM, marginModification = 0) => {
        if (!windowDetails[albumSizeKey] || !windowDetails[albumSizeKey][windowType]) {
            return {};
        }
    
        return {
            dimensions: {
                width: (windowDetails[albumSizeKey][windowType].dimensions.width) * oneCM,
                height: (windowDetails[albumSizeKey][windowType].dimensions.height) * oneCM,
            },
            margins: {
                top: (windowDetails[albumSizeKey][windowType].margins.top + marginModification) * oneCM,
                left: (windowDetails[albumSizeKey][windowType].margins.left + marginModification) * oneCM,
            },
        };
    };

    const getDimensionsAndMargins = (windowType, albumSize, containerWidth, frameOption) => {
        const albumSizeInPX = albumSize.size_width * 2.50;  
        const albumSizeKey = `${albumSizeInPX}x${albumSizeInPX}`;
        const oneCM = containerWidth / (albumSizeInPX + 1);
        const halfCm = oneCM / 2;
    
        let frameModification = 0;
        let marginModification = 0;
    
        let windowDetails = calculateWindowDetails(albumSizeKey, windowType, oneCM);
    
        if (frameOption === 'Bez ramki') {
            frameModification = 0; 
            // marginModification = 1;
    
            windowDetails = calculateWindowDetails(albumSizeKey, windowType, oneCM + frameModification, marginModification);
        }
    
        if (frameOption === 'Z ramką') {
            frameModification = -1; 
    
            const windowDetailsImg = {
                width: windowDetails.dimensions.width - halfCm,
                height: windowDetails.dimensions.height - halfCm,
            };
    
            const marginsImg = {
                top: halfCm / 2,
                left: halfCm / 2,
            };
    
            return { windowDetails, windowDetailsImg, marginsImg, halfCm };
        }
    
        return { windowDetails, halfCm };
    };
    //liczenie odstepu dla tekstu overprint od samej gory 
    const calculateOverprintPosition = (windowType, albumSize, containerWidth) => {
        const albumSizeInPX = albumSize.size_width * 2.50;  
        const albumSizeKey = `${albumSizeInPX}x${albumSizeInPX}`;
        const oneCM = containerWidth / (albumSizeInPX + 1);
        if (!windowDetails[albumSizeKey] || !windowDetails[albumSizeKey][windowType]) {
            return {}; 
        }
        const top = windowDetails[albumSizeKey][windowType].margins.top * oneCM + windowDetails[albumSizeKey][windowType].dimensions.height * oneCM + 1.5 * oneCM; 
        return {top: `${top}px`};
    };

    useEffect(() => {
        if(!containerRefCameoAndOverprint.current) return
    
        const updateDimensions = () => {
            if(!containerRefCameoAndOverprint.current) return

            const containerWidth = containerRefCameoAndOverprint.current.offsetWidth;
            const { windowDetails, windowDetailsImg, marginsImg, halfCm } = getDimensionsAndMargins(selectedtypeCameoOkienko, albumSize, containerWidth, selectedOpcjaOkienka);
         
            setWindowStyle({
                width: `${windowDetails.dimensions.width}px`,
                height: `${windowDetails.dimensions.height}px`,
                marginTop: `${windowDetails.margins.top}px`,
                marginLeft: `${windowDetails.margins.left}px`,
            }); 
            setOdstepOdGoryDlaTeksta(calculateOverprintPosition(selectedtypeCameoOkienko, albumSize, containerWidth));
    
            if (windowDetailsImg && marginsImg) {
                setImageStyleZRamka({
                    width: `${windowDetailsImg.width}px`,
                    height: `${windowDetailsImg.height}px`,
                    marginTop: `${marginsImg.top}px`,
                    marginLeft: `${marginsImg.left}px`,
                });
            } else {
                setImageStyleZRamka({
                    width: `calc(100% + ${2 * halfCm}px)`,
                    height: `calc(100% + ${2 * halfCm}px)`,
                    position: 'absolute',
                    top: `-${halfCm}px`,
                    left: `-${halfCm}px`,
                });
            }
            setPaddingHalfCm(halfCm);
        }
        updateDimensions();
    
        
        if ('ResizeObserver' in window) {
            resizeObserver.current = new ResizeObserver(updateDimensions);
            resizeObserver.current.observe(containerRefCameoAndOverprint.current);
        }
    
        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }
        };
    
    },[selectedtypeCameoOkienko, albumSize, containerRefCameoAndOverprint, selectedOpcjaOkienka])

    
    //odstepy ramek kontainera przy overprintie
    useEffect(() => {
        const updateContainerWidth = () => {
            if (containerRefCameoAndOverprint.current) {
                const albumSizeInPX = albumSize.size_width * 2.50;
                const containerWidth = containerRefCameoAndOverprint.current.offsetWidth;
                const oneCM = containerWidth / (albumSizeInPX + 1);
                const paddingFactorOverprintLeft = 1.5;
                const odstępContaineraContent = 2;

                const calculatedOdstępContaineraContent = oneCM * odstępContaineraContent
                const calculatedPaddingOverprintLeft = oneCM * paddingFactorOverprintLeft;

                setOverprintAndCameRamkaOdstep({
                    left: calculatedPaddingOverprintLeft,
                });

                const szerokoscKontaineraZkontentem = containerWidth - calculatedOdstępContaineraContent

                setSzerokoscKontaineraWithContents({
                    width: szerokoscKontaineraZkontentem
                })
            }
        }
        
        updateContainerWidth();
    
        const resizeObserver = new ResizeObserver(updateContainerWidth);
        resizeObserver.observe(containerRefCameoAndOverprint.current);
    
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [albumSize, containerRefCameoAndOverprint]);


    useLayoutEffect(() => {
        if (!containerRefOverprintAndCameoPopup.current || !textBlockRefOverprintAndCameoPopup.current) {
            return;
        }
    
        const checkTextOverflow = () => {
            const container = containerRefOverprintAndCameoPopup.current;
            const textBlock = textBlockRefOverprintAndCameoPopup.current;
            
            if (!container || !textBlock) {
                return;
            }
            
            if (textBlock.offsetWidth > container.offsetWidth || textBlock.offsetHeight > container.offsetHeight) {
                if (canShowPopupOverprintAndCameo) {
                    setIsPopupVisable(prev => ({
                        ...prev,
                        isPopupVisibleOverprintAndCameo: true
                    }));
                    setCanShowPopupOverprintAndCameo(false);
                }
            } else {
                setCanShowPopupOverprintAndCameo(true);
            }
        };
    
        const resizeObserver = new ResizeObserver(checkTextOverflow);
        resizeObserver.observe(containerRefOverprintAndCameoPopup.current);
        resizeObserver.observe(textBlockRefOverprintAndCameoPopup.current);
    
        return () => resizeObserver.disconnect();
    }, [canShowPopupOverprintAndCameo]);

    // liczenie rozmiaru w kontainerze tekstu przy skalowaniu 
    useResponsiveFontSize(
        containerRefCameoAndOverprint, 
        [sizePixels.sizePixelLine1, sizePixels.sizePixelLine2, sizePixels.sizePixelLine3], 
        [setFontSizeLineOverprintCombo1, setFontSizeLineOverprintCombo2, setFontSizeLineOverprintCombo3]
    );

    const chosenOverprintColor = overprintColorsWizualizator[formData.selectedColorOverprint] || '';
    const chosenOverprintFontLine1 = fontsMappingWizualizator[formData.overprintFontLine1] || '';
    const chosenOverprintFontLine2 = fontsMappingWizualizator[formData.overprintFontLine2] || '';
    const chosenOverprintFontLine3 = fontsMappingWizualizator[formData.overprintFontLine3] || '';

    return (
        <div className={style.cameoOverprint_container} ref = {containerRefCameoAndOverprint}>
           <div className={style.cameoAndOverprintContentContainer} style={{width: szerokoscKontaineraWithContens.width}}>
                <div className={style.okienko_cameo} style={{...windowStyle,backgroundColor: selectedtypeCameoOkienko ? 'white' : '', overflow: selectedtypeCameoOkienko ? 'hidden' : ''}}>
                    {urlCameo ? 
                        <img className={style.img_Cameo} src={urlCameo} alt="Uploaded" style={imageStyleZRamka}/>
                        : selectedtypeCameoOkienko  ? <div style={{backgroundColor: 'white', width: '100%', height: '100%'}} /> : null
                    }
                </div>
                    
                <div ref = {containerRefOverprintAndCameoPopup} className={style.container_overprint_border} style={{...odstepOdGoryDlaTeksta, left: overprintAndCameRamkaOdstep.left, right: overprintAndCameRamkaOdstep.left, bottom: overprintAndCameRamkaOdstep.left}}>
                    <div ref = {textBlockRefOverprintAndCameoPopup} className={`${style[chosenOverprintColor]} ${ style.block_with_text_overprint} `}>
                        <p className={style[chosenOverprintFontLine1]} style = {{fontSize: fontSizeLineOverprintCombo1, lineHeight: lineHeightOverprint}}>{formData.lineOverprint1}</p>
                        <p className={style[chosenOverprintFontLine2]} style = {{fontSize: fontSizeLineOverprintCombo2, lineHeight: lineHeightOverprint}}>{formData.lineOverprint2}</p>
                        <p className={style[chosenOverprintFontLine3]} style = {{fontSize: fontSizeLineOverprintCombo3, lineHeight: lineHeightOverprint}}>{formData.lineOverprint3}</p>
                    </div>
                </div>
           </div>
        </div>
    );
};

export default CameoAndOverprintWizualizator;
