import lokalizacjaCenter from '../../assets/Rectangle11.png'
import lokalizacjaDol from '../../assets/Rectangle2.png'

export const SizesOklakda = {
    XS: '4X4', 
    S:  '6X6',
    M:  '8X8',
    L:  '10X10',
    XL: '12X12'
}

export const maxSignsOklakda = {
    [SizesOklakda.XS]: 20,
    [SizesOklakda.S]: 20,
    [SizesOklakda.M]: 25,
    [SizesOklakda.L]: 25,
    [SizesOklakda.XL]: 25,
}

export const rozmiarCzcionkiOkladka = [
    { id: '1-1', value: 'Futura PT Book 9mm', size: '9mm' , onlyDates: false},
    { id: '1-2', value: 'Futura PT Book (tylko daty) 6mm', size:'6mm', onlyDates: true},
    { id: '2-1', value: 'Futura PT Book 5mm', size: '5mm', onlyDates: false},
    { id: '2-2', value: 'Futura PT Book (tylko daty) 3.5mm', size: '3.5mm', onlyDates: true},
];

export const localizacjaImages = [
    {
      name: 'Wizualne Centrum',
      src: lokalizacjaCenter,
    },
    {
      name: 'Prawy dolny róg',
      src: lokalizacjaDol,
    },
];

export const colorsOkladkaWizualizator = {
    'Plain': 'plain',
    'White': 'plain',
    'Gold':  'gold',
    'Silver': 'silver',
    'Copper': 'copper',
}

export const fontClassesWizualizator = {
    '1-1': 'font1-1',
    '1-2': 'font1-2',
    '2-1': 'font2-1',
    '2-2': 'font2-2'
};


export const positionClassesWizualizator = {
    'Wizualne Centrum': 'center_okladka',
    'Prawy dolny róg': 'prawy_dol_okladka'
};


export const baseFontSizesFor1000px = {
    "15x15": {
      "1-1": 73.8,
      "1-2": 49.2,
      "2-1": 45,
      "2-2": 28.7
    },
    "20x20": {
        "1-1": 56.2,
        "1-2": 37.4,
        "2-1": 34.4,
        "2-2": 21.8
    },
    "25x25": {
        "1-1": 45.5,
        "1-2": 30.3,
        "2-1": 27.7,
        "2-2": 17.6
    },
    "30x30": {
        "1-1": 38.2,
        "1-2": 25.4,
        "2-1": 23.3,
        "2-2": 14.8
    },
};