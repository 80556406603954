import React, { useContext, useEffect, useState } from 'react';
import style from './Grzbiet.module.css'
import { AppContext, MainStateModal } from '../../../../context/Contex';
import { colorMapGrzbiet, positionMapGrzbiet, odstepDolGrzbiet, baseFontSizesFor1000pxGrzbiet } from '../../../../constants/dataTloczenie/dataTloczenieGrzbiet';

const Grzbiet = ({imageSrcOkladka, containerOkladkaRef}) => {

    const [grzbieAlone, setGrzbietAlone] = useState(false)
    const {containerRefGrzbiet} = useContext(MainStateModal)
    const [bottomOdstep, setBottomOdstep] = useState(0);

    const grzbietClass = grzbieAlone ? style.grzbietAlone : style.grzbiet_with_Components;
    const photoGrzbiet =  grzbieAlone ? style.img_grzbiet_alone : style.img_grzbiet_with_components

    const [grzbietRozmiarCzcionki1, setGrzbietRozmiarCzcionki1] = useState(null);

    const {isMatryca, isPhotopanel, formData, isGrzbietChosen, isOkladka, isStuidoLOGO, isOverprint, isCameo, isCameoAndOverprint , isCameoAndMatryca, isCameoAndTloczenie} = useContext(MainStateModal)
    const {albumSize} = useContext(AppContext)

    useEffect(()=> {
        const otherComponentsSelected = isPhotopanel || isOkladka || isStuidoLOGO || isOverprint || isMatryca || isCameo || isCameoAndOverprint || isCameoAndMatryca || isCameoAndTloczenie;
        
        if(isGrzbietChosen && !otherComponentsSelected){
            setGrzbietAlone(true)
        }else if(isGrzbietChosen && otherComponentsSelected){
            setGrzbietAlone(false)
        }
    },[isCameoAndMatryca, isCameoAndTloczenie, isGrzbietChosen, isOkladka, isOverprint, isStuidoLOGO, isMatryca, isCameo, isCameoAndOverprint, isPhotopanel])
    
    const computeCmForStyles = (containerWidth, albumSize) => {
        const oneCm = containerWidth / (albumSize.size_width * 2.5 + 1);
        return odstepDolGrzbiet[albumSize.size_width * 2.5] * oneCm;
    };

    useEffect(() => {
        if(!containerOkladkaRef.current && !containerRefGrzbiet.current) return;

        if (containerOkladkaRef.current) {
            const containerWidth = containerOkladkaRef.current.offsetWidth;
            const computedBottom = computeCmForStyles(containerWidth, albumSize);
            setBottomOdstep(computedBottom);
        } 

        if (containerRefGrzbiet.current && !containerOkladkaRef.current) {
            const containerWidth = containerRefGrzbiet.current.offsetHeight;
            const computedBottom = computeCmForStyles(containerWidth, albumSize);
            setBottomOdstep(computedBottom);
        }
    }, [albumSize.size_width]);


    const {colorClassGrzbiet,positionClass_grzbiet} = {
        colorClassGrzbiet: colorMapGrzbiet[formData.selectedColorGrzbiet],
        positionClass_grzbiet: positionMapGrzbiet[formData.selectedImageGrzbiet?.id]
    };

    useEffect(() => {
        const updateFontSizes = () => {
            const windowHeight = containerRefGrzbiet.current.offsetHeight;
    
            const albumKey = `${albumSize.size_height * 2.5}x${albumSize.size_width * 2.5}`;
            const scaleFactor = windowHeight / 1000;
    
            if (baseFontSizesFor1000pxGrzbiet[albumKey]) {
                setGrzbietRozmiarCzcionki1(baseFontSizesFor1000pxGrzbiet[albumKey][formData.fontSizeGrzbiet] * scaleFactor);
            }
        };
    
        const currentRef = containerRefGrzbiet.current;
    
        const resizeObserver = new ResizeObserver(updateFontSizes);
        if (currentRef) {
            resizeObserver.observe(currentRef);
        }
    
        window.addEventListener('resize', updateFontSizes);
    
        return () => {
            if (currentRef) {
                resizeObserver.unobserve(currentRef); 
            }
            window.removeEventListener('resize', updateFontSizes);
        };
    }, [albumSize, formData.fontSizeGrzbiet, containerRefGrzbiet]);

    //wybrana lokalizacja
    const positionClass = positionMapGrzbiet[formData.selectedImageGrzbiet?.id];
    
    return (
        <div className={grzbietClass} ref = {containerRefGrzbiet} >

            <img className={photoGrzbiet} src={imageSrcOkladka} alt='album' />
            <div className={`${style.content_text_grzbiet} ${style[positionClass_grzbiet]}`} style={positionClass === 'dol_grzbiet' ? { bottom: `${bottomOdstep}px` } : {}}>
                <div className={style[colorClassGrzbiet]}>
                    <p style={{ fontSize: grzbietRozmiarCzcionki1, fontFamily: 'futura_ptbook'}}>{formData.lineGrzbiet}</p>
                </div>
            </div>
        </div>
    );
};

export default Grzbiet;

