import kolorBlackTłoczenie from '../assets/embossing_color_black.png'
import kolorCopperTłoczenie from '../assets/embossing_color_copper.png'
import kolorGoldTłoczenie from '../assets/embossing_color_gold.png'
import kolorPlainTłoczenie from '../assets/embossing_color_plain.png'
import kolorSilverTłoczenie from '../assets/embossing_color_silver.png'
import kolorWhiteTłoczenie from '../assets/embossing_color_white.png'

export const mainInfoAlbum = {
    nazwa_projektu: '', 
    papier: {value: ''},
    // Nazwa materiału Okładki = type okladki
    typ_okładki: {type: ''}, 
    materiał_okładki: {value: ''}, 
    personalizacja_okładki: {value: []},
}


export const Colors = ['Plain', 'White', 'Gold', 'Silver', 'Copper'];

export const ColorImagesTloczenie = [
    { color: 'Plain', image: kolorPlainTłoczenie },
    { color: 'White', image: kolorWhiteTłoczenie },
    { color: 'Gold', image: kolorGoldTłoczenie },
    { color: 'Silver', image: kolorSilverTłoczenie },
    { color: 'Copper', image: kolorCopperTłoczenie },
    // { color: 'Black', image: kolorBlackTłoczenie }, 
];

export const ColorCodes = {
    'Plain': '#5e5d5d', 
    'White': 'white',
    'Gold': '#c6aa6a',
    'Silver': '#babbbe',
    'Copper': '#b46b3d',
};

// o - false, 1- true
export const availableColors = {
    "PREMIUM PASTEL White": [0, 0, 1, 1, 1],
    "NATURAL Beige": [0, 0, 1, 1, 1],
    "NATURAL Raw Linen": [0, 0, 1, 1, 1],
    "NATURAL Gray": [0, 0, 1, 1, 1],
    "PREMIUM PASTEL Earth Gray": [0, 0, 1, 1, 1],
    "PREMIUM PASTEL Old Rosa": [0, 0, 1, 1, 1],
    "PREMIUM PASTEL Greige": [0, 0, 1, 1, 1],
    "PREMIUM PASTEL Arctic": [0, 0, 1, 1, 1],
    "PREMIUM PASTEL Mauve": [0, 0, 1, 1, 1],
    "PREMIUM PASTEL Turquoise": [0, 0, 1, 1, 1],
    "PREMIUM PASTEL Dusty Pink": [0, 0, 1, 1, 1],
    "PREMIUM PASTEL Duck Egg": [0, 0, 1, 1, 1],
    "PREMIUM PASTEL Sage": [0, 0, 1, 1, 1],
    "PREMIUM PASTEL Granite": [0, 0, 1, 1, 1],
    "LUX Gray": [0, 0, 1, 1, 1],
    "LUX Orchidee": [0, 0, 1, 1, 1],
    "LUX Blue": [0, 0, 1, 1, 1],
    "LUX Rosa": [0, 0, 1, 1, 1],
    "LUX Turquoise": [0, 0, 1, 1, 1],
    "LUX Honey": [0, 0, 1, 1, 1],
    "LUX Cherry": [0, 0, 1, 1, 1],
    "LUX Navy": [0, 0, 1, 1, 1],
    "EcoVelvet Cream": [1, 0, 1, 1, 1],
    "EcoVelvet Silver": [1, 0, 1, 1, 1],
    "EcoVelvet Mint": [1, 0, 1, 1, 1],
    "EcoVelvet Baby Blue": [1, 0, 1, 1, 1],
    "EcoVelvet Beige": [1, 0, 1, 1, 1],
    "EcoVelvet PaleRosa": [1, 0, 1, 1, 1],
    "EcoVelvet Lavender": [1, 0, 1, 1, 1],
    "EcoVelvet Mustard": [1, 0, 1, 1, 1],
    "EcoVelvet Lazur": [1, 0, 1, 1, 1],
    "EcoVelvet Green1": [1, 0, 1, 1, 1],
    "EcoVelvet Red": [1, 0, 1, 1, 1],
    "EcoVelvet Black": [1, 0, 1, 1, 1],
    "Mandarin": [0, 0, 1, 1, 1],
    "Ash": [0, 0, 1, 1, 1],
    "Black Olive": [0, 0, 1, 1, 1],
    "Blue Poppy": [0, 0, 1, 1, 1],
    "Bluebell": [0, 0, 1, 1, 1],
    "Cranberry": [0, 0, 1, 1, 1],
    "Magnolia": [0, 0, 1, 1, 1],
    "Mint": [0, 0, 1, 1, 1],
    "Tropical": [0, 0, 1, 1, 1],
    "Wheat": [0, 0, 1, 1, 1],
    "Pansy": [0, 0, 1, 1, 1],
    "Velvet Petal": [1, 0, 1, 1, 1],
    "Velvet Dusty Pink": [1, 0, 1, 1, 1],
    "Velvet Gray": [1, 0, 1, 1, 1],
    "Velvet Baby Blue": [1, 0, 1, 1, 1],
    "Velvet Sage": [1, 0, 1, 1, 1],
    "Velvet Turquoise": [1, 0, 1, 1, 1],
    "Velvet Burgundy": [1, 0, 1, 1, 1],
    "Velvet Navy Blue": [1, 0, 1, 1, 1],
    "Velvet Bottle Green": [1, 0, 1, 1, 1],
    "Velvet Raven": [1, 0, 1, 1, 1],
    "Skóra Snow White": [1, 0, 1, 1, 1],
    "Skóra Off White": [1, 0, 1, 1, 1],
    "Skóra Blush": [1, 1, 1, 1, 1],
    "Skóra Jade": [1, 1, 1, 1, 1],
    "Skóra Sky Blue": [1, 1, 1, 1, 1],
    "Skóra Turquoise": [1, 1, 1, 1, 1],
    "Jedwab Creme": [0, 1, 1, 1, 1],
    "Jedwab Pale Rosa": [0, 1, 1, 1, 1],
    "Jedwab French Pink": [0, 1, 1, 1, 1],
    "Jedwab Waterfall": [0, 1, 1, 1, 1],
    "Jedwab Peacock": [0, 1, 1, 1, 1],
}

export const allowedCharacters = " ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!,-./'&+:\'`~¨^Øø#˅ĄąÆÄäàáæËëęĘÉéöÖóÓśŚŁłżŻĆźćŃńÜüß";
export const specialCharacters = ' 0123456789./-'

export const windowDetails = {
    "15x15": {
        "Kwadrat": { dimensions: { width: 7, height: 7 }, margins: { top: 4,  left: 5 } },
        "Prostokąt poziomy": { dimensions: { width: 9, height: 7 }, margins: { top: 4, left: 4 } },
        "Prostokąt pionowy": { dimensions: { width: 7, height: 9 }, margins: { top: 3, left: 5 } },
    },
    "20x20": {
        "Kwadrat": { dimensions: { width: 8, height: 8 }, margins: { top: 6, left: 5.5 } },
        "Prostokąt poziomy": { dimensions: { width: 11, height: 8 }, margins: { top: 5, left: 4 } },
        "Prostokąt pionowy": {  dimensions: { width: 8, height: 11 }, margins: { top: 3, left: 5.5 } },
    },
    "25x25": {
        "Kwadrat": { dimensions: { width: 9, height: 9 }, margins: { top: 8, left: 7.5 } },
        "Prostokąt poziomy": { dimensions: { width: 12, height: 9 }, margins: { top: 7, left: 6 } },
        "Prostokąt pionowy": { dimensions: { width: 9, height: 12 }, margins: { top: 5.5, left: 7.5 } },
    },
    "30x30": {
        "Kwadrat": { dimensions: { width: 10, height: 10 }, margins: { top: 10, left: 9.5 } },
        "Prostokąt poziomy": { dimensions: { width: 13, height: 10 }, margins: { top: 9, left: 8 } },
        "Prostokąt pionowy": { dimensions: { width: 10, height: 13 }, margins: { top: 8, left: 9.5 } },
    },
};

export const photosForOkladka = {
    //Okładki Standard
    "PREMIUM PASTEL White": "pastel_white",
    "NATURAL Beige": "natural_beige",
    "NATURAL Raw Linen": "natural_rawlinen",
    "NATURAL Gray": "",
    "PREMIUM PASTEL Earth Gray": "pastel_earthgray",
    "PREMIUM PASTEL Old Rosa": "pastel_oldrosa",
    "PREMIUM PASTEL Greige": "pastel_greige",
    "PREMIUM PASTEL Arctic": "pastel_arctic",
    "PREMIUM PASTEL Mauve": "pastel_mauve",
    "PREMIUM PASTEL Turquoise": "pastel_turquoise",
    "PREMIUM PASTEL Dusty Pink": "pastel_dustypink",
    "PREMIUM PASTEL Duck Egg": "pastel_duckegg",
    "PREMIUM PASTEL Sage": "pastel_sage",
    "PREMIUM PASTEL Granite": "pastel_granite",
    "LUX Gray": "lux_gray",
    "LUX Orchidee": "lux_orchidee",
    "LUX Blue": "lux_blue",
    "LUX Rosa": "lux_rosa",
    "LUX Turquoise": "lux_turquise",
    "LUX Honey": "lux_honey",
    "LUX Cherry": "lux_cherry",
    "LUX Navy": "",

    //Okładki EcoVelvet
    "EcoVelvet Cream": "ecovelvet_cream",
    "EcoVelvet Silver": "ecovelvet_silver",
    "EcoVelvet Mint": "ecovelvet_mint",
    "EcoVelvet Baby Blue": "ecovelvet_babyblue",
    "EcoVelvet Beige": "ecovelvet_beige",
    "EcoVelvet PaleRosa": "ecovelvet_palerosa",
    "EcoVelvet Lavender": "ecovelvet_lavender",
    "EcoVelvet Mustard": "ecovelvet_mustard",
    "EcoVelvet Lazur": "ecovelvet_lazur",
    "EcoVelvet Green": "ecovelvet_green",
    "EcoVelvet Red": "",
    "EcoVelvet Black": "",

    //Okładki Flores
    'Mandarin': 'flores_pansy', 
    'Ash': 'flores_ash', 
    'Black Olive': 'flores_blackolive',
    'Blue Poppy': 'flores_bluepoppy',
    'Bluebell': 'flores_bluebell',
    'Cranberry': 'flores_cranberry',
    'Magnolia': 'flores_magnolia', 
    'Mint': 'flores_mint',
    'Tropical': 'flores_tropical',
    'Wheat': 'flores_wheat',
    'Pansy': 'flores_pansy',
    //Okładki velurowe
    'Velvet Petal': 'velvet_petal',
    'Velvet Dusty Pink': 'velvet_dustypink',
    'Velvet Gray': 'velvet_grey',
    'Velvet Baby Blue': 'velvet_babyblue',
    'Velvet Sage': 'velvet_sage',
    'Velvet Turquoise': 'velvet_turquoise',
    'Velvet Burgundy': 'velvet_burgundy',
    // 'Velvet Navy Blue': 'velvet_navy'- ???
    'Velvet Navy Blue': 'velvet_navy',
    'Velvet Bottle Green': 'velvet_bottlegreen',
    'Velvet Raven': 'velvet_raven',
    //Okładki skorzane
    'Skóra Snow White': 'leather_white',
    'Skóra Off White': 'leather_offwhite',
    'Skóra Blush': '',
    'Skóra Jade': 'leather_jade',
    'Skóra Sky Blue': 'leather_skyblue',
    'Skóra Turquoise': 'leather_turquise',
    //Okładki Jedwabne
    'Jedwab Creme': '',
    'Jedwab Pale Rosa': '',
    'Jedwab French Pink': '',
    'Jedwab Waterfall': '',
    'Jedwab Peacock': '',
}

export const fontsMappingWizualizator = {
    'Autumn In November (S,G)': 'font_Autumn_in_November',
    'Ballerina Script': 'font_Ballerina_script',
    'Anna Clara': 'font_Anna_Clara',
    'Charlotte': 'font_Charlotte',
    'Endolitta' : 'font_Endolitta_demo',
    'Gardenia' : 'font_Gardenia',
    'Janda Happy Day' : 'font_JandaHappyDay',
    'Kristen ITC' : 'font_ITCKRIST',
    'My Silly Willy Girl' : 'font_My_silly_wully_girl',
    'Weather Sunday' : 'font_Weather_sunday',
    'Peoni Pro' : 'font_Peoni_Pro',
    'Spring is Coming' : 'font_Spring_is_comming',
    'Rouge Script' : 'font_RougeScript',
    'Claudia' : 'font_Claudia_script',
    'Hesterica' : 'font_Hesterica',
    'Juice ITP' : 'font_Juice_itc',
    'March Lovers' : 'font_March_lovers',
    'King Basil Lite' : 'font_King_Basil_Lite',
    'Nattalia' : 'font_Nattalia',
    'Avocado & Lime' : 'font_Avocado_Lime',
    'Champagne & Limousines' : 'font_Champagne_limousines',
    'Argentinian Night' : 'font_Argentinian_night',
    'Dutch801 Xbd BT' : 'font_Dutch_801_xbd_bt',
    'You make me smile' : 'font_You_make_me_smile',
    'Monday Line' : 'font_Mondayline',
    'Harrington' : 'font_HARRINGT',
    'Munira Script' : 'font_Munira_script',
    'October Moon' : 'font_October_moon',
    'Salsabilla' : 'font_SALSABILLA',
    'Exotic850 Bd Bt' : 'font_Exotc350BdBTBold',
    'NsimSun' : 'font_SIMSUN',
    'Stylus BT' : 'font_stylus_bt',
    'High Tower Text' : 'font_HighTOWERT',
    'A Gentle Touch' : 'font_A_Gentle_Touch',
    'KG Neatly Printed' : 'font_KGNeatlyPrinted',
    'KG Neneath - Your Beaoutiful Chunk' : 'font_KGBeneathYourBeautifulChunk',
    'Love Moment' : 'font_Love_moment',
    'Optimus Princeps': 'font_OptimusPrinceps',
};