import React, { useContext } from 'react';
import style from './Popup.module.css'
import { MainStateModal } from '../../context/Contex';

const Popup = ({ text,onConfirm, onClose,isVisible  }) => {

  const { isPopupVisableThatAllInfoWillDelete, isPopupVisable} = useContext(MainStateModal)

    return (
      <div className={isVisible  ? style.modalOverlayActive : style.modalOverlayNotActive}>
        <div className={style.modalContent}>
            <span className={style.uwagaInfo}>UWAGA!</span>
            <p>{text}</p>

            <div className = {style.containerWithButtons} >
                {isPopupVisableThatAllInfoWillDelete &&  <button onClick={onConfirm} className={style.modalConfirmButton} style={{marginRight: '10px'}}>Zamknąć konfigurator</button>}
                {!isPopupVisable.isPopupVisableWhenChangingOkienkoType && !isPopupVisable.isPopupVisableWhenChangingOkienkoTypeLOGO && !isPopupVisable.isPopupVisableWhenChangingRozmiarLOGO && <button onClick={onClose} className={style.modalCloseButton}>Kontynuować wypełnianie konfiguratora</button>}
                {isPopupVisable.isPopupVisableWhenChangingOkienkoType && <button onClick={onConfirm} className={style.modalCloseButton}>Wykadruj zdjęcie ponownie</button>}
                
                
                {isPopupVisable.isPopupVisableWhenChangingOkienkoTypeLOGO && <button onClick={onClose} className={style.modalCloseButton}>Upload again</button>}
            </div>
        </div>
      </div>
    );
  };
  

export default Popup;