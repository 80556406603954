import photoVC from '../../assets/MatrycaC.png';
import photoBC from '../../assets/MatrycaBC.png';
import photoBR from '../../assets/MatrycaBR.png';
import photoMR from '../../assets/MatrycaMR.png';
import photoTC from '../../assets/MatrycaTC.png';
import photoMC from '../../assets/MatrycaC.png';


export const imageOptionsMatryca = [
    { img: photoVC, label: 'Visual Center' },
    { img: photoBC, label: 'Bottom Center' },
    { img: photoBR, label: 'Bottom Right' },
    { img: photoMR, label: 'Right Center' },
    { img: photoTC, label: 'Top Center' },
    { img: photoMC, label: 'Middle Center' }
];

export const rozmiarMatrycy = ['5x1', '5x2', '5x5']

export const rozmiaryMatrycy10x10 = ['2.5x1', '2.5x1.5', '2.5x2.5']

export const matrixSizes = {
    "5x1": { width: 12.7, height: 2.5 },
    "5x2": { width: 12.7, height: 5 },
    "5x5": { width: 12.7, height: 12.7 },
};

export const matrixSizesFor10x10 = {
    "2.5x1": { width: 6.35, height: 2.5 },
    "2.5x1.5": { width: 6.35, height: 3.81 },
    "2.5x2.5": { width: 6.35, height: 6.35 },
};

export const colorsMatrycaWizualizator = {
    'Plain': 'plain',
    'White': 'white',
    'Gold': 'gold',
    'Silver': 'silver',
    'Copper': 'copper',
}

export const lokalizacjaMatrycaWizualizator = {
    'Middle Center': 'Middle_Center',
    'Visual Center': 'Visual_Center',
    'Top Center': 'Top_Center',
    'Bottom Center': 'Bottom_Center',
    'Bottom Right': 'Bottom_Right',
    'Right Center': 'Right_Center',
}


