import React, { useContext } from 'react';
import { ColorImagesTloczenie} from '../../../constants/data';
import { maxSignsGrzbiet } from '../../../constants/dataTloczenie/dataTloczenieGrzbiet';
import { localizacjaTłoczeniaGrzbiet } from '../../../constants/dataTloczenie/dataTloczenieGrzbiet';
import { MainStateModal } from '../../../context/Contex';
import style from '../tloczenieGrzbiet/TloczenieGrzbiet.module.css'

const TloczenieGrzbiet = () => {

    const {
        isSaveAttempted, 
        hasErrors, 
        albumSize, 
        disabledColors, 
        formData, 
        setFormData,
        formDataInvalid, 
    } = useContext(MainStateModal);

    const {lineGrzbiet,selectedImageGrzbiet} = formData
    const {invalidCharacterGrzbiet} = formDataInvalid
    const handleImageSelection = (el) => el.id === 'Vertical Text' && lineGrzbiet.length > 7 ? setFormData((prev) => ({...prev, lineGrzbiet: ''})) : setFormData((prev) => ({...prev, selectedImageGrzbiet: el}));
    
    return (
        <div>
          <div>
            {hasErrors && isSaveAttempted && selectedImageGrzbiet === null && <p style={{ color: 'red' }}>Musisz wpisać tekst:</p>}
            <p style={{fontWeight: '300'}}>Położenie tłoczenia:</p>
            <div style={{display: 'flex', gap: '15px', marginLeft: '3px'}}>
                {localizacjaTłoczeniaGrzbiet.map((el, idx) => (
                    <div 
                        className={[
                            style.imagesBlockGrzbiet, 
                            selectedImageGrzbiet && selectedImageGrzbiet.id === el.id ? style.selected : style.notSelected
                        ].join(' ')} 
                        key={idx} 
                        onClick={() => handleImageSelection(el)}
                    >
                        <img src={el.path} alt='lokalizacja' />
                        <span style={{marginTop: '5px', fontSize: '12px'}}>{el.text}</span> 
                    </div>
                ))}
            </div>
          </div>
          

           <div className={style.mainContainerWithTekstGrzbiet}>
                <div className={style.containerWithInputGrzbiet}>
                        <div className={style.blockWithinfoTekstGrzbiet}>
                            <p style={{fontWeight: '300'}}>Tekst:</p>
                            <p>{`${lineGrzbiet.length} | ${selectedImageGrzbiet !== null && selectedImageGrzbiet.id === 'Vertical Text' ? 7: maxSignsGrzbiet[`${albumSize.size_height}X${albumSize.size_width}`]}`}</p>
                        </div>
                        
                        <div>
                            {hasErrors && isSaveAttempted && lineGrzbiet.length <= 0 && <p style={{ color: 'red' }}>Musisz napisać tekst</p>}
                            <input 
                                placeholder = 'Wpisz tekst...'
                                style={{fontFamily: 'MavenPro'}}
                                className={style.inputGrzbiet} 
                                value = {lineGrzbiet} 
                                onChange={(e) => setFormData((prev) => ({...prev, lineGrzbiet: e.target.value}))}
                                type = 'text' 
                                maxLength={selectedImageGrzbiet !== null && selectedImageGrzbiet.id === 'Vertical Text' ? 7 : maxSignsGrzbiet[`${albumSize.size_height}X${albumSize.size_width}`]
                            }/>
                            {invalidCharacterGrzbiet && <span style={{ color: 'red' }}>Nieprawidłowy znak:{invalidCharacterGrzbiet}</span>}

                        </div>
                </div>
           </div>
           <div className={style.blockPersonalizacjaColoru}>
                <p style={{fontWeight: '300'}}>Kolor:</p>
                <div className={style.colorContainerGrzbiet}>
                    {ColorImagesTloczenie.map((colorItem, idx) => (
                        <div 
                            key={idx} 
                            className={`${style.colorBlockGrzbiet} ${disabledColors.includes(colorItem.color) ? style.disabled : ''} ${formData.selectedColorGrzbiet === colorItem.color ? style.selected : ''}`} 
                            style={{ fontFamily: 'MavenPro' }} 
                            onClick={() => !disabledColors.includes(colorItem.color) && setFormData((prevState) => ({...prevState, selectedColorGrzbiet: colorItem.color }))}
                        >
                            <div className={style.blockWithPhotosColorGrzbiet}>
                                <img 
                                    src={colorItem.image} 
                                    alt={colorItem.color} 
                                    className={`${style.colorImageGrzbiet} ${formData.selectedColorGrzbiet === colorItem.color ? style.selected : style.notSelected}`} 
                                />
                                <label style={{ color: 'black', fontSize: '12px' }} >{colorItem.color}</label>
                            </div>
                        </div>
                    ))}
                </div>
                {hasErrors && isSaveAttempted && !formData.selectedColorGrzbiet && <p style={{ color: 'red' }}>Musisz wyprać color tłoczenia</p>}
            </div>
        </div>
    );
};

export default TloczenieGrzbiet;









