import React, { useContext, useEffect, useLayoutEffect, useRef} from 'react';
import style from './Wizualizator.module.css'
import { AppContext, MainStateModal, WizualizatorContext } from '../../context/Contex';
import Grzbiet from './elements/grzbiet/Grzbiet';
import Okladka from './elements/okladka/Okladka'
import StudioLogo from './elements/studioLOGO/StudioLogo';
import OverprintAlbum from './elements/overprint/OverprintAlbum';
import MatrycaWizualizator from './elements/matryca/MatrycaWizualizator';
import CameoWizualizator from './elements/cameo/CameoWizualizator';
import CameoAndOverprintWizualizator from './elements/cameoAndOverprint/CameoAndOverprintWizualizator';
import CameoAndTloczenieWizualizator from './elements/cameoAndOkladka/CameoAndTloczenieWizualizator';
import CameoAndMatrycaWizualizator from './elements/cameoAndMatryca/CameoAndMatrycaWizualizator'
import { photosForOkladka } from '../../constants/data';
import PhotopanelWizualizator from './elements/photopanel/PhotopanelWizualizator';

const Wizualizator = () => {
        
    const {lastSelectedComponent, imageSrcOkladka, setImageSrcOkladka, containerRefGrzbiet, containerLeftRefWizualizator, isCameoAndTloczenie, isCameoAndMatryca,isMatryca, positionLOGO, activeTab, urlLOGO,isGrzbietChosen, isCameoAndOverprint,isOkladka, isPhotopanel, isStuidoLOGO, isOverprint, isCameo} = useContext(MainStateModal)
    const {data,albumSize} = useContext(AppContext)

    const otherComponentsSelected =  isOkladka || isStuidoLOGO || isOverprint || isMatryca || isCameo || isCameoAndOverprint || isCameoAndMatryca || isCameoAndTloczenie || isPhotopanel;
    const activeTabKey = (activeTab.key === 'isPhotopanel' || activeTab.key === 'isStuidoLOGO' || activeTab.key === 'isOkladka' || activeTab.key === 'isOverprint' || activeTab.key === 'isMatryca' || activeTab.key === 'isGrzbietChosen' || activeTab.key === 'isCameo' || activeTab.key === 'isCameoAndOverprint' || activeTab.key === 'isCameoAndTloczenie' || activeTab.key === 'isCameoAndMatryca' || isOkladka || isOverprint || isMatryca || isGrzbietChosen || isCameo || isCameoAndOverprint || isCameoAndTloczenie || isCameoAndMatryca || isPhotopanel|| isStuidoLOGO) 

    const containerWizualizatorRef = useRef(null);
    const containerOkladkaRef = useRef(null);
    const containerWithImageRef = useRef(null)
    
    const getCoverMaterialFileName = (materialValue) => {
        const fileName = photosForOkladka[materialValue];
        if (!fileName) {
            return null;
        }
        return fileName;
    }
    
    useEffect(() => { 
        const fileName = getCoverMaterialFileName(data.materiał_okładki.value);
        if (fileName) {
            const newImageSrc = `https://w210.dkonto.pl/${albumSize.size_height * 2.5}x${albumSize.size_width * 2.5}_${fileName}.jpg`;
            setImageSrcOkladka(newImageSrc); 
        }

    }, [data.materiał_okładki.value, albumSize.size_height, albumSize.size_width]); 
    
    useLayoutEffect(() => {
        const updateDimensions = () => {
            requestAnimationFrame(() => {
                if (containerRefGrzbiet.current) {
                    if (isGrzbietChosen && otherComponentsSelected) {
                        containerRefGrzbiet.current.style.width = "";
                        const coverHeightH = containerOkladkaRef.current.offsetHeight;
                        containerRefGrzbiet.current.style.height = coverHeightH + 'px';
                    }

                    if (isGrzbietChosen && !otherComponentsSelected) {
                        const heightInPxGrzbiet = containerRefGrzbiet.current.offsetHeight;
                        const cmValue = heightInPxGrzbiet / (albumSize.size_height * 2.5 + 1);
                        const finalWidth = cmValue * 2;
                        containerRefGrzbiet.current.style.width = finalWidth + 'px';

                        const heightContainerImage = containerWithImageRef.current.offsetHeight;
                        containerRefGrzbiet.current.style.height = heightContainerImage + 'px';
                    }
                }
            });
        };
    
        const roOkladka = new ResizeObserver(updateDimensions);
        const roGrzbiet = new ResizeObserver(updateDimensions);
        const roContainerWithImage = new ResizeObserver(updateDimensions);
    
        if (containerOkladkaRef.current) {
            roOkladka.observe(containerOkladkaRef.current);
        }
        if (containerRefGrzbiet.current) {
            roGrzbiet.observe(containerRefGrzbiet.current);
        }
        if (containerWithImageRef.current) {
            roContainerWithImage.observe(containerWithImageRef.current);
        }
    
        return () => {
            roOkladka.disconnect();
            roGrzbiet.disconnect();
            roContainerWithImage.disconnect();
        };
    }, [isGrzbietChosen, otherComponentsSelected, albumSize.size_height, containerRefGrzbiet]);
    
    
    useLayoutEffect(() => {
        const resizeContainer = () => {
            requestAnimationFrame(() => {
                if (!containerLeftRefWizualizator.current ||
                    !containerWizualizatorRef.current ||
                    !containerOkladkaRef.current) return;
    
                const containerLeftW = containerLeftRefWizualizator.current.offsetWidth;
                const containerWizualizatorH = containerWizualizatorRef.current.offsetHeight;
                const coverHeightH = containerOkladkaRef.current.offsetHeight;
                const coverHeightW = containerOkladkaRef.current.offsetWidth;
    
                let newHeight = containerWizualizatorH * coverHeightW / coverHeightH;
                if (newHeight > containerLeftW) {
                    newHeight = containerLeftW;
                }
    
                containerWizualizatorRef.current.style.width = newHeight + 'px';
            });
        };
    
        resizeContainer();
    
        window.addEventListener('resize', resizeContainer);
        return () => window.removeEventListener('resize', resizeContainer);
    }, [containerLeftRefWizualizator, containerWizualizatorRef, containerOkladkaRef.current?.offsetHeight, containerRefGrzbiet, isGrzbietChosen, otherComponentsSelected]);
    


    let imageStyles = {};

    if (activeTab.key === 'isStuidoLOGO' || (activeTab.key === 'isGrzbietChosen' && lastSelectedComponent === 'isStuidoLOGO')) {
        imageStyles = {
            transform: positionLOGO === 'Back-cover' ? 'rotate(180deg)' : 'none',
            filter: positionLOGO === 'Endpapers' ? 'brightness(0) invert(1)' : 'none'
        };
    }


    return (
        <WizualizatorContext.Provider value={{urlLOGO}}>
            <div ref = {containerWizualizatorRef} id = 'mainContainer' className= {style.container_wizualizator} >
                <div ref  = {containerWithImageRef} className={style.container_with_image}>
                    {isGrzbietChosen && <Grzbiet imageSrcOkladka = {imageSrcOkladka} containerOkladkaRef = {containerOkladkaRef}/> }

                    {!(isGrzbietChosen && !otherComponentsSelected) && (  
                    
                        <div ref  = {containerOkladkaRef} className = {style.container_with_contents} > 
                            {activeTabKey && <img className={style.photo_main} src={imageSrcOkladka} alt='album' style={imageStyles} />}

                            {(activeTab.key === 'isOkladka' || (activeTab.key === 'isGrzbietChosen' && lastSelectedComponent === 'isOkladka')) && <Okladka containerOkladkaRef ={containerOkladkaRef}/>}
                            {(activeTab.key === 'isStuidoLOGO' || (activeTab.key === 'isGrzbietChosen' && lastSelectedComponent === 'isStuidoLOGO')) && <StudioLogo />}
                            {(activeTab.key === 'isOverprint' || (activeTab.key === 'isGrzbietChosen' && lastSelectedComponent === 'isOverprint')) && <OverprintAlbum containerOkladkaRef= {containerOkladkaRef}/> }
                            {(activeTab.key === 'isMatryca' || (activeTab.key === 'isGrzbietChosen' && lastSelectedComponent === 'isMatryca')) && <MatrycaWizualizator/> }
                            {(activeTab.key === 'isCameo' || (activeTab.key === 'isGrzbietChosen' && lastSelectedComponent === 'isCameo')) && <CameoWizualizator /> }
                            {(activeTab.key === 'isPhotopanel' || (activeTab.key === 'isGrzbietChosen' && lastSelectedComponent === 'isPhotopanel')) && <PhotopanelWizualizator /> }

                            {(activeTab.key.startsWith('isCameoAndTloczenie') || (activeTab.key === 'isGrzbietChosen' && lastSelectedComponent.startsWith('isCameoAndTloczenie'))) && <CameoAndTloczenieWizualizator/>}
                            {(activeTab.key.startsWith('isCameoAndOverprint') || (activeTab.key === 'isGrzbietChosen' && lastSelectedComponent.startsWith('isCameoAndOverprint'))) && <CameoAndOverprintWizualizator/>}
                            {(activeTab.key.startsWith('isCameoAndMatryca') || (activeTab.key === 'isGrzbietChosen' && lastSelectedComponent.startsWith('isCameoAndMatryca'))) && <CameoAndMatrycaWizualizator/>}
                            
                         </div>
                    )}
                </div>
            </div>
    </WizualizatorContext.Provider>
        
    );
}
export default Wizualizator;
















// return (
//     <WizualizatorContext.Provider value={{urlLOGO}}>
//         <div ref = {containerWizualizatorRef} id = 'mainContainer' className= {style.container_wizualizator} >
//             <div ref  = {containerWithImageRef} className={style.container_with_image}>
//                 {isGrzbietChosen && <Grzbiet imageSrcOkladka = {imageSrcOkladka} containerOkladkaRef = {containerOkladkaRef}/> }

//                 {!(isGrzbietChosen && !otherComponentsSelected) && (  
                
//                     <div ref  = {containerOkladkaRef} className = {style.container_with_contents} > 
                        
//                         {activeTabKey && <img className = {style.photo_main} src={imageSrcOkladka} alt='album'/>}
                        
//                         {(activeTab.key === 'isOkladka' || activeTab.key === 'isGrzbietChosen') &&  <Okladka/>}

//                         {/* {activeTab.key === 'isOkladka' &&  <Okladka/>} */}
//                         {activeTab.key === 'isStuidoLOGO' &&
//                             <>
//                                 <img 
//                                     className = {style.photo_main} 
//                                     src={imageSrcOkladka} 
//                                     alt='album'
//                                     style={{
//                                         transform: positionLOGO === 'Back-cover' ? 'rotate(180deg)' : 'none',
//                                         filter: positionLOGO === 'Endpapers' ? 'brightness(0) invert(1)' : 'none'
//                                 }}
//                                 />
//                                 <StudioLogo/>
//                             </>
//                         }

//                         {activeTab.key === 'isOverprint' && <OverprintAlbum containerOkladkaRef= {containerOkladkaRef}/> }
//                         {activeTab.key === 'isMatryca' && <MatrycaWizualizator/> }
//                         {activeTab.key === 'isCameo' && <CameoWizualizator /> }
//                         {activeTab.key.startsWith('isCameoAndTloczenie') &&<CameoAndTloczenieWizualizator/>}
//                         {activeTab.key.startsWith('isCameoAndOverprint') && <CameoAndOverprintWizualizator/>}
//                         {activeTab.key.startsWith('isCameoAndMatryca') && <CameoAndMatrycaWizualizator/> }

//                      </div>
//                 )}
//             </div>
//         </div>
// </WizualizatorContext.Provider>
    
// );