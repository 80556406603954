import { useContext, useEffect } from "react";
import { AppContext } from "../context/Contex";

export const useResponsiveFontSize = (containerRef, initialFontSizes, setFontSizes) => {

    const {albumSize} = useContext(AppContext)

    const factorScale = {
        '30x30': 1,
        '25x25': 1.2,
        '20x20': 1.5,
        '15x15': 2
    }

    const albumKey = `${albumSize.size_height * 2.5}x${albumSize.size_width * 2.5}`;
    const scaleFactor = factorScale[albumKey] || 1; 

    const computeFontSize = (inputFontSize, containerWidth) => {
        return `${inputFontSize * (containerWidth / 1000) * scaleFactor}px`;
    };

    useEffect(() => {
       
        const handleResize = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const newFontSizes = initialFontSizes.map(size => computeFontSize(size, containerWidth));
                newFontSizes.forEach((fontSize, index) => {
                    setFontSizes[index](fontSize);
                });
            }
        };
        handleResize();  

        const resizeObserver = new ResizeObserver(handleResize);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
        
    }, [initialFontSizes, containerRef, albumSize]);
}