import React, { useContext, useEffect } from 'react';
import { AppContext } from '../context/Contex';


const Effects = () => {
    
    const{ setActive, setData, setAlbumSize,albumSize, setShowButtonKonfiguracji, showButtonKonfiguracji, data} = useContext(AppContext)

    //blockowanie wybranych chekboksów
    useEffect(() => {
      const albumSizeInPx = albumSize.size_width * 2.5;
      const isCameo = document.querySelector('input[value="Okienko"]');
      const isOkladka = document.querySelector('input[value="Tłoczenie na okładce"]');
      const isOverprint = document.querySelector('input[value="Overprint"]');
      const isMatryca = document.querySelector('input[value="Tłoczenie z matrycy"]');
      const isCameoAndOverprint = document.querySelector('input[value="Cameo_Overprint"]');
      const isCameoAndTloczenie = document.querySelector('input[value="Cameo_Tloczenie"]');
      const isCameoAndMatryca = document.querySelector('input[value="Cameo_Matryca"]');
      
      const checkboxesToDisableIfCameo = [isOkladka, isOverprint, isMatryca, isCameoAndOverprint, isCameoAndTloczenie, isCameoAndMatryca];
      const checkboxesToDisableIfCameoAndOverprint = [isCameo, isOverprint, isCameoAndTloczenie, isCameoAndMatryca];
      const checkboxesToDisableIfCameoAndTloczenie = [isOkladka, isCameo, isCameoAndOverprint, isCameoAndMatryca];
      const checkboxesToDisableIfCameoAndMatryca = [isCameoAndTloczenie, isCameoAndOverprint, isMatryca, isCameo];
      const checkboxesToDisableIfChosenSomethingPojedyncze = [isCameo, isCameoAndOverprint, isCameoAndTloczenie, isCameoAndMatryca];
      const checkboxesToDisableIfAlbumSize10or15 = [isCameoAndOverprint, isCameoAndTloczenie, isCameoAndMatryca];

      if ((albumSizeInPx === 10 || albumSizeInPx === 15)) {
        checkboxesToDisableIfAlbumSize10or15.forEach(function (checkbox) {
            if (checkbox) checkbox.disabled = true;
        });
      }
      
      const handleCheckboxChange = function () {
        let disable = this.checked;
        let checkboxesToDisable = [];
    
        switch (this.value) {
          case 'Okienko':
            checkboxesToDisable = checkboxesToDisableIfCameo;
            break;
          case 'Cameo_Overprint':
            checkboxesToDisable = checkboxesToDisableIfCameoAndOverprint;
            break;
          case 'Cameo_Tloczenie':
            checkboxesToDisable = checkboxesToDisableIfCameoAndTloczenie;
            break;
          case 'Cameo_Matryca':
            checkboxesToDisable = checkboxesToDisableIfCameoAndMatryca;
            break;
          case 'Tłoczenie na okładce':
          case 'Overprint':
          case 'Tłoczenie z matrycy':
              checkboxesToDisable = checkboxesToDisableIfChosenSomethingPojedyncze;
              break;
            default: break;
        }
    
        checkboxesToDisable.forEach(function (checkbox) {
        if (checkbox) checkbox.disabled = disable;
      });
      };
    
      [isCameo, isCameoAndOverprint, isCameoAndTloczenie, isCameoAndMatryca, isOkladka, isOverprint, isMatryca].forEach(function (checkbox) {
        if (checkbox) checkbox.addEventListener('change', handleCheckboxChange);
      });
    
      return () => {
        [isCameo, isCameoAndOverprint, isCameoAndTloczenie, isCameoAndMatryca, isOkladka, isOverprint, isMatryca].forEach(function (checkbox) {
          if (checkbox) checkbox.removeEventListener('change', handleCheckboxChange);
        });
      };
    }, [albumSize.size_width]);
    


    // // button_konfigurator
    useEffect(() => {
      const buttonKonfigurator = document.getElementById('product-configurator');
    
      if (!buttonKonfigurator) return;

      const handleClick = () => {
        setActive(true);
      };
    
      buttonKonfigurator.addEventListener('click', handleClick);
    
      return () => {
        buttonKonfigurator.removeEventListener('click', handleClick);
      };
    }, [showButtonKonfiguracji]);

    //album_size
    // useEffect(() => {

    //   const albumHeightEl = document.getElementById('mango_upload_file_height')
    //   const albumWidthEl = document.getElementById('mango_upload_file_width')

    //   if (albumHeightEl && albumWidthEl) {
    //     setAlbumSize((prevSize) => ({
    //       ...prevSize,
    //       size_height: albumHeightEl.value,
    //       size_width: albumWidthEl.value,
    //     }));
    //   }
    // },[])
    
    useEffect(() => {
      const selectElement = document.getElementById('albumSizeSelect');
      
      const handleSizeChange = (e) => {
          const selectedSize = e.target.value; 
          const calculatedSize = Number(selectedSize) / 2.5;
  
          setAlbumSize({
              size_height: calculatedSize,
              size_width: calculatedSize
          });
      };
  
      if (selectElement) {
          selectElement.addEventListener('change', handleSizeChange);
  
          return () => selectElement.removeEventListener('change', handleSizeChange);
      }
  }, []);

    // nazwa albumu
    useEffect(() => {
        const selectedNameElement = document.querySelector("input[name='properties[Nazwa Projektu]']");  

        if (!selectedNameElement) return;

        const handleChange = () => {
          setData((prevData) => ({
            ...prevData,
            nazwa_projektu: selectedNameElement.value,
          }))
        };

        selectedNameElement.addEventListener('change', handleChange)

        return () => {
          selectedNameElement.removeEventListener('change', handleChange)
        }
    }, [])

    // Papier
    // useEffect(() => {
    //     const selectElement = document.querySelector('select[name="properties[Papier]"]');
        
    //     const handleChange = (event) => {
    //       const selectedValue = event.target.value;
    //       setData((prevData) => ({
    //         ...prevData,
    //         papier: {
    //           value: selectedValue,
    //         },
    //       }));
    //     };
      
    //     selectElement.addEventListener('change', handleChange);
      
    //     return () => {
    //       selectElement.removeEventListener('change', handleChange);
    //     };
    // });

    // Nazwa materiału Okładki (okladka flores, okladka eco valvet)
    useEffect(() => {
        const selectElement = document.querySelector('select[name="properties[Materiał okładki]"]');
        
        const handleChange = (event) => {
          const selectedValue = event.target.value;
          setData((prevData) => ({
            ...prevData,
            typ_okładki: {
              type: selectedValue,
            },
          }));
        };
      
        selectElement.addEventListener('change', handleChange);
      
        return () => {
          selectElement.removeEventListener('change', handleChange);
        };
    }, []);


    //Materiał Okładki
    useEffect(() => {

      try {
          const boldOptionElements = document.querySelectorAll('.bold_option.bold_option_swatch .bold_option_element input[type="checkbox"]');

          const handleChange = (event) => {   
              if (event.target.checked) {
                  boldOptionElements.forEach((checkbox) => {
                      if (checkbox !== event.target) {
                          checkbox.checked = false;
                      }
                  });
      
                  const selectedValue = event.target.value;
                  setData((prevData) => ({
                      ...prevData,
                      materiał_okładki: {
                          value: selectedValue,
                      },
                  }));
              } else {
                  setData((prevData) => ({
                      ...prevData,
                      materiał_okładki: { 
                          value: '',
                      },
                  }));
              }
          };
        
          boldOptionElements.forEach((checkbox) => {
              checkbox.addEventListener('change', handleChange);
          });
          
          return () => {
              boldOptionElements.forEach((checkbox) => {
                  checkbox.removeEventListener('change', handleChange);
              });
          };
  
      } catch (error) {
          console.error(error);
      }
      }, []);

    //Personalizacja okładki
    useEffect(() => {
      try {
        const checkboxElements = document.querySelectorAll('.bold_option.bold_option_checkboxmulti .bold_option_value_element input[type="checkbox"]');
    
        const handleCheckboxChange = (event) => {
            const selectedValue = event.target.value;
            const isChecked = event.target.checked;
        
            setData((prevData) => {
            const updatedPersonalizacja = isChecked
                ? [...prevData.personalizacja_okładki.value, selectedValue]
                : prevData.personalizacja_okładki.value.filter((value) => value !== selectedValue);
              
            return {
                ...prevData,
                personalizacja_okładki: {
                value: updatedPersonalizacja,
                },
            };
            });
        };
    
        checkboxElements && checkboxElements.forEach((checkbox) => {
            checkbox.addEventListener('change', handleCheckboxChange);
        });
        
        return () => {
            checkboxElements && checkboxElements.forEach((checkbox) => {
            checkbox.removeEventListener('change', handleCheckboxChange);
            });
        };
        
      } catch (error) {
          console.error(error)
      }
    
    }, []);

    // realizacja pokazywania button KONFIGURUJ
    useEffect(() => {
      const handleChange = () => {
        const anySelected = data.materiał_okładki.value && data.personalizacja_okładki.value.length > 0;
        setShowButtonKonfiguracji(anySelected)
      }
      handleChange()
    }, [data.materiał_okładki.value, data.personalizacja_okładki.value])

  return null;
};

export default Effects;