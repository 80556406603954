
export const positionsStudioLOGO = ['Back-cover', 'Endpapers']

export const matrixSizes = {
    "5x1": { width: 12.7, height: 2.5 },
    "5x2": { width: 12.7, height: 5 },
    "5x5": { width: 12.7, height: 12.7 },
};

export const matrixSizesFor10x10 = {
    "2.5x1": { width: 6.35, height: 2.5 },
    "2.5x1.5": { width: 6.35, height: 3.81 },
    "2.5x2.5": { width: 6.35, height: 6.35 },
}; 
export const rozmiarMatrycy = ['5x1', '5x2', '5x5']

export const rozmiaryMatrycy10x10 = ['2.5x1', '2.5x1.5', '2.5x2.5']

export const sizesPictureToUpload = {
    '5x1' : {maxSize: '1500x300' , minSize: '1400x250'},
    '5x2' : {maxSize: '1500x600' , minSize: '1400x550'},
    '5x5' : {maxSize: '1500x1500' , minSize: '1400x1400'},
}