import lokalizacjaCenterGrzbiet from '../../assets/GrzbietCenter.png'
import lokalizacjaDolGrzbiet from '../../assets/GrzbietDol.png'

export const localizacjaTłoczeniaGrzbiet = [
    {text: 'Center Text', id: 'Center Text', path: lokalizacjaCenterGrzbiet, },
    {text: 'Bottom Text', id: 'Bottom Text', path: lokalizacjaDolGrzbiet,},
    // {text: 'Vertical Text', id: 'Vertical Text', path: 'https://static.boldcommerce.com/options/swatch/540872/80efc3d1d0c17ee272ddf7e735c030c9.png',}
]

export const SizesGrzbiet = {
    XS: '4X4', 
    S:  '6X6',
    M:  '8X8',
    L:  '10X10',
    XL: '12X12'
}

export const maxSignsGrzbiet = {
    [SizesGrzbiet.XS]: 10,
    [SizesGrzbiet.S]: 15,
    [SizesGrzbiet.M]: 20,
    [SizesGrzbiet.L]: 25,
    [SizesGrzbiet.XL]: 25,
}

export const colorMapGrzbiet = {
    'Plain': 'plain',
    'White': 'white',
    'Gold': 'gold',
    'Silver': 'silver',
    'Copper': 'copper'
};

export const positionMapGrzbiet = {
    'Center Text': 'center_grzbiet',
    'Bottom Text': 'dol_grzbiet'
};

export const odstepDolGrzbiet = {
    30: 2,
    25: 2,
    20: 2,
    15: 1.5,
    10: 1
};

export const baseFontSizesFor1000pxGrzbiet = {
    "15x15": {
        "2-1": 45,
    },
    "20x20": {
        "2-1": 34.4,
    },
    "25x25": {
        "2-1": 27.7,
    },
    "30x30": {
        "2-1": 23.3,
    },
};