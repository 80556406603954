import React, { useContext, useEffect, useRef, useState } from 'react';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { photoSizesForCropper, photoSizesForWizualizator } from '../../constants/dataPhotopanel/dataPhotopanel';
import { AppContext } from '../../context/Contex';
import style from './CropperModal.module.css'

const CropperModal = ({ imageUrl, onCrop , isCropperOpen, setIsCropperOpen}) => {
    const imageRef = useRef(null);
    const {albumSize} = useContext(AppContext)
    const [cropperInstance, setCropperInstance] = useState(null); 
    const [cropDataForMarcel, setCropDataForMarcel] = useState('')


    const computeBorderWidth = (cropperInstance) => {
        const cropBoxData = cropperInstance.getCropBoxData();
        const albumWidthInPixels = photoSizesForCropper[albumSize.size_width * 2.5].width;
        const actualWidthInPixels = cropBoxData.width;
        const ratio = actualWidthInPixels / albumWidthInPixels;
        return ratio * 2.5 * 118;
    };

    const getImgBleedSize = (cropperInstance) => {
        const cropData = cropperInstance.getData();
        
        return cropData.width / (photoSizesForCropper[albumSize.size_width * 2.50].width / 118) * 2.5 
    }

    useEffect(() => {
        if (imageUrl && imageRef.current) {
            imageRef.current.classList.remove('cropper-hidden');
            const cropper = new Cropper(imageRef.current, {
                viewMode: 3,
                responsive: true,
                background: false,
                zoomable: false, 
                zoomOnWheel: false,
                zoomOnTouch: false,
                modal: false,


                aspectRatio: photoSizesForCropper[albumSize.size_width * 2.5].width / photoSizesForCropper[albumSize.size_width * 2.5].height,
                ready: function () {
                    const borderWidth = computeBorderWidth(cropper);
                    const cropBoxElement = document.querySelector('.cropper-crop-box');
                    if (cropBoxElement) {
                        const createBorderElement = (width) => {
                            const div = document.createElement('div');
                            div.className = 'customBorder';
                            Object.assign(div.style, {
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                                borderLeft: 'none',
                                pointerEvents: 'none',
                                borderRight: `${width}px solid #fff600`,
                                borderTop: `${width}px solid #fff600`,
                                borderBottom: `${width}px solid #fff600`,
                                opacity: '0.3'
                            });
                            return div;
                        };
        
                        cropBoxElement.appendChild(createBorderElement(borderWidth));
                    }
                }
            });
            
            const handleCropMove = () => {
                const borderWidth = computeBorderWidth(cropper);
                const customBorder = document.querySelector('.customBorder');
                if (customBorder) {
                    customBorder.style.borderTopWidth = `${borderWidth}px`;
                    customBorder.style.borderRightWidth = `${borderWidth}px`;
                    customBorder.style.borderBottomWidth = `${borderWidth}px`;
                }
            };
    
            imageRef.current.addEventListener('cropmove', handleCropMove);
    
            setCropperInstance(cropper);
    
            return () => {
                if (cropper && cropper.container) {
                    if (imageRef.current) {
                        imageRef.current.removeEventListener('cropmove', handleCropMove);
                    }
                    cropper.destroy();
                }
            };
        }
    }, [imageUrl, albumSize]);

    const handleCropInPhotopanelCropper = () => {
        if (cropperInstance) {
            let cropData = cropperInstance.getData();
            const borderWidthInPixels = getImgBleedSize(cropperInstance);

            cropData.y += borderWidthInPixels;
            cropData.width -= borderWidthInPixels;
            cropData.height -= 2 * borderWidthInPixels;
            
            setCropDataForMarcel(cropData)

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
                
            const targetWidth = photoSizesForWizualizator[albumSize.size_width * 2.5].width;
            const targetHeight = photoSizesForWizualizator[albumSize.size_width * 2.5].height;
    
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            
            ctx.drawImage(
                imageRef.current,
                cropData.x, cropData.y, cropData.width, cropData.height,  
                0, 0, targetWidth, targetHeight 
            );
    
            const croppedImageUrl = canvas.toDataURL('image/jpeg');
    
            if (canvas.width < targetWidth || canvas.height < targetHeight) {
                alert("Zbyt niska rozdzi...");
            } else {
                onCrop(croppedImageUrl);
            }
        }
    };


    return (
      
        <div className={style.modalOverlay}>
            <div className={style.modalContent} >
                <img ref={imageRef} src={imageUrl} alt="For cropping" crossOrigin="anonymous" />
            </div>
            <div className = {style.containerWithButtonsCropPhotopanel}>
                <button className = {style.buttonCropPhotopanel1} onClick={() => setIsCropperOpen(false)}>Cancel</button>
                <button className = {style.buttonCropPhotopanel2} onClick={handleCropInPhotopanelCropper}>Crop</button>
            </div>
        </div>
    );
};

export default CropperModal;

