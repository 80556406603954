import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import style from './Overprint.module.css'
import { AppContext, MainStateModal } from '../../../../context/Contex';
import { overprintColorsWizualizator } from '../../../../constants/dataOverprint/dataOverprint';
import { lokalizacjaOverprintWizualizator } from '../../../../constants/dataOverprint/dataOverprint';
import { fontsMappingWizualizator } from '../../../../constants/data';
import { useResponsiveFontSize } from '../../../../hooks/useResponsiveFontSize';

const OverprintAlbum = () => {

    const{overprintLokalizacja, formData, sizePixels, lineHeightOverprint, setIsPopupVisable} = useContext(MainStateModal)
    const {albumSize} = useContext(AppContext)
    const refOverprintContainer = useRef(null)
   
    const [fontSizeLine1, setFontSizeLine1] = useState("20px");
    const [fontSizeLine2, setFontSizeLine2] = useState("20px");
    const [fontSizeLine3, setFontSizeLine3] = useState("20px");

    const [overprintRamkaOdstep, setOverprintRamkaOdstep] = useState({
        left: 0,
        others: 0
    });

    // для того чтобы попап не показывался постоянно как мы уменьшаем текст .
    const [canShowPopupOverprint, setCanShowPopupOverprint] = useState(true);
    const containerRefOverprintPopup = useRef(null);
    const textBlockRefOverprintPopup= useRef(null);
    
    useEffect(() => {
        const updateContainerWidth = () => {
            if (refOverprintContainer.current) {
                const albumSizeInPX = albumSize.size_width * 2.50;
                const containerWidth = refOverprintContainer.current.offsetWidth;
                const oneCM = containerWidth / (albumSizeInPX + 1);
                

                // tutaj nie ma dodatkowego kontainera, jest zrobione ze odstęp ramki powiększony na 2cm
                const paddingFactorOverprintLeft = 3.5;
                
                const paddingFactorOverprintOther = 1.5;
                const calculatedPaddingOverprintLeft = oneCM * paddingFactorOverprintLeft;
                const calculatedPaddingOverprintOther = oneCM * paddingFactorOverprintOther;

                setOverprintRamkaOdstep({
                    left: calculatedPaddingOverprintLeft,
                    others: calculatedPaddingOverprintOther
                });
            }
        }
        
        updateContainerWidth();
    
        const resizeObserver = new ResizeObserver(updateContainerWidth);
        resizeObserver.observe(refOverprintContainer.current);
    
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [albumSize, refOverprintContainer]);

   
    useLayoutEffect(() => {
        if (!containerRefOverprintPopup.current || !textBlockRefOverprintPopup.current) {
            return;
        }
    
        const checkTextOverflow = () => {
            const container = containerRefOverprintPopup.current;
            const textBlock = textBlockRefOverprintPopup.current;
            
            if (!container || !textBlock) {
                return;
            }
            
            if (textBlock.offsetWidth > container.offsetWidth || textBlock.offsetHeight > container.offsetHeight) {
                if (canShowPopupOverprint) {
                    setIsPopupVisable(prev => ({
                        ...prev, 
                        isPopupVisibleOverprint: true
                    }));
                    setCanShowPopupOverprint(false);
                }
            } else {
                setCanShowPopupOverprint(true);
            }
        };
    
        const resizeObserver = new ResizeObserver(checkTextOverflow);
        resizeObserver.observe(containerRefOverprintPopup.current);
        resizeObserver.observe(textBlockRefOverprintPopup.current);
    
        return () => resizeObserver.disconnect();

    }, [canShowPopupOverprint]);



    // liczenie rozmiaru w kontainerze tekstu przy skalowaniu 
    useResponsiveFontSize(
        refOverprintContainer, 
        [sizePixels.sizePixelLine1, sizePixels.sizePixelLine2, sizePixels.sizePixelLine3], 
        [setFontSizeLine1, setFontSizeLine2, setFontSizeLine3]
    );

    
    const chosenOverprintColor = overprintColorsWizualizator[formData.selectedColorOverprint] || 'gold';
    const lokalizacjaOverprintWizualizatorr = lokalizacjaOverprintWizualizator[overprintLokalizacja] || 'Visual_Center';

    const chosenOverprintFontLine1 = fontsMappingWizualizator[formData.overprintFontLine1] || '';
    const chosenOverprintFontLine2 = fontsMappingWizualizator[formData.overprintFontLine2] || '';
    const chosenOverprintFontLine3 = fontsMappingWizualizator[formData.overprintFontLine3] || '';

    return (
        <>
            <div className={style.container_overprint} ref = {refOverprintContainer}>
                <div id='containerWithFramesOverprint' className={style.container_overprint_border} ref={containerRefOverprintPopup} style={{left: overprintRamkaOdstep.left, right: overprintRamkaOdstep.others, top: overprintRamkaOdstep.others, bottom: overprintRamkaOdstep.others}}>
                    <div id='containerWithTextOverprint' ref = {textBlockRefOverprintPopup} className={`${style[chosenOverprintColor]} ${style[lokalizacjaOverprintWizualizatorr]} ${style.block_with_text_overprint}`}>
                        <p className={style[chosenOverprintFontLine1]} style={{ fontSize: fontSizeLine1, lineHeight: lineHeightOverprint }}>
                            {formData.lineOverprint1}
                        </p>
                        <p className={style[chosenOverprintFontLine2]} style={{ fontSize: fontSizeLine2, lineHeight: lineHeightOverprint }}>
                            {formData.lineOverprint2}
                        </p>
                        <p className={style[chosenOverprintFontLine3]} style={{ fontSize: fontSizeLine3, lineHeight: lineHeightOverprint }}>
                            {formData.lineOverprint3}
                        </p>
                    </div>
                </div>
            </div>
        </>
       
    );
};

export default OverprintAlbum;



