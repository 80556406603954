import React, { useContext, useEffect, useRef, useState } from 'react';
import { Colors, allowedCharacters, availableColors, specialCharacters } from '../../constants/data';
import { AppContext, MainStateModal } from '../../context/Contex';
import Cameo from '../localizacjaTloczenia/cameo/Cameo';
import Matryca from '../localizacjaTloczenia/matryca/Matryca';
import Overprint from '../localizacjaTloczenia/overprint/Overprint';
import StudioLOGO from '../localizacjaTloczenia/studioLOGO/StudioLOGO';
import TloczenieGrzbiet from '../localizacjaTloczenia/tloczenieGrzbiet/TloczenieGrzbiet';
import TloczenieOkladka from '../localizacjaTloczenia/tloczenieOkladka/TloczenieOkladka';
import style from '../modal/Modal.module.css';
import Wizualizator from '../wizualizator/Wizualizator';
import { localizacjaTłoczeniaGrzbiet } from '../../constants/dataTloczenie/dataTloczenieGrzbiet';
import { rozmiarMatrycy, rozmiaryMatrycy10x10 } from '../../constants/dataStudioLogo/dataStudioLogo';
import { positionsCameo } from '../../constants/dataCameo/dataCameo';
import Popup from '../../utilities/popup/Popup';
import logoFirmy from '../../assets/logoFirmy.png'
import lineTop from '../../assets/LineTop.png'
import lineBottom from '../../assets/LineBigBottom.png'
import { imageOptionsMatryca } from '../../constants/dataMatryca/dataMatryca';
import html2canvas from 'html2canvas';
import Photopanel from '../localizacjaTloczenia/photopanel/Photopanel';

const Modal = () => {

    const {albumSize, data, active, setActive} = useContext(AppContext)

    // название выбранного альбома для хэдера 
    const [titleText, setTitleText] = useState('');

    useEffect(() => {
        const h1Element = document.querySelector('.product-single__title');
        if (h1Element) {
            setTitleText(h1Element.textContent);
        }
    }, []);

    //TABY
    const [activeTab, setActiveTab] = useState({ key: '', component: null });

    //подставляю первое дефолтно выбранное значение для размера матрицы.
    useEffect(() => {
        const albumWidth = albumSize.size_width * 2.5;

        const selectedRozmiar = albumWidth === 10 
          ? rozmiaryMatrycy10x10[0] 
          : rozmiarMatrycy[0];
      
        setFormData(prevState => ({
          ...prevState,  
          selectedRozmiar,  
        }));
      
      }, [albumSize]);

    const[formData, setFormData] = useState({
        line1: '',
        line2: '',
        selectedFont1: '1-1',
        selectedFont2: '1-1',
        selectedColor: 'Plain',

        lineGrzbiet: '',
        selectedColorGrzbiet: 'Plain',
        selectedImageGrzbiet: localizacjaTłoczeniaGrzbiet[0],
        fontSizeGrzbiet: '2-1',

        selectedColorOverprint: 'Gold',
        lineOverprint1: '',
        lineOverprint2: '',
        lineOverprint3: '',
        overprintFontLine1: 'Charlotte',
        overprintFontLine2: 'Charlotte',
        overprintFontLine3: 'Charlotte',
        overprintLokalizacja: 'Visual Center',

        lineMatryca1: '',
        lineMatryca2: '',
        lineMatryca3: '',
        matrycaFontLine1: 'Charlotte',
        matrycaFontLine2: 'Charlotte',
        matrycaFontLine3: 'Charlotte',
        selectedColorMatryca: 'Plain',
        lokalizacjaMatryca: imageOptionsMatryca[4].label,
        selectedRozmiar: '',
        rozmiarCzcionkiLine1: 20,
        rozmiarCzcionkiLine2: 20,
        rozmiarCzcionkiLine3: 20,

        selectedtypeCameoOkienko: positionsCameo[0],
        // selectedOpcjaOkienka: 'Z ramką',
        selectedOpcjaOkienka: 'Bez ramki',

    })
    const [formDataInvalid, setFormDataInvalid] = useState({
        invalidCharacterLine1: '',
        invalidCharacterLine2: '',
        invalidCharacterGrzbiet: ''
    })
    const [lokalizacja, setLokalizacja] = useState('Wizualne Centrum')
    const [disabledColors, setDisabledColors] = useState([]);
    // errors of validations

    const [hasErrors, setHasErrors] = useState(false);
    const [isSaveAttempted, setIsSaveAttempted] = useState(false);
    const [showTabErrors, setShowTabErrors] = useState({});

    const [save, setSave] = useState([])

    //стейт для скриншота браузера
    const [saveScreenShotOfBrauser, setSaveScreenShotOfBrauser] = useState('')

    //kiedy klikamy na stworz projekt, potrzebujemy ten state dla sprawdzenia czy sa bledy i zeyby pokazywac popup
    const [isButtonSaveClickedPopup, setIsButtonClickedClickedPopup] = useState(false);

    // extra payment
    const [extraPaymentRequiredOkladka, setExtraPaymentRequiredOkladka] = useState(false);
   
    // show us additional components:
    const [dataAdditComponent, setDataAdditComponent] = useState({
        isGrzbietChosen: false,
        isOkladka: false,
        isStuidoLOGO:false,
        isOverprint: false,
        isMatryca: false,
        isCameo: false,
        isCameoAndOverprint: false,
        isCameoAndTloczenie: false,
        isCameoAndMatryca: false, 
        isPhotopanel: false,
    })
    // uploader studio logo + cameo
    const [urlLOGO, setUrlLOGO] = useState('');
    const [urlCameo, setUrlCameo] = useState('');
    const [urlPhotopanel, setUrlPhotopanel] = useState('')

    //studiLOGO
    const [dataStudioLogo, setDataStudioLogo] = useState({
        positionLOGO: 'Back-cover',
        rodzajOkienkaStudioLOGO: rozmiarMatrycy[0],
        selectedSizeInPxSTUDIOLOGO: { width: 0, height: 0 }
    })
    //pixels size in overprint album for czcionka
    const [sizePixels, setSizePixels] = useState({
        sizePixelLine1: 20,
        sizePixelLine2: 20,
        sizePixelLine3: 20,
        sizePixelLine1Matryca: 20
    });

    const {sizePixelLine1,sizePixelLine2,sizePixelLine3  } = sizePixels


    //line height in overprint forn fonts
    const [lineHeightOverprint, setLineHeightOverprint] = useState(1)
    //line height in matryca forn fonts
    const [lineHeightMatryca, setLineHeightMatryca] = useState(1)


    //показывать или нет попап о том что текст вышел за границы контейнера
    const [isPopupVisable, setIsPopupVisable] = useState({
        isPopupVisibleOverprint: false,
        isPopupVisibleMatryca: false,
        isPopupVisibleMatrycaAndCameo: false,
        isPopupVisibleOverprintAndCameo: false,
        isPopupVisableForSubmitInformation: false,
        isPopupVisableThatAllInfoWillDelete: false,

        isPopupVisableWhenChangingOkienkoType: false,

        isPopupVisableWhenIncorrectPhotoTypeLOGO: false,
        isPopupVisableWhenChangingRozmiarLOGO : false
    })

    const {isPopupVisableForSubmitInformation, isPopupVisableThatAllInfoWillDelete, isPopupVisableWhenIncorrectPhotoTypeLOGO} = isPopupVisable
    const sizeHeight = parseInt(albumSize.size_height * 2.5);
    const sizeWidth = parseInt(albumSize.size_width * 2.5);

    //COMPONENT CAMEO - ODSTĘPU PHOTO, HEIGHT, WIDTH
    const [windowStyle, setWindowStyle] = useState({});

    //переменная для адаптива контейнера
    const containerLeftRefWizualizator = useRef(null);
    // adaptive for grzbiet alone container
    const containerRefGrzbiet = useRef(null)


    //стейт для хранения картинки и замены при выборе материала окладки 
    const [imageSrcOkladka, setImageSrcOkladka] = useState(''); 

    // показываем див с алертом об дополнительной оплате при выборе разных kół
    const [dodatkowaOplataAlert, setDodatkowaOplataAlert] = useState(false);

    const {
        line1,
        line2,
        lineGrzbiet,
        selectedFont1,
        selectedFont2,
        selectedColor,
        selectedColorGrzbiet,
        selectedImageGrzbiet,

        selectedColorOverprint,
        lineOverprint1,
        lineOverprint2,
        lineOverprint3,
        overprintFontLine1,
        overprintFontLine2,
        overprintFontLine3,
        overprintLokalizacja,

        lineMatryca1,
        lineMatryca2,
        lineMatryca3,
        matrycaFontLine1,
        matrycaFontLine2,
        matrycaFontLine3,
        selectedColorMatryca,
        lokalizacjaMatryca,
        selectedRozmiar,
        rozmiarCzcionkiLine1,
        rozmiarCzcionkiLine2,
        rozmiarCzcionkiLine3,

        selectedtypeCameoOkienko,
        selectedOpcjaOkienka
    } = formData
    const{
        positionLOGO,
        rodzajOkienkaStudioLOGO,
        selectedSizeInPxSTUDIOLOGO
    } = dataStudioLogo

    const { 
        invalidCharacterLine1,
        invalidCharacterLine2,
        invalidCharacterGrzbiet
    } = formDataInvalid

    const{
        isGrzbietChosen,
        isOkladka,
        isStuidoLOGO,
        isOverprint,
        isMatryca,
        isCameo,
        isCameoAndOverprint,
        isCameoAndMatryca,
        isCameoAndTloczenie, 
        isPhotopanel
    } = dataAdditComponent
    
    //POKAZYWANIE ELEMENTÓW W MODALU W ZALEŻNOŚCI OD WYBRANEGO CHEKBOKSA
    useEffect(() => {
            const isTłoczenieGrzbietClicked = data.personalizacja_okładki.value.includes('Tłoczenie na grzbiecie');
            const isTłoczenieNaOkładce = data.personalizacja_okładki.value.includes('Tłoczenie na okładce')
            const isStuidoLOGO = data.personalizacja_okładki.value.includes('Studio LOGO Fotografa')
            const isOverprintClicked = data.personalizacja_okładki.value.includes('Overprint')
            const isMatrycaClicked = data.personalizacja_okładki.value.includes('Tłoczenie z matrycy')
            const isCameoChosen = data.personalizacja_okładki.value.includes('Okienko')
            const isCameoAndOverprintClicked = data.personalizacja_okładki.value.includes('Cameo_Overprint')
            const isCameoAndMatrycaClicked= data.personalizacja_okładki.value.includes('Cameo_Matryca')
            const isCameoAndTloczenieClicked = data.personalizacja_okładki.value.includes('Cameo_Tloczenie')
            const isPhotopanelClicked = data.personalizacja_okładki.value.includes('Photopanel')


            const newAdditComponent = {
                isGrzbietChosen: isTłoczenieGrzbietClicked, 
                isOkladka: isTłoczenieNaOkładce, 
                isStuidoLOGO:isStuidoLOGO, 
                isOverprint: isOverprintClicked, 
                isMatryca: isMatrycaClicked,
                isCameo:isCameoChosen,
                isCameoAndOverprint:isCameoAndOverprintClicked,
                isCameoAndMatryca: isCameoAndMatrycaClicked,
                isCameoAndTloczenie: isCameoAndTloczenieClicked,
                isPhotopanel: isPhotopanelClicked
            };

            setDataAdditComponent((prev) => ({...prev, ...newAdditComponent}))

    },[ data.personalizacja_okładki.value])

    // WALIDACJA INPUTÓW
    const selectAllowedChars = (font) => {
        // if (font === '3.5mm' || font === '6mm') {
        //   return specialCharacters;
        // }
        if (font === '1-2' || font === '2-2') {
            return specialCharacters;
          }
        return allowedCharacters;
    };

    const checkInvalidCharacters = (line, allowedCharsForValidation) => {
        return line.match(new RegExp(`[^${allowedCharsForValidation}]`, 'g'))?.join('') || '';
    };

    useEffect(() => {
        const allowedCharsForValidation1 = selectAllowedChars(selectedFont1);
        const allowedCharsForValidation2 = selectAllowedChars(selectedFont2);
      
        setFormDataInvalid((prevState) => ({
          ...prevState,
          invalidCharacterLine1: checkInvalidCharacters(line1, allowedCharsForValidation1),
          invalidCharacterLine2: checkInvalidCharacters(line2, allowedCharsForValidation2),
          invalidCharacterGrzbiet: checkInvalidCharacters(lineGrzbiet, allowedCharacters),
        }));
      
    }, [line1, line2, lineGrzbiet, selectedFont1, selectedFont2]);


    // save screenshot of brauser
    const captureScreen = async () => {
        const canvas = await html2canvas(document.body); 
        const screenshot = canvas.toDataURL();
        
        setSaveScreenShotOfBrauser(screenshot); 
    }

    //SUBMIT BUTTON
    useEffect(() => {
        console.log('Saved:', save)
    },[save])

    const submitInfo = (e) => {
        e.preventDefault();
        setIsSaveAttempted(true);
        let errorsExist = checkErrors();
       
        if (!errorsExist) {
            saveData(); 
            captureScreen()
            setIsSaveAttempted(false);
        }
        setHasErrors(errorsExist);
    };

    const checkErrors = () => {
        let errorsExist = false;
        let newTabErrors = {}; 

        if (isOkladka && (!selectedFont1 || !selectedFont2 || !selectedColor || invalidCharacterLine1.length > 0 || invalidCharacterLine2.length > 0 || line1.length <= 0 || !lokalizacja)) {
          errorsExist = true;
          newTabErrors.isOkladka = true;
        }
        
        if (isGrzbietChosen && (!selectedColorGrzbiet || selectedImageGrzbiet === null || invalidCharacterGrzbiet.length > 0 || lineGrzbiet.length <= 0)) {
            errorsExist = true;
            newTabErrors.isGrzbietChosen = true;
        }

        if (isStuidoLOGO && (!urlLOGO || !rodzajOkienkaStudioLOGO || !positionLOGO)) {
            errorsExist = true;
            newTabErrors.isStuidoLOGO = true;
        }

        if(isOverprint && (!selectedColorOverprint || lineOverprint1.length <= 0 || !overprintFontLine1 || !overprintFontLine2 || !overprintFontLine3 || !overprintLokalizacja || !sizePixelLine1 || !sizePixelLine2 || !sizePixelLine3 )){
            errorsExist = true;
            newTabErrors.isOverprint = true;
        }
       
        if(isMatryca && (!selectedColorMatryca || lineMatryca1.length <= 0 || !lokalizacjaMatryca || !selectedRozmiar )){
            errorsExist = true;
            newTabErrors.isMatryca = true;
        }

        if(isCameo && (!urlCameo || !selectedtypeCameoOkienko ||!selectedOpcjaOkienka)){
            errorsExist = true;
            newTabErrors.isCameo = true;
        }

        if(isCameoAndOverprint && (!urlCameo || !selectedtypeCameoOkienko ||!selectedOpcjaOkienka || !selectedColorOverprint || lineOverprint1.length <= 0 || !overprintFontLine1 || !overprintFontLine2 || !overprintFontLine3 || !sizePixelLine1 || !sizePixelLine2 || !sizePixelLine3 )){
            errorsExist = true;
            newTabErrors.isCameoAndOverprint = true;
        }

        if(isCameoAndMatryca && (!urlCameo || !selectedtypeCameoOkienko ||!selectedOpcjaOkienka || !selectedColorMatryca || lineMatryca1.length <= 0 || !selectedRozmiar)){
            errorsExist = true;
            newTabErrors.isCameoAndMatryca = true;
        }

        if(isCameoAndTloczenie && (!urlCameo || !selectedtypeCameoOkienko ||!selectedOpcjaOkienka || !selectedFont1 || !selectedFont2 || !selectedColor || invalidCharacterLine1.length > 0 || invalidCharacterLine2.length > 0 || line1.length <= 0  )){
            errorsExist = true;
            newTabErrors.isCameoAndTloczenie = true;
        }

        if(isPhotopanel && !urlPhotopanel) {
            errorsExist = true;
            newTabErrors.isPhotopanel = true;
        }

        setShowTabErrors(newTabErrors);
        return errorsExist;
    };

    const saveData = () => {
        const newData = {};
        
            if(isOkladka){
                newData.line1 = line1;
                newData.line2 = line2;
                newData.selectedFont1 = selectedFont1;
                newData.selectedFont2 = selectedFont2;
                newData.lokalizacja = lokalizacja;
                newData.selectedColor = selectedColor;
            }
            if (isGrzbietChosen) {
                newData.lineGrzbiet = lineGrzbiet;
                newData.selectedColorGrzbiet = selectedColorGrzbiet;
                newData.selectedImageGrzbiet = selectedImageGrzbiet;
            }
            
            if (isStuidoLOGO) {
                newData.urlLOGO = urlLOGO
                newData.rodzajOkienkaStudioLOGO = rodzajOkienkaStudioLOGO
                newData.positionLOGO = positionLOGO
            }
            
            if(isOverprint){
                newData.lineOverprint1 = lineOverprint1
                newData.lineOverprint2 = lineOverprint2
                newData.lineOverprint3 = lineOverprint3
                newData.selectedColorOverprint = selectedColorOverprint
                newData.overprintFontLine1 = overprintFontLine1
                newData.overprintFontLine2 = overprintFontLine2
                newData.overprintFontLine3 = overprintFontLine3
                newData.overprintLokalizacja = overprintLokalizacja
                newData.sizePixelLine1 = sizePixelLine1
                newData.sizePixelLine2 = sizePixelLine2
                newData.sizePixelLine3 = sizePixelLine3
            }

            if(isMatryca){
                newData.selectedColorMatryca = selectedColorMatryca
                newData.lineMatryca1 = lineMatryca1
                newData.lineMatryca2 = lineMatryca2
                newData.lineMatryca3 = lineMatryca3
                newData.matrycaFontLine1 = matrycaFontLine1
                newData.matrycaFontLine2 = matrycaFontLine2
                newData.matrycaFontLine3 = matrycaFontLine3
                newData.rozmiarCzcionkiLine1 = rozmiarCzcionkiLine1
                newData.rozmiarCzcionkiLine2 = rozmiarCzcionkiLine2
                newData.rozmiarCzcionkiLine3 = rozmiarCzcionkiLine3
                newData.lokalizacjaMatryca = lokalizacjaMatryca
                newData.selectedRozmiar = selectedRozmiar
            }
           
            if(isCameo){
                newData.selectedtypeCameoOkienko = selectedtypeCameoOkienko
                newData.urlCameo = urlCameo
                newData.selectedOpcjaOkienka = selectedOpcjaOkienka
            }
            if(isCameoAndTloczenie){
                newData.selectedtypeCameoOkienko = selectedtypeCameoOkienko
                newData.urlCameo = urlCameo
                newData.selectedOpcjaOkienka = selectedOpcjaOkienka
                newData.line1 = line1;
                newData.line2 = line2;
                newData.selectedFont1 = selectedFont1;
                newData.selectedFont2 = selectedFont2;
                newData.selectedColor = selectedColor;
            }
            if(isCameoAndMatryca){
                newData.selectedtypeCameoOkienko = selectedtypeCameoOkienko
                newData.urlCameo = urlCameo
                newData.selectedOpcjaOkienka = selectedOpcjaOkienka
                newData.selectedColorMatryca = selectedColorMatryca
                newData.lineMatryca1 = lineMatryca1
                newData.lineMatryca2 = lineMatryca2
                newData.lineMatryca3 = lineMatryca3
                newData.matrycaFontLine1 = matrycaFontLine1
                newData.matrycaFontLine2 = matrycaFontLine2
                newData.matrycaFontLine3 = matrycaFontLine3
                newData.rozmiarCzcionkiLine1 = rozmiarCzcionkiLine1
                newData.rozmiarCzcionkiLine2 = rozmiarCzcionkiLine2
                newData.rozmiarCzcionkiLine3 = rozmiarCzcionkiLine3
                newData.selectedRozmiar = selectedRozmiar
            }

            if(isCameoAndOverprint){
                newData.selectedtypeCameoOkienko = selectedtypeCameoOkienko
                newData.urlCameo = urlCameo
                newData.selectedOpcjaOkienka = selectedOpcjaOkienka
                newData.lineOverprint1 = lineOverprint1
                newData.lineOverprint2 = lineOverprint2
                newData.lineOverprint3 = lineOverprint3
                newData.selectedColorOverprint = selectedColorOverprint
                newData.overprintFontLine1 = overprintFontLine1
                newData.overprintFontLine2 = overprintFontLine2
                newData.overprintFontLine3 = overprintFontLine3
                newData.sizePixelLine1 = sizePixelLine1
                newData.sizePixelLine2 = sizePixelLine2
                newData.sizePixelLine3 = sizePixelLine3
            }
            
            if(extraPaymentRequiredOkladka){
                newData.extraPaymentRequiredOkladka = extraPaymentRequiredOkladka
            }

            if(saveScreenShotOfBrauser){
                newData.saveScreenShotOfBrauser = saveScreenShotOfBrauser
            }

            if(isPhotopanel) {
                newData.urlPhotopanel = urlPhotopanel
            }

            setSave(newData);
            setIsSaveAttempted(false); 
    };
    // OPCJA WYBORU I BLOKOWANIA KOLORÓW
    useEffect(() => {
        const material = data.materiał_okładki.value;
        const colors = Object.keys(availableColors);
        
        if (material && colors.includes(material)) {
            const materialColors = availableColors[material];
            const disabledColors = materialColors
              .map((value, index) => (value === 0 ? Colors[index] : null))
              .filter((color) => color !== null);
            setDisabledColors(disabledColors);
        }
    }, [data.materiał_okładki.value]);

    
    // delete info when we are closing modal
    const deleteAllInfo = () => {

        const albumWidth = albumSize.size_width * 2.5;

        const defaultRozmiar = albumWidth === 10 
        ? rozmiaryMatrycy10x10[0] 
        : rozmiarMatrycy[0];

        setFormData((prev) => ({
            ...prev,
            line1: '',
            line2: '',
            lineGrzbiet: '',
            selectedFont1: '1-1',
            selectedFont2: '1-1',
            selectedColor: 'Plain',
            lokalizacja: '',
            selectedColorGrzbiet: 'Plain',
            selectedImageGrzbiet: localizacjaTłoczeniaGrzbiet[0],
            selectedColorOverprint: 'Gold',
            lineOverprint1: '',
            lineOverprint2: '',
            lineOverprint3: '',
            overprintFontLine1: 'Charlotte',
            overprintFontLine2: 'Charlotte',
            overprintFontLine3: 'Charlotte',
            overprintLokalizacja: 'Visual Center',


            lineMatryca1: '',
            lineMatryca2: '',
            lineMatryca3: '',
            selectedColorMatryca: 'Plain',
            lokalizacjaMatryca: imageOptionsMatryca[5].label,
            selectedRozmiar: defaultRozmiar,
            matrycaFontLine1: 'Charlotte',
            matrycaFontLine2: 'Charlotte',
            matrycaFontLine3: 'Charlotte',
            rozmiarCzcionkiLine1: 20,
            rozmiarCzcionkiLine2: 20,
            rozmiarCzcionkiLine3: 20,

            typeCameoOkienko: '',
            selectedOpcjaOkienka: 'Bez ramki',
            selectedtypeCameoOkienko: positionsCameo[0],
        }));
        setDataStudioLogo((prev) =>({
            ...prev, 
            positionLOGO: 'Back-cover',
            rodzajOkienkaStudioLOGO: rozmiarMatrycy[0],
        }))

        setUrlLOGO('');
        setUrlCameo('');
        setUrlPhotopanel('');
        setSizePixels((prev) => ({
            ...prev,
            sizePixelLine1: 20,
            sizePixelLine2: 20,
            sizePixelLine3: 20
        }))
        setHasErrors(false)
        setIsSaveAttempted(false)

        setSaveScreenShotOfBrauser('')

        if (containerRefGrzbiet.current) {
            containerRefGrzbiet.current.style.width = ''; 
        }
        setDodatkowaOplataAlert(false)
        // setImageSrcOkladka('')

    }    

    const stateNamesMapping = {
        isOkladka: { name: 'Tłoczenie na okładce', component: <TloczenieOkladka /> },
        isGrzbietChosen: { name: 'Tłoczeniena grzbiecie', component: <TloczenieGrzbiet /> },
        isStuidoLOGO: { name: 'Studio LOGO Fotografa', component: <StudioLOGO /> },
        isOverprint: { name: 'Overprint', component: <Overprint /> },
        isMatryca: { name: 'Tłoczenie z matrycy', component: <Matryca /> },
        isCameo: { name: 'Cameo', component: <Cameo /> },
        isCameoAndOverprint: [
            { name: 'Cameo', component: <Cameo /> },
            { name: 'Overprint', component: <Overprint /> },
        ],
        isCameoAndMatryca: [
            { name: 'Cameo', component: <Cameo /> },
            { name: 'Matryca', component: <Matryca /> },
        ],
        isCameoAndTloczenie: [
            { name: 'Cameo', component: <Cameo /> },
            { name: 'Tłoczenie na okładce', component: <TloczenieOkladka /> },
        ],
        isPhotopanel : {name: 'Photopanel', component: <Photopanel /> }
    };
  




    
    function sortKeysByTabPriority(keys) {
        return keys.sort((a, b) => {
            // чтобы "Tłoczenie na okładce" был первым
            if (a === "isOkladka") return -1; 
            if (b === "isOkladka") return 1;
            if (a === "isGrzbietChosen") return 1; 
            if (b === "isGrzbietChosen") return -1;
            return 0; 
        });
    }
    

    // pokazywanie naszego pierwszego wybranego czekboksa , jego funkcjonalnć odrazu przy otwarciu konfiguratora
    useEffect(() => {
        const sortedKeys = sortKeysByTabPriority(Object.keys(dataAdditComponent));
        const activeCheckbox = sortedKeys.find(key => dataAdditComponent[key]);
    
        if (activeCheckbox) {
            const isCombo = Array.isArray(stateNamesMapping[activeCheckbox]);
            const component = isCombo
                ? stateNamesMapping[activeCheckbox][0].component 
                : stateNamesMapping[activeCheckbox].component;
            
            const activeKey = isCombo ? activeCheckbox + '0' : activeCheckbox;
            setActiveTab({ key: activeKey, component });
            setLastSelectedComponent(activeKey); 
        }
    }, [dataAdditComponent]);


    
    //pokazywanie popup if button save clicked and są błędy
    useEffect(() => {
        if (isButtonSaveClickedPopup && hasErrors) {
          setIsPopupVisable({ ...isPopupVisable, isPopupVisableForSubmitInformation: true });
        }
       
    }, [isButtonSaveClickedPopup, hasErrors]);


    //information about delete all info will delete whe we are closing modal
    const handleDeleteAllInfoPopupWhenClosingConfirm = () => {
        setActive(false);
        deleteAllInfo();
        setIsPopupVisable({ ...isPopupVisable, isPopupVisableThatAllInfoWillDelete: false });
    };

    const handleShowPopup = () => {
        setIsPopupVisable({ ...isPopupVisable, isPopupVisableThatAllInfoWillDelete: true });
    };
    // closing popup
    const handleClosePopup = () => {
        setIsPopupVisable({ ...isPopupVisable, isPopupVisableThatAllInfoWillDelete: false });
    };

    // okienko popup when changing type okienka
    useEffect(() => {
        if (urlCameo) {  
            setUrlCameo('');

            setIsPopupVisable({
                ...isPopupVisable,
                isPopupVisableWhenChangingOkienkoType: true
            });
        }
    }, [selectedtypeCameoOkienko]);

    const handleDeletePhotoChangedTypeOkienka = () => {
        setIsPopupVisable({
            ...isPopupVisable,
            isPopupVisableWhenChangingOkienkoType: false
        });
    };


    const [lastSelectedComponent, setLastSelectedComponent] = useState(null);

    const handleTabClick = (key, component) => {
        setActiveTab({ key: key, component: component });
        if (key !== 'isGrzbietChosen') {
            setLastSelectedComponent(key);
        }
    };


  return (  
    <MainStateModal.Provider value={{urlPhotopanel, isPhotopanel, setUrlPhotopanel, isPopupVisable, setIsPopupVisable, setFormData, lastSelectedComponent, selectedtypeCameoOkienko, setSizePixels, lineHeightMatryca, setLineHeightMatryca, rozmiarCzcionkiLine1,rozmiarCzcionkiLine2, rozmiarCzcionkiLine3,selectedFont1,selectedFont2, overprintLokalizacja, dodatkowaOplataAlert, setDodatkowaOplataAlert, isPopupVisableThatAllInfoWillDelete, imageSrcOkladka, setImageSrcOkladka, containerRefGrzbiet, containerLeftRefWizualizator, setIsPopupVisable,lineHeightOverprint, setLineHeightOverprint, setDataStudioLogo, selectedSizeInPxSTUDIOLOGO, rodzajOkienkaStudioLOGO, activeTab, windowStyle, setWindowStyle, isCameoAndMatryca, isCameoAndTloczenie,isCameoAndOverprint, selectedRozmiar, selectedOpcjaOkienka,isCameo,urlCameo, setUrlCameo,isMatryca,positionLOGO, sizePixels, setExtraPaymentRequiredOkladka, setSizePixels, isOverprint, selectedColorOverprint, sizeHeight, sizeWidth, urlLOGO, setUrlLOGO, isStuidoLOGO,isGrzbietChosen, isOkladka, lokalizacja, setLokalizacja,isSaveAttempted, hasErrors, albumSize, disabledColors, formData, setFormData, formDataInvalid, setFormDataInvalid}}>
        <div className = {active ? style.modal_active : style.modal }>
            {isPopupVisable.isPopupVisibleOverprint && isOverprint && <Popup isVisible={isPopupVisable.isPopupVisibleOverprint} text="Tekst wykroczył poza ewentualne pisanie tekstu!" onClose={() => setIsPopupVisable({ ...isPopupVisable, isPopupVisibleOverprint: false })} />}
            {isPopupVisable.isPopupVisibleMatryca && isMatryca && <Popup isVisible={isPopupVisable.isPopupVisibleMatryca} text="Tekst wykroczył poza ewentualne pisanie tekstu!" onClose={() => setIsPopupVisable({ ...isPopupVisable, isPopupVisibleMatryca: false })} />}
            {isPopupVisable.isPopupVisibleMatrycaAndCameo && isCameoAndMatryca && <Popup isVisible={isPopupVisable.isPopupVisibleMatrycaAndCameo} text="Tekst wykroczył poza ewentualne pisanie tekstu!" onClose={() => setIsPopupVisable({ ...isPopupVisable, isPopupVisibleMatrycaAndCameo: false })} />}
            {isPopupVisable.isPopupVisibleOverprintAndCameo && isCameoAndOverprint && <Popup isVisible={isPopupVisable.isPopupVisibleOverprintAndCameo} text="Tekst wykroczył poza ewentualne pisanie tekstu!" onClose={() => setIsPopupVisable({ ...isPopupVisable, isPopupVisibleOverprintAndCameo: false })} />}
            {isPopupVisable.isPopupVisableForSubmitInformation && <Popup isVisible={isPopupVisable.isPopupVisableForSubmitInformation}
                text={`Popraw błedy w zakładce: ${
                    Object.keys(showTabErrors)
                    .filter(key => showTabErrors[key])
                    .flatMap(key => {
                        const mapping = stateNamesMapping[key];
                        if (Array.isArray(mapping)) {
                            return mapping.map(item => item.name);
                        } else {
                            return [mapping.name];
                        }
                    }).join(', ')
                }`}
                onClose={() => setIsPopupVisable({ ...isPopupVisable, isPopupVisableForSubmitInformation: false })} />}
            {isPopupVisable.isPopupVisableThatAllInfoWillDelete && <Popup isVisible={isPopupVisable.isPopupVisableThatAllInfoWillDelete}  text="Wszystkie dane zostaną usunięte!" onClose={handleClosePopup} onConfirm={handleDeleteAllInfoPopupWhenClosingConfirm}/>}
            {isPopupVisable.isPopupVisableWhenChangingOkienkoType && (isCameo || isCameoAndOverprint || isCameoAndMatryca || isCameoAndTloczenie) && (
                <Popup
                    isVisible={isPopupVisable.isPopupVisableWhenChangingOkienkoType}
                    text="Zmieniłeś orientację okienka, musisz ponownie wykadrować zdjęcie!"
                    onConfirm={handleDeletePhotoChangedTypeOkienka}
                    onClose={() => setIsPopupVisable({
                        ...isPopupVisable,
                        isPopupVisableWhenChangingOkienkoType: false
                    })}
                />
            )}



            <div style={{position: 'absolute', top: '5px', right: '10px'}}>
                <span  onClick={handleShowPopup} style={{fontWeight: '800', cursor: 'pointer', color: '#484848'}}>X</span> 
            </div> 

            <div className={style.headerKonfigurator}>
                <div>
                    <img height= '65px' src = {logoFirmy} alt = 'logo' />
                </div>
                <div>
                    <div>
                        <h1 style = {{fontFamily: 'OldStandardTT', fontSize: '20px'}}>SNAP ALBUM SLIM / {`${sizeHeight}x${sizeWidth}`}</h1>
                    </div>
                    
                </div>
            </div>
            <div className={style.mainContainerWithAllContentKonfigurator}>
                <div className={style.wrapper}>
                    <div ref = {containerLeftRefWizualizator} className= {style.wrapperWizualizator}>
                        <Wizualizator/>
                    </div >
                        <div className={style.wrapperConstruktor}>
                            <div className={style.containerWithAllContentRight}>
                                <div className={style.containerWithForm}>
                                        <form id="myForm" onSubmit={submitInfo}>
                                            <div className={style.mainContainerForLinesWithTabs}>
                                                <div className={style.smallLine}>
                                                    <img src = {lineTop} alt = 'line'/>
                                                </div>
                                               <div className={style.containerWithTabs}>
                                                    {sortKeysByTabPriority(Object.keys(dataAdditComponent)).map((el) => {
                                                        if (dataAdditComponent[el]) {
                                                            return Array.isArray(stateNamesMapping[el])
                                                                ? stateNamesMapping[el].map((tab, idx) => {
                                                                    const currentKey = el + idx;
                                                                    return (
                                                                        <button
                                                                            onClick={() => handleTabClick(currentKey, tab.component)}
                                                                            // onClick={() => setActiveTab({ key: currentKey, component: tab.component })}
                                                                            type="button"
                                                                            className={`${style.tabOne} ${activeTab.key === currentKey ? style.activeTab : ''}`}
                                                                            key={currentKey}
                                                                            style={{ fontFamily: 'OldStandardTT' }}
                                                                        >
                                                                            {tab.name}
                                                                        </button>
                                                                    );
                                                                })
                                                                : (
                                                                    <button
                                                                        onClick={() => handleTabClick(el, stateNamesMapping[el].component)}
                                                                        // onClick={() => setActiveTab({ key: el, component: stateNamesMapping[el].component })}
                                                                        type="button"
                                                                        className={`${style.tabOne} ${activeTab.key === el ? style.activeTab : ''}`}
                                                                        key={el}
                                                                        style={{ fontFamily: 'OldStandardTT' }}
                                                                    >
                                                                        {stateNamesMapping[el].name}
                                                                    </button>
                                                                );
                                                        }
                                                        return null;
                                                    })}
                                                </div>
                                                <div style = {{width: '100%'}}>
                                                    <img height = '1px' width = '100% 'src = {lineBottom} alt = 'line'/>
                                                </div>
                                            </div>
                                               
                                                <div className={style.componentContainer}>
                                                    {activeTab.component}
                                                </div>
                                        </form>
                                </div>
                            </div>
                        </div>
                    
                </div>  
            </div>
            <div className={style.containerWithButtonsKOnfiguracji}>
                <div className={style.mainContainerWithbuttonsKinfiguracji} >
                    <button onClick={handleShowPopup} className={style.buttonSaveAllKonfigurator} type = 'button'>
                        ⭠ POWRÓT DO SNAP ALBUMS
                    </button>
                    <button form="myForm" onClick={() => setIsButtonClickedClickedPopup(true)} className={style.buttonSaveAllKonfigurator} type = 'submit'>
                        STWÓRZ MÓJ PROJEKT ⭢
                    </button>
                </div>
            </div>
        </div>
    </MainStateModal.Provider>
  );
};

export default Modal;

