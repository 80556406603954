import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './PhotopanelWizualizator.module.css'
import { AppContext, MainStateModal } from '../../../../context/Contex';

const PhotopanelWizualizator = () => {

    const { urlPhotopanel, setUrlPhotopanel} = useContext(MainStateModal);
    const {albumSize} = useContext(AppContext)

    const [szerokoscKontaineraWithPhotopanel, setSzerokoscKontaineraWithPhotopanel] = useState('')
    const containerRefPhotopanel = useRef(null)

    const updateDimensions = () => {
        if (containerRefPhotopanel.current) {
            const containerWidth = containerRefPhotopanel.current.offsetWidth;
            const oneCM = containerWidth / (albumSize.size_width * 2.50 + 1);
    
            const calculatedOdstępContaineraContent = oneCM * 3;
            const szerokoscKontaineraZContentem = containerWidth - calculatedOdstępContaineraContent;
            setSzerokoscKontaineraWithPhotopanel({
                width: szerokoscKontaineraZContentem
            });
        }
    };
    
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (containerRefPhotopanel.current) {
                updateDimensions();
            }
        });
        
        if (containerRefPhotopanel.current) {
            resizeObserver.observe(containerRefPhotopanel.current);
        }
        
        return () => {
            resizeObserver.disconnect();
        }
    }, [albumSize, containerRefPhotopanel]);

   
    return (
        <div className={style.containerWizualizatorPhotopanel} ref={containerRefPhotopanel}>
            <div style={{width: `${szerokoscKontaineraWithPhotopanel.width}px`}} className = {style.reducedContainerWithPhoto}>
                <div className={style.containerPhotoPhotopanel} >
                    {urlPhotopanel ? <img className={style.img_Cameo} src={urlPhotopanel} alt="Uploaded" /> : null}
                </div>
            </div>
        </div>
    );
};

export default PhotopanelWizualizator;