
import React, { useContext } from 'react';
import UploaderCameo from '../../uploader/UploaderCameo';
import { MainStateModal } from '../../../context/Contex';
import { positionsCameoOkienko, opcjaOkienkaOptions } from '../../../constants/dataCameo/dataCameo';
import style from './Cameo.module.css'

const Cameo = () => {
    const { selectedtypeCameoOkienko, setFormData, selectedOpcjaOkienka, windowStyle, setWindowStyle,hasErrors ,isSaveAttempted} = useContext(MainStateModal)

    return (
        <>
           
            <div>
                <p style={{fontWeight: '300'}}>Okienko:</p>
                <div className={style.cameoOptions}>
                        {positionsCameoOkienko.map((position) => (
                            <div 
                                key={position.label} 
                                className={`${style.cameoOption} `}
                                onClick={() => setFormData(prev => ({...prev, selectedtypeCameoOkienko: position.label}))}
                            >
                                <div className= {style.cameoOkeinkoOne}>
                                    <img className={`${selectedtypeCameoOkienko === position.label ? style.selected : style.notSelected}`} src={position.src} alt={position.label} />
                                    <span style={{marginTop: '7px', fontSize: '12px'}}>{position.label}</span>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            
            <div>
                <p style={{fontWeight: '300'}}>Ramka:</p>
                {selectedtypeCameoOkienko &&
                        <div className={style.opcjaOkienkaContainer}>
                            {opcjaOkienkaOptions.map((option) => (
                                <div 
                                    key={option.label} 
                                    className={style.opcjaOkienkaOption}
                                    onClick={() => setFormData(prev => ({...prev, selectedOpcjaOkienka: option.label}))}
                                >
                                    <div className={style.opcjaOkienkaOne}>
                                        <img 
                                            className={`${selectedOpcjaOkienka === option.label ? style.selected : style.notSelected}`}
                                            src={option.src} 
                                            alt={option.label}
                                        />
                                        <span style={{fontSize: '12px'}}>{option.label}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                }
                {hasErrors && isSaveAttempted && !selectedOpcjaOkienka && <p style={{ color: 'red', marginTop: '7px' }}>Musisz wybrać rodzaj okienka!</p>}
            </div>

            <div className={style.containerWithButtonZaladujPlik}>
                {selectedtypeCameoOkienko && selectedOpcjaOkienka && <UploaderCameo windowStyle = {windowStyle} setWindowStyle = {setWindowStyle}/>}
            </div>

        </>
    );
};

export default Cameo;