import React, { useContext, useEffect, useState } from 'react';
import { init } from 'filestack-js';
import { MainStateModal } from '../../context/Contex';
import style from './UploaderStyles.module.css'
import { sizesPictureToUpload } from '../../constants/dataStudioLogo/dataStudioLogo';
import Popup from '../../utilities/popup/Popup';


const UploaderLOGO = () => {
    
    const client = init('Ao0ziRhFORuKJepGBM5Icz');
    const {hasErrors, isSaveAttempted, rodzajOkienkaStudioLOGO, setIsPopupVisable, isPopupVisable, isStuidoLOGO} = useContext(MainStateModal);

    const {urlLOGO, setUrlLOGO} = useContext(MainStateModal);

    const selectedPhotoSize = sizesPictureToUpload[rodzajOkienkaStudioLOGO];
    const [showTekstWhenChangedRozmiar, setShowTekstWhenChangedRozmiar] = useState(false)
    //стейт для предыдущего знач размера, чтобы не показывался попап при перерендере компонента
    const [prevRodzaj, setPrevRodzaj] = useState(rodzajOkienkaStudioLOGO);


    useEffect(() => {
        if (urlLOGO && rodzajOkienkaStudioLOGO !== prevRodzaj) {
            setUrlLOGO(''); 
            setIsPopupVisable(prevState => ({...prevState, isPopupVisableWhenChangingOkienkoTypeLOGO: true}));
            setShowTekstWhenChangedRozmiar(true);
        }
        setPrevRodzaj(rodzajOkienkaStudioLOGO); 
    }, [rodzajOkienkaStudioLOGO]);

    console.log(rodzajOkienkaStudioLOGO)

    const handleClosePopup = () => {
        setIsPopupVisable({ ...isPopupVisable, isPopupVisableWhenChangingOkienkoTypeLOGO: false });
        setShowTekstWhenChangedRozmiar(false)
    };

    const handleUpload = () => {
        const options = {
            accept: 'image/png',
            maxSize: 1500 * 300,
            onUploadDone: (res) => {
                const uploadedFileURL = res.filesUploaded[0].url;
                console.log('File Uploaded:', uploadedFileURL);
    
                const img = new Image();
                img.src = uploadedFileURL;
                
                img.onload = function() {
                    const [maxWidth, maxHeight] = selectedPhotoSize.maxSize.split('x').map(Number);
                    const [minWidth, minHeight] = selectedPhotoSize.minSize.split('x').map(Number);

                    if (
                        (this.naturalWidth <= maxWidth && this.naturalWidth >= minWidth) &&
                        (this.naturalHeight <= maxHeight && this.naturalHeight >= minHeight)
                    ) {
                        setUrlLOGO(uploadedFileURL);
                    } else {
                        setIsPopupVisable(prevState => ({...prevState, isPopupVisableWhenChangingOkienkoTypeLOGO: true}));
                    }
                };
            }, 
            transformations: {
                crop: false,
                circle: false,
                rotate: false
            }
        };
        client.picker(options).open();
    };
        
    return (
        <div>
            {isPopupVisable.isPopupVisableWhenChangingOkienkoTypeLOGO && isStuidoLOGO && <Popup isVisible={isPopupVisable.isPopupVisableWhenChangingOkienkoTypeLOGO} text= { showTekstWhenChangedRozmiar ? `Wybrany rozmiar matrycy różni się od rozmiaru dodanego pliku. Dla wybranego rozmiaru matrycy odpowiedni rozmiar pliku to: ${sizesPictureToUpload[rodzajOkienkaStudioLOGO].maxSize}px` : ` Dodany plik nie odpowiada rozmiarom wybranej matrycy. Wybierz plik o rozmiarze: ${sizesPictureToUpload[rodzajOkienkaStudioLOGO].maxSize}px`} onClose={handleClosePopup} />}
            
            
            <button className = {style.buttonUploadLogo} type="button" onClick={handleUpload}>ZAŁADUJ PLIK</button>
            {hasErrors && isSaveAttempted && urlLOGO === '' && <p style={{ color: 'red', marginTop: '7px' }}>Musisz wybrać zdjęcie</p>}

        </div>
    );
};

export default UploaderLOGO;


