import React,{useContext} from 'react';
import UploaderLOGO from '../../uploader/UploaderLOGO';
import {  MainStateModal } from '../../../context/Contex';
import { positionsStudioLOGO } from '../../../constants/dataStudioLogo/dataStudioLogo';
import { rozmiarMatrycy } from '../../../constants/dataStudioLogo/dataStudioLogo';
import style from './StudioLogo.module.css'
import { sizesPictureToUpload } from '../../../constants/dataStudioLogo/dataStudioLogo';

const StudioLOGO = () => {

    const {positionLOGO,hasErrors, isSaveAttempted, rodzajOkienkaStudioLOGO, setDataStudioLogo} = useContext(MainStateModal)
    return (
        <div className={style.containerWithAllLOGO}>
            
            <div className={style.containerWithPositionLogo}>
                <p style = {{fontWeight: '300'}}> Położenie:</p>
                <select className = {style.selectPositionLOGO} value={positionLOGO ? positionLOGO : 'Wybierz położenie'} onChange={(e) => setDataStudioLogo((prevState) => ({...prevState, positionLOGO: e.target.value}))}>
                    <option disabled value={'Wybierz położenie'}>Wybierz położenie</option> 
                        {positionsStudioLOGO.map((el) => (
                            <option key= {el}>{el}</option>
                        ))}
                </select>

                {hasErrors && isSaveAttempted && !positionLOGO && <p style={{ color: 'red' }}>Musisz wybrać lokalizację</p>}
           </div>
           
            <div className={style.containerWithRozmiarLOGO}>           
                {hasErrors && isSaveAttempted && !rodzajOkienkaStudioLOGO && <p style={{ color: 'red' }}>Musisz wybrać rozmiar</p>}
                <p style = {{fontWeight: '300'}}>Rozmiar:</p>
                <select className = {style.selectPositionLOGO}value = {rodzajOkienkaStudioLOGO ? rodzajOkienkaStudioLOGO : '--Wybierz rozmiar Matrycy--'} onChange={(e) => setDataStudioLogo(prevState => ({ ...prevState,rodzajOkienkaStudioLOGO: e.target.value})) }>
                    <option disabled value = {'--Wybierz rozmiar Matrycy--'}>--Wybierz rozmiar Matrycy--</option> 
                         {rozmiarMatrycy.map((el,idx) => <option key = {idx}>{el}</option>)}
                </select>
            </div>
            <div style={{marginTop: '16px'}}>
                <span>Prześlij plik w rozmiarze: {sizesPictureToUpload[rodzajOkienkaStudioLOGO].maxSize}px</span>
            </div>
            <div className={style.containerWithZaladujPlik}>
                {rodzajOkienkaStudioLOGO && positionLOGO &&  <UploaderLOGO/>}
            </div>
        </div>
           
    );
};

export default StudioLOGO;