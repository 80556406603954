import zRamkaCameo from '../../assets/zRamkaCameo.png'
import bezRamkiCameo from '../../assets/bezRamkiCameo.png'
import kwadratCameo from '../../assets/kwadratCameo.png'
import prostokatCameoPoziomy from '../../assets/prostokatCameoPoziomy.png'
import prostokatCameoPionowy from '../../assets/prostokatCameoPionowy.png'


export const positionsCameo = ['Kwadrat', 'Prostokąt poziomy', 'Prostokąt pionowy']

export const positionsCameoOkienko = [
    { label: 'Kwadrat', src: kwadratCameo },
    { label: 'Prostokąt poziomy', src: prostokatCameoPoziomy },
    { label: 'Prostokąt pionowy', src: prostokatCameoPionowy }
];

export const opcjaOkienka = ['Z ramką', 'Bez ramki']

export const opcjaOkienkaOptions = [
    { src: zRamkaCameo, label: 'Z ramką' },
    { src: bezRamkiCameo, label: 'Bez ramki' }
];

