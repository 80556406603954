import React from 'react';
import style from './Photopanel.module.css'
import UploaderPhotopanel from '../../uploader/UploaderPhotopanel';

const Photopanel = () => {
    return (
        <div className={style.containerWithButtonPhotopanelUploader}>
            <UploaderPhotopanel/>
        </div>
    );
};

export default Photopanel;