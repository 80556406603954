import React, { useContext, useEffect, useRef, useState} from 'react';
import style from './Okladka.module.css'
import { AppContext, MainStateModal } from '../../../../context/Contex';
import { colorsOkladkaWizualizator, positionClassesWizualizator, baseFontSizesFor1000px } from '../../../../constants/dataTloczenie/dataTloczenieOkladka';

const Okladka = ({containerOkladkaRef}) => {
    
    const {albumSize} = useContext(AppContext)
    const {formData, lokalizacja} = useContext(MainStateModal)
    const refContainerOkladka = useRef(null)
    
    const [odstepTexstOkladka, setOdstepTexstOkladka] = useState(0);
    const[szerokoscKontainerOKladka, setSzerokoscKontainerOkladka] = useState({width : ''})
    
    const [okladkaRozmiarCzcionki1, setOkladkaRozmiarCzcionki1] = useState(null);
    const [okladkaRozmiarCzcionki2, setOkladkaRozmiarCzcionki2] = useState(null);

    useEffect(() => {
        const updateContainerWidth = () => {
            if (refContainerOkladka.current) {
                const albumSizeInPX = albumSize.size_width * 2.50;
                const containerWidth = refContainerOkladka.current.offsetWidth;
                const oneCM = containerWidth / (albumSizeInPX + 1);
    
                let paddingFactorOkladka;
                switch (albumSizeInPX) {
                    case 30: paddingFactorOkladka = 3.5; break;
                    case 25: paddingFactorOkladka = 3; break;
                    case 20: paddingFactorOkladka = 2; break;
                    case 15: paddingFactorOkladka = 1; break;
                    case 10: paddingFactorOkladka = 1; break;
                    default: paddingFactorOkladka = 1;
                }
    
                const calculatedPaddingOkladka = oneCM * paddingFactorOkladka;
       
                setOdstepTexstOkladka(calculatedPaddingOkladka)

                const odstepOdGlownegoKontainera = 2;
                const calculatedOdstępContaineraContent = oneCM * odstepOdGlownegoKontainera
                const szerokoscKontaineraZContentem = containerWidth - calculatedOdstępContaineraContent
                setSzerokoscKontainerOkladka({
                    width: szerokoscKontaineraZContentem
                })
            }
        }
    
        updateContainerWidth();
    
        const resizeObserver = new ResizeObserver(updateContainerWidth);
        resizeObserver.observe(refContainerOkladka.current);
    
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [albumSize, refContainerOkladka, refContainerOkladka.current?.offsetWidth]);


    // rozmiar czcionki / 1000px 
    useEffect(() => {
        const updateFontSizes = () => {
            const windowWidth = containerOkladkaRef.current.offsetWidth;
            const albumKey = `${albumSize.size_height * 2.5}x${albumSize.size_width * 2.5}`;
            const scaleFactor = windowWidth / 1000;
    
            if (baseFontSizesFor1000px[albumKey]) {
                setOkladkaRozmiarCzcionki1(baseFontSizesFor1000px[albumKey][formData.selectedFont1] * scaleFactor);
                setOkladkaRozmiarCzcionki2(baseFontSizesFor1000px[albumKey][formData.selectedFont2] * scaleFactor);
            }
        };
    
        const currentRef = containerOkladkaRef.current; 
    
        const resizeObserver = new ResizeObserver(updateFontSizes);
        if (currentRef) {
            resizeObserver.observe(currentRef);
        }
    
        window.addEventListener('resize', updateFontSizes);
    
        return () => {
            if (currentRef) {
                resizeObserver.unobserve(currentRef); 
            }
            window.removeEventListener('resize', updateFontSizes);
        };
    }, [albumSize, formData.selectedFont1, formData.selectedFont2, containerOkladkaRef]);




    const colorClass = colorsOkladkaWizualizator[formData.selectedColor] || '';
    const positionClassOkladka = positionClassesWizualizator[lokalizacja] || 'center_okladka';

    return (
        <div className={style.container_okładka} ref={refContainerOkladka} >
            <div className={style.tloczenieContentContainer} style={{width: `${szerokoscKontainerOKladka.width}px`}}>
                <div className={style[positionClassOkladka]} style = {{marginBottom: odstepTexstOkladka, marginRight: odstepTexstOkladka}}>
                    <div className={`${style[colorClass]}`} style = {{whiteSpace: 'nowrap',}}>
                        <p style={{ margin: '0px', fontSize: okladkaRozmiarCzcionki1, fontFamily: 'futura_ptbook'  }}>{formData.line1}</p>
                    </div>
                    
                    <div className={`${style[colorClass]} `} style = {{whiteSpace: 'nowrap',}}>
                        <p style={{ margin: '0px', fontSize: okladkaRozmiarCzcionki2, fontFamily: 'futura_ptbook'  }}>{formData.line2}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Okladka;





