import React, { useContext } from 'react';
import { AppContext, MainStateModal } from '../../../context/Contex';
import { rozmiarMatrycy,rozmiaryMatrycy10x10 } from '../../../constants/dataMatryca/dataMatryca';
import style from './Matryca.module.css'
import { imageOptionsMatryca } from '../../../constants/dataMatryca/dataMatryca';
import { overprintFonts } from '../../../constants/dataOverprint/dataOverprint';
import { ColorImagesTloczenie } from '../../../constants/data';

const Matryca = () => {

    const defaultIncrementValue = 20;
    const minValue = 20;
    const maxValue = 100;
    
    const { lineHeightMatryca, setLineHeightMatryca, rozmiarCzcionkiLine1,rozmiarCzcionkiLine2, rozmiarCzcionkiLine3, formData, setFormData,hasErrors, isSaveAttempted, selectedRozmiar, activeTab, disabledColors } = useContext(MainStateModal);
    const{albumSize} = useContext(AppContext)
    const {lineMatryca1,lineMatryca2, lineMatryca3,  lokalizacjaMatryca, selectedColorMatryca, matrycaFontLine1, matrycaFontLine2, matrycaFontLine3} = formData

    const handleIncrementDecrement = (inputName, increment) => {
        setFormData(prev => {
            let value = prev[inputName] || defaultIncrementValue;
            value = increment ? Math.min(value + 1, maxValue) : Math.max(value - 1, minValue);
            return {...prev, [inputName]: value};
        });
    };
    
    const handleSizeChangeBlur = (inputName, e, isBlur) => {
        let newValue = parseInt(e.target.value, 10);
        if (isNaN(newValue)) {
            newValue = '';
        } else if(isBlur){
            if (newValue < minValue) {
                newValue = minValue;
            } else if (newValue > maxValue) {
                newValue = maxValue;
            }
        }
    
        setFormData(prev => ({ ...prev, [inputName]: newValue }));
    };

    //line height change
    const handleLineHeightChangeMatryca = (e) => {
        setLineHeightMatryca(e.target.value);
    }


    return (
        <div className={style.containerWithMatryca}>
            <div>
                <p style={{fontWeight: '300'}}>Rozmiar:</p>  
                <div className={style.containerZRozmiaremMatrycy}>
                    <select className={style.selectZRozmiaremMatrycy} value={selectedRozmiar ? selectedRozmiar : '--Wybierz rozmiar Matrycy--'} onChange={(e) => setFormData((prev) => ({...prev, selectedRozmiar: e.target.value}))}>
                        <option style = {{backgroundColor: '#ffe1c2'}} disabled value = {'--Wybierz rozmiar Matrycy--'}>--Wybierz rozmiar Matrycy--</option> 
                        {((albumSize.size_height * 2.5) === 10 ? rozmiaryMatrycy10x10 : rozmiarMatrycy).map((el, idx) => 
                            <option style = {{backgroundColor: '#ffe1c2'}} key={idx} disabled={activeTab.key.startsWith('isCameoAndMatryca') && el === '5x5'}>
                                {el}
                            </option>
                        )}
                    </select>
                </div>         
                {/* {hasErrors && isSaveAttempted && !selectedRozmiar && <p style={{ color: 'red' }}>Musisz wybrać rozmiar</p>} */}
            </div>
           
            <div className={style.matrycaContainerWithLines}>
                <div style = {{display:'flex'}}>
                    
                    <div className = {style.containerWithTekstInfoMatryca}>
                        <div>
                            <p style={{fontWeight: '300'}}>Tekst Linia 1:</p>  
                        </div>
                        <div>
                            <input
                                className={style.inputMatrucaLine}
                                placeholder='Wpisz tekst...'
                                maxLength={40}
                                type='text'
                                value={lineMatryca1}
                                onChange={(e) => setFormData((prev) => ({ ...prev, lineMatryca1: e.target.value }))}    
                            />
                        </div>
                        {hasErrors && isSaveAttempted && lineMatryca1.length <= 0 && <p style={{ color: 'red', marginTop: '7px', marginBottom: '0px' }}>Musisz napisać tekst</p>}
                    </div>          
                  
                    <div className = {style.containerWithInputsSizeMatryca}>
                        <p style = {{fontWeight: '300'}}>Rozmiar czcionki:</p>
                        <div className={style.containerWithRozmiaCzcionkiElementsMatryca}>
                             <div className={style.blockWithMinusMatryca}>
                                <span onClick={() => handleIncrementDecrement('rozmiarCzcionkiLine1', false)}>-</span>
                            </div>
                            <div className={style.containerWithInputCzcionkaRozmiarMatryca}>
                                <input
                                    style = {{width: '70%',  border: 'none',  textAlign: 'center', padding: '0', color: 'rgb(152, 152, 152)', outline: 'none'}}
                                    type = 'number'
                                    value={rozmiarCzcionkiLine1}
                                    onChange={(e) => handleSizeChangeBlur('rozmiarCzcionkiLine1', e, false)}
                                    onBlur={(e) => handleSizeChangeBlur('rozmiarCzcionkiLine1', e, true)}   
                                />
                            </div>
                            <div className={style.containerWithPlusMatryca}>
                                <span  onClick={() => handleIncrementDecrement('rozmiarCzcionkiLine1', true)}>+</span>
                            </div>
                        </div>
                    
                    </div>   

                    <div className = {style.containerWithFontsMatryca}>
                        <div>
                            <p style={{fontWeight: '300'}}>Wybierz czcionkę:</p>
                        </div>
                        <select className= {style.selectRozmiarCzcionkiMatryca} value={matrycaFontLine1 ? matrycaFontLine1 : 'Wybierz czcionkę'} onChange={(e) => setFormData((prev) => ({...prev, matrycaFontLine1: e.target.value}))}>
                            <option disabled value={'Wybierz czcionkę'} >Wybierz czcionkę</option>
                            {overprintFonts.map((font) => (
                                <option key = {font}>{font}</option>
                            ))}
                        </select>

                    </div>         
                </div>               


                <div style = {{display:'flex'}}>
                    
                    <div className = {style.containerWithTekstInfoMatryca}>
                        <div>
                            <p style={{fontWeight: '300'}}>Tekst Linia 2:</p>  
                        </div>
                        <div>
                            <input
                                className={style.inputMatrucaLine}
                                placeholder='Wpisz tekst...'
                                maxLength={40}
                                type='text'
                                value={lineMatryca2}
                                onChange={(e) => setFormData((prev) => ({ ...prev, lineMatryca2: e.target.value }))}    
                            />
                        </div>
                        {/* {hasErrors && isSaveAttempted && lineMatryca2.length <= 0 && <p style={{ color: 'red', marginTop: '7px', marginBottom: '0px' }}>Musisz napisać tekst</p>} */}
                    </div>          
                

                    <div className={style.containerWithInputsSizeMatryca}>
                        <p style = {{fontWeight: '300'}}>Rozmiar czcionki:</p>

                        <div className={style.containerWithRozmiaCzcionkiElementsMatryca}>
                            <div className={style.blockWithMinusMatryca}>
                                <span  onClick={() => handleIncrementDecrement('rozmiarCzcionkiLine2', false)}>-</span>
                            </div>
                            <div className={style.containerWithInputCzcionkaRozmiarMatryca}>
                                <input
                                    style = {{width: '70%',  border: 'none',  textAlign: 'center', padding: '0', color: 'rgb(152, 152, 152)', outline: 'none'}}
                                    type = 'number'
                                    value={rozmiarCzcionkiLine2}
                                    onChange={(e) => handleSizeChangeBlur('rozmiarCzcionkiLine2', e, false)}
                                    onBlur={(e) => handleSizeChangeBlur('rozmiarCzcionkiLine2', e, true)}   
                                />
                            </div>
                           
                            <div className={style.containerWithPlusMatryca}>
                                <span  onClick={() => handleIncrementDecrement('rozmiarCzcionkiLine2', true)}>+</span>
                           </div>
                        </div>
                    
                    </div>   

                    <div className = {style.containerWithFontsMatryca}>
                        <div>
                            <p style={{fontWeight: '300'}}>Wybierz czcionkę:</p>
                        </div>
                        <select className= {style.selectRozmiarCzcionkiMatryca} value={matrycaFontLine2 ? matrycaFontLine2 : 'Wybierz czcionkę'} onChange={(e) => setFormData((prev) => ({...prev, matrycaFontLine2: e.target.value}))}>
                            <option disabled value={'Wybierz czcionkę'} >Wybierz czcionkę</option>
                            {overprintFonts.map((font) => (
                                <option key = {font}>{font}</option>
                            ))}
                        </select>
                            
                    </div>         
                </div>                   

                <div style = {{display:'flex'}}>
                    
                    <div className = {style.containerWithTekstInfoMatryca}>
                        <div>
                            <p style={{fontWeight: '300'}}>Tekst Linia 3:</p>  
                        </div>
                        <div>
                            <input
                                className={style.inputMatrucaLine}
                                placeholder='Wpisz tekst...'
                                maxLength={40}
                                type='text'
                                value={lineMatryca3}
                                onChange={(e) => setFormData((prev) => ({ ...prev, lineMatryca3: e.target.value }))}    
                            />
                        </div>
                        {/* {hasErrors && isSaveAttempted && lineMatryca3.length <= 0 && <p style={{ color: 'red', marginTop: '7px', marginBottom: '0px' }}>Musisz napisać tekst</p>} */}
                    </div>          
                

                    <div className={style.containerWithInputsSizeMatryca}>
                        <p style = {{fontWeight: '300'}}>Rozmiar czcionki:</p>
                        <div className={style.containerWithRozmiaCzcionkiElementsMatryca}>
                            <div className={style.blockWithMinusMatryca}>
                                <span  onClick={() => handleIncrementDecrement('rozmiarCzcionkiLine3', false)}>-</span>
                            </div>
                            <div className={style.containerWithInputCzcionkaRozmiarMatryca}>
                                <input
                                    style = {{width: '70%',  border: 'none',  textAlign: 'center', padding: '0', color: 'rgb(152, 152, 152)', outline: 'none'}}
                                    type = 'number'
                                    value={rozmiarCzcionkiLine3}
                                    onChange={(e) => handleSizeChangeBlur('rozmiarCzcionkiLine3', e, false)}
                                    onBlur={(e) => handleSizeChangeBlur('rozmiarCzcionkiLine3', e, true)}   
                                />
                            </div>
                            
                            <div className={style.containerWithPlusMatryca}>
                                <span  onClick={() => handleIncrementDecrement('rozmiarCzcionkiLine3', true)}>+</span>
                            </div>
                        </div>
                    
                    </div>   

                    <div className = {style.containerWithFontsMatryca}>
                        <div>
                            <p style={{fontWeight: '300'}}>Wybierz czcionkę:</p>
                        </div>
                        <select className= {style.selectRozmiarCzcionkiMatryca} value={matrycaFontLine3 ? matrycaFontLine3 : 'Wybierz czcionkę'} onChange={(e) => setFormData((prev) => ({...prev, matrycaFontLine3: e.target.value}))}>
                            <option disabled value={'Wybierz czcionkę'} >Wybierz czcionkę</option>
                            {overprintFonts.map((font) => (
                                <option key = {font}>{font}</option>
                            ))}
                        </select>
                            
                    </div>         
                </div>     

            </div>
                                
            <div className={style.mainContainerWithLineHeightAndCzcionka}>
                    <p style={{fontWeight: '300'}}>Wybierz wysokość odstępu tekstu:</p>
                    <div style = {{display: 'flex', alignItems: 'center'}}>
                        <div>
                            <input
                                type="range"
                                min="0.5"
                                max="3"
                                step="0.1"
                                value={lineHeightMatryca}
                                onChange={handleLineHeightChangeMatryca}
                            />
                        </div>
                    </div>
            </div>

            <div style = {{marginTop: '16px'}}>
                <p style={{fontWeight: '300', marginTop: '0px'}}>Kolor tłoczenia:</p>
                <div className={style.colorContainerMatrcya}>
                    {ColorImagesTloczenie.map((colorItem, idx) => (
                            <div 
                                key={idx} 
                                className={`${style.colorBlockMatryca} ${disabledColors.includes(colorItem.color) ? style.disabled : ''}`} 
                                onClick={() => !disabledColors.includes(colorItem.color) && setFormData((prevState) => ({...prevState, selectedColorMatryca: colorItem.color }))}
                            >
                                <div className={style.blockWithPhotosColor}>
                                    <img 
                                        className={`${style.colorImageMatryca} ${selectedColorMatryca === colorItem.color ? style.selected : style.notSelected}`}
                                        src={colorItem.image} 
                                        alt={colorItem.color} 
                                    />
                                    <label style={{ color: 'black' , fontSize: '12px'}}>{colorItem.color}</label>
                                </div>
                            </div>
                        ))}
                </div>
                {hasErrors && isSaveAttempted && !selectedColorMatryca && <p style={{ color: 'red', marginTop: '7px' }}>Musisz wybrać Color</p>}

            </div>

            <div>
                {!activeTab.key.startsWith('isCameoAndMatryca') &&
                <>
                    <p style={{fontWeight: '300'}}>Położenie:</p>

                    <div className={style.imageOptionsLokalizacja}>
                    {imageOptionsMatryca.map((option, idx) => (
                                <div 
                                    key={idx} 
                                    className={style.imageOptionLokalizacja} 
                                    onClick={() => setFormData(prev => ({...prev, lokalizacjaMatryca: option.label}))}
                                >
                                    <div className={style.containerWithOneElemtLokalizacji}>
                                        <img 
                                            src={option.img} 
                                            alt={option.label} 
                                            className={`${style.imgElementLokalizacji} ${lokalizacjaMatryca === option.label ? style.selected : style.notSelected}`}

                                        />
                                        <span style={{marginTop: '4px', fontSize: '12px'}}>{option.label}</span>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </>
                }
                {hasErrors && isSaveAttempted && !lokalizacjaMatryca && <p style={{ color: 'red' }}>Musisz wybrać lokalizację</p>}

            </div>
            
        </div>
    );
};

export default Matryca;
