import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext, MainStateModal } from '../../../../context/Contex';
import {  windowDetails } from '../../../../constants/data'
import style from './Cameo.module.css'

const CameoWizualizator = () => {

    const {albumSize} = useContext(AppContext)
    const {selectedtypeCameoOkienko, urlCameo, selectedOpcjaOkienka,windowStyle, setWindowStyle} = useContext(MainStateModal)

    const resizeObserver = useRef(null);
    const containerRefCameo = useRef(null)
    const [imageStyleZRamka, setImageStyleZRamka] = useState('')
    const [paddingHalfCm, setPaddingHalfCm] = useState(0);
    const[szerokoscKontainerCameo, setSzerokoscKontainerCameo] = useState({width : ''})

    const calculateDetails = (windowDetails, albumSizeKey, windowType, oneCM, marginModification = 0) => {
        if (!windowDetails[albumSizeKey] || !windowDetails[albumSizeKey][windowType]) {
          return {};
        }
    
        const { dimensions, margins } = windowDetails[albumSizeKey][windowType];
        return {
          dimensions: {
            width: dimensions.width * oneCM,
            height: dimensions.height * oneCM,
          },
          margins: {
            top: (margins.top + marginModification) * oneCM,
            left: (margins.left + marginModification) * oneCM,
          },
        };
    }
    
    const getDimensionsAndMargins = (windowType, albumSize, containerWidth, frameOption) => {
        const albumSizeInPX = albumSize.size_width * 2.50;  
        const albumSizeKey = `${albumSizeInPX}x${albumSizeInPX}`;
        const oneCM = containerWidth / (albumSizeInPX + 1);
        const halfCm = oneCM / 2;
        let frameModification = 0;
        let marginModification = 0;

        let details = calculateDetails(windowDetails, albumSizeKey, windowType, oneCM);
    
        if (frameOption === 'Bez ramki') {
            frameModification = 0; 
    
            details = calculateDetails(windowDetails, albumSizeKey, windowType, oneCM + frameModification, marginModification);
        }
    
        if (frameOption === 'Z ramką') {
            frameModification = -1; 
    
            const rozmiarOkienkaImg = {
                width: details.dimensions.width - halfCm,
                height: details.dimensions.height - halfCm,
            };
    
            const marginsImg = {
                top: halfCm / 2,
                left: halfCm / 2,
            };
    
            return { details, rozmiarOkienkaImg, marginsImg, halfCm };
        }
    
        return { details, halfCm, oneCM };
    };
    
    useEffect(() => {
        if(!containerRefCameo.current) return

        const updateDimensions = () => {

            if(!containerRefCameo.current) return
            
            const containerWidth = containerRefCameo.current.offsetWidth;
            const { details, rozmiarOkienkaImg, marginsImg, halfCm, oneCM  } = getDimensionsAndMargins(selectedtypeCameoOkienko, albumSize, containerWidth, selectedOpcjaOkienka);
            
            const odstepOdGlownegoKontainera = 2;
            const calculatedOdstępContaineraContent = oneCM * odstepOdGlownegoKontainera
            const szerokoscKontaineraZContentem = containerWidth - calculatedOdstępContaineraContent
            setSzerokoscKontainerCameo({
                width: szerokoscKontaineraZContentem
            })

            //okienko rozmiar
            setWindowStyle({
                width: `${details.dimensions.width}px`,
                height: `${details.dimensions.height}px`,
                marginTop: `${details.margins.top}px`,
                marginLeft: `${details.margins.left}px`,
            });  

            if (rozmiarOkienkaImg && marginsImg) {
                setImageStyleZRamka({
                    width: `${rozmiarOkienkaImg.width}px`,
                    height: `${rozmiarOkienkaImg.height}px`,
                    marginTop: `${marginsImg.top}px`,
                    marginLeft: `${marginsImg.left}px`,
                });
            } else {
                setImageStyleZRamka({
                    width: `calc(100% + ${2 * halfCm}px)`,
                    height: `calc(100% + ${2 * halfCm}px)`,
                    position: 'absolute',
                    top: `-${halfCm}px`,
                    left: `-${halfCm}px`,
                    
                });
            }
            setPaddingHalfCm(halfCm);
        }
        updateDimensions();

        
        if ('ResizeObserver' in window) {
            resizeObserver.current = new ResizeObserver(updateDimensions);
            resizeObserver.current.observe(containerRefCameo.current);
        }

        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }
        };

    },[selectedtypeCameoOkienko, albumSize, containerRefCameo, selectedOpcjaOkienka])
    
    return (
        <div className={style.cameo_container} ref={containerRefCameo}>
            <div style={{width: `${szerokoscKontainerCameo.width}px`}}>
                <div className={style.okienko_cameo} style={{...windowStyle,  backgroundColor: selectedtypeCameoOkienko ? 'white' : '', overflow: selectedtypeCameoOkienko ? 'hidden' : ''}}>
                    {urlCameo ? 
                        <img className={style.img_Cameo} src={urlCameo} alt="Uploaded" style={imageStyleZRamka}/>
                        : selectedtypeCameoOkienko ? <div style={{backgroundColor: 'white', width: '100%', height: '100%'}} /> : null
                    }
                </div>
            </div>
        </div>
    );
};

export default CameoWizualizator;



