import React, { useContext, useEffect, useState, useRef, useLayoutEffect } from 'react';
import style from './StudioLOGO.module.css'
import { AppContext, MainStateModal } from '../../../../context/Contex';
import { matrixSizes} from '../../../../constants/dataMatryca/dataMatryca';

const StudioLogo = () => {

    const {albumSize} = useContext(AppContext)
    const {positionLOGO, urlLOGO, rodzajOkienkaStudioLOGO, selectedSizeInPxSTUDIOLOGO, setDataStudioLogo} = useContext(MainStateModal)

    const containerRefLOGO = useRef()
    const [odstepDolText, setOdstepText] = useState(0)

    const[szerokoscKontainerLOGO, setSzerokoscKontainerLOGO] = useState({width : ''})
    

    useEffect(() => {
        const updateDimensions = () => {
            if(!containerRefLOGO.current) return;
    
            const containerWidth = containerRefLOGO.current.offsetWidth;
            const albumSizeInCM = albumSize.size_width * 2.50;
            const oneCM = containerWidth / (albumSizeInCM + 1);
    
            let odstepDol = oneCM * 2
            let selectedSize;
            
            selectedSize = matrixSizes[rodzajOkienkaStudioLOGO]
            
            if (selectedSize) {
                setDataStudioLogo(prevState => ({
                    ...prevState,
                    selectedSizeInPxSTUDIOLOGO: {
                        width: selectedSize.width * oneCM,
                        height: selectedSize.height * oneCM,
                    },
                }));
            }
            setOdstepText(odstepDol)
    
            const odstepOdGlownegoKontainera = 2;
            const calculatedOdstępContaineraContent = oneCM * odstepOdGlownegoKontainera
            const szerokoscKontaineraZContentem = containerWidth - calculatedOdstępContaineraContent
    
            setSzerokoscKontainerLOGO({
                width: szerokoscKontaineraZContentem
            })
        };
        updateDimensions();

        const resizeObserver = new ResizeObserver(updateDimensions);
        resizeObserver.observe(containerRefLOGO.current);
    
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [albumSize,containerRefLOGO, rodzajOkienkaStudioLOGO])
   

    const styles = {
        width: rodzajOkienkaStudioLOGO ? `${selectedSizeInPxSTUDIOLOGO.width}px` : 'auto',
        height: rodzajOkienkaStudioLOGO ? `${selectedSizeInPxSTUDIOLOGO.height}px` : 'auto',
        border: rodzajOkienkaStudioLOGO ? '1px solid orange' : '',
    }

    return (
        <div className={style.container_style_logo} ref = {containerRefLOGO}>
            <div className={style.tloczenieContentContainer} style={{width: positionLOGO === 'Endpapers' ? '' : `${szerokoscKontainerLOGO.width}px`}}> 
                <div style={{...styles, marginBottom: `${odstepDolText}px`}} className= {style.containerWithIMG} >
                    {urlLOGO ? 
                        <img className = {style.img_center_LOGO} src={urlLOGO} alt="Uploaded"/>
                        :
                        <div className= {positionLOGO === 'Endpapers' ? style.img_center_LOGO_black : style.img_center_LOGO_white} />
                    }
                </div>
            </div>
        </div>
    );
};

export default StudioLogo;

