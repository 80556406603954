import React, { useContext, useState } from 'react';
import { overprintFonts } from '../../../constants/dataOverprint/dataOverprint';
import { MainStateModal } from '../../../context/Contex';
import style from './OverprintFunkcjonalnosc.module.css'
import { imageOptionsLokalizacjaOverprint, ColorImagesOverprint } from '../../../constants/dataOverprint/dataOverprint';


const Overprint = () => {

    const defaultIncrementValue = 20;
    const minValue = 20;
    const maxValue = 100;


    const {overprintLokalizacja, activeTab, lineHeightOverprint, setLineHeightOverprint, selectedColorOverprint, formData, setFormData, setSizePixels,hasErrors, isSaveAttempted, sizePixels} = useContext(MainStateModal);
    
    const [tempValues, setTempValues] = useState({
        sizePixelLine1: sizePixels.sizePixelLine1,
        sizePixelLine2: sizePixels.sizePixelLine2,
        sizePixelLine3: sizePixels.sizePixelLine3,
    });

    const handleIncrementDecrement = (inputName, increment) => {
        setSizePixels(prev => {
            let value = prev[inputName] || defaultIncrementValue;
            value = increment ? Math.min(value + 1, maxValue) : Math.max(value - 1, minValue);
            return {...prev, [inputName]: value};
        });
        setTempValues(prev => {
            let value = prev[inputName] || defaultIncrementValue;
            value = increment ? Math.min(value + 1, maxValue) : Math.max(value - 1, minValue);
            return {...prev, [inputName]: value};
        });
    };

    const handleSizeChangeBlur = (inputName, e, isBlur) => {
        let newValue = parseInt(e.target.value, 10);
        if (isNaN(newValue)) {
            newValue = '';
        } else if(isBlur){
            if (newValue < minValue) {
                newValue = minValue;
            } else if (newValue > maxValue) {
                newValue = maxValue;
            }
        }
        setSizePixels(prev => ({ ...prev, [inputName]: newValue }));
        setTempValues(prev => ({ ...prev, [inputName]: newValue }));
    };
    
    //line height change
    const handleLineHeightChange = (e) => {
        setLineHeightOverprint(e.target.value);
    }

    return (
        <div>
            <div className={style.mainContainerWithTekstLines}>
                
                <div style = {{display:'flex'}}>

                    <div className = {style.containerWithTekstInfo}>
                        <div>
                            <p style = {{fontWeight: '300'}}>Tekst Linia 1:</p>
                        </div>
                        <div>
                            <input placeholder = 'Wpisz tekst...' className = {style.inputLine1} type = 'text' maxLength={50} value={formData.lineOverprint1} onChange={(e) => setFormData((prev) => ({...prev, lineOverprint1: e.target.value }))}/>
                        </div>
                        {hasErrors && isSaveAttempted && formData.lineOverprint1.length <= 0 && <p style={{ color: 'red', margin: '0px' }}>Musisz wpisać tekst do Linii!</p>}
                    </div>


                    <div className = {style.containerWithInputSize} >
                        <p style = {{fontWeight: '300'}}>Rozmiar czcionki:</p>

                        <div className = {style.containerWithRozmiaCzcionkiElements}>
                            <div className={style.blockWithMinus}>
                                <span onClick={() => handleIncrementDecrement('sizePixelLine1', false)}>-</span>
                            </div>

                            <div className={style.containerWithInputCzcionkaRozmiar}>
                                <input
                                    style = {{width: '70%',  border: 'none',  textAlign: 'center', padding: '0', color: 'rgb(152, 152, 152)', outline: 'none'}}
                                    type = 'number'
                                    value={tempValues.sizePixelLine1}
                                    onChange={(e) => handleSizeChangeBlur('sizePixelLine1', e, false)}
                                    onBlur={(e) => handleSizeChangeBlur('sizePixelLine1', e, true)}
                                />
                            </div>
                                
                            <div className={style.containerWithPlus}>
                                <span onClick={() => handleIncrementDecrement('sizePixelLine1', true)}>+</span>
                            </div>
                        </div>
                    </div>


                    <div className = {style.containerWithFonts}>
                        <div>
                            <p style={{fontWeight: '300'}}>Wybierz czcionkę:</p>
                        </div>
                        <select className= {style.selectRozmiarCzcionkiOverprint} value={formData.overprintFontLine1 ? formData.overprintFontLine1 : 'Wybierz czcionkę'} onChange={(e) => setFormData((prev) => ({...prev, overprintFontLine1: e.target.value}))}>
                            <option disabled value={'Wybierz czcionkę'} >Wybierz czcionkę</option>
                            {overprintFonts.map((font) => (
                                <option key = {font}>{font}</option>
                            ))}
                        </select>

                        {/* {hasErrors && isSaveAttempted && !formData.overprintFontLine1 && <p style={{ color: 'red', margin: '0px' }}>Musisz wybrać czcionkę!</p>} */}
                        
                    </div>
                        
                </div>

                <div style = {{display:'flex'}}>

                    <div className = {style.containerWithTekstInfo}>
                        <div>
                            <p style = {{fontWeight: '300'}}>Tekst Linia 2:</p>
                        </div>
                        <div>
                            <input placeholder = 'Wpisz tekst...' className = {style.inputLine1} type = 'text' maxLength={50} value={formData.lineOverprint2} onChange={(e) => setFormData((prev) => ({...prev, lineOverprint2: e.target.value }))}/>
                        </div>

                        {/* {hasErrors && isSaveAttempted && formData.lineOverprint2.length <= 0 && <p style={{ color: 'red', margin: '0px' }}>Musisz wpisać tekst do Linii!</p>} */}

                    </div>

                    <div className = {style.containerWithInputSize} >
                        <p style = {{fontWeight: '300'}}>Rozmiar czcionki:</p>
                        <div className = {style.containerWithRozmiaCzcionkiElements}>
                            <div className={style.blockWithMinus}>
                                <span onClick={() => handleIncrementDecrement('sizePixelLine2', false)}>-</span>
                            </div>
                            <div className={style.containerWithInputCzcionkaRozmiar}>
                                <input
                                    style = {{width: '70%',  border: 'none',  textAlign: 'center', padding: '0', color: 'rgb(152, 152, 152)', outline: 'none'}}
                                    type = 'number'
                                    value={tempValues.sizePixelLine2}
                                    onChange={(e) => handleSizeChangeBlur('sizePixelLine2', e, false)}
                                    onBlur={(e) => handleSizeChangeBlur('sizePixelLine2', e, true)}
                                />
                            </div>
                            
                            <div className={style.containerWithPlus}>
                                <span  onClick={() => handleIncrementDecrement('sizePixelLine2', true)}>+</span>
                            </div>
                        </div>
                    </div>


                    <div className = {style.containerWithFonts}>
                        <div>
                            <p style={{fontWeight: '300'}}>Wybierz czcionkę:</p>
                        </div>
                        <select className= {style.selectRozmiarCzcionkiOverprint} value={formData.overprintFontLine2 ? formData.overprintFontLine2 : 'Wybierz czcionkę'} onChange={(e) => setFormData((prev) => ({...prev, overprintFontLine2: e.target.value}))}>
                            <option disabled value={'Wybierz czcionkę'} >Wybierz czcionkę</option>
                            {overprintFonts.map((font) => (
                                    <option key = {font}>{font}</option>
                                ))}
                        </select>
                    </div>

                </div>


                <div style = {{display:'flex'}}>
                    <div className = {style.containerWithTekstInfo}>
                        <div>
                            <p style = {{fontWeight: '300'}}>Tekst Linia 3:</p>

                        </div>
                        <div>
                            <input placeholder = 'Wpisz tekst...' className = {style.inputLine1} type = 'text' maxLength={50} value={formData.lineOverprint3} onChange={(e) => setFormData((prev) => ({...prev, lineOverprint3: e.target.value }))}/>
                        </div>
                    </div>

                    <div className = {style.containerWithInputSize} >
                        <p style = {{fontWeight: '300'}}>Rozmiar czcionki:</p>

                        <div className = {style.containerWithRozmiaCzcionkiElements}>
                            <div className={style.blockWithMinus}>
                                <span onClick={() => handleIncrementDecrement('sizePixelLine3', false)}>-</span>
                            </div>
                            <div className={style.containerWithInputCzcionkaRozmiar}>
                                <input
                                    style = {{width: '70%',  border: 'none',  textAlign: 'center', padding: '0', color: 'rgb(152, 152, 152)', outline: 'none'}}
                                    type = 'number'
                                    value={tempValues.sizePixelLine3}
                                    onChange={(e) => handleSizeChangeBlur('sizePixelLine3', e, false)}
                                    onBlur={(e) => handleSizeChangeBlur('sizePixelLine3', e, true)}
                                />
                            </div>
                            
                            <div className={style.containerWithPlus}>
                                <span onClick={() => handleIncrementDecrement('sizePixelLine3', true)}>+</span>
                            </div>
                        </div>
                    </div>


                        <div className = {style.containerWithFonts}>
                            <div>
                                <p style={{fontWeight: '300'}}>Wybierz czcionkę:</p>
                            </div>
                            <select className= {style.selectRozmiarCzcionkiOverprint} value={formData.overprintFontLine3 ? formData.overprintFontLine3 : 'Wybierz czcionkę'} onChange={(e) => setFormData((prev) => ({...prev, overprintFontLine3: e.target.value}))}>
                                <option disabled value={'Wybierz czcionkę'} >Wybierz czcionkę</option>
                                {overprintFonts.map((font) => (
                                    <option key = {font}>{font}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                {/* {hasErrors && isSaveAttempted && formData.lineOverprint3.length <= 0 && <p style={{ color: 'red',margin: '0px' }}>Musisz wpisać tekst do Linii!</p>} */}
            </div>
            


            <div className={style.mainContainerWithLineHeightAndCzcionka}>
                    <p style={{fontWeight: '300'}}>Wybierz wysokość odstępu tekstu</p>
                    <div style = {{display: 'flex', alignItems: 'center'}}>
                        <div>
                            <input
                                type="range"
                                min="0.5"
                                max="3"
                                step="0.1"
                                value={lineHeightOverprint}
                                onChange={handleLineHeightChange}
                            />
                        </div>
                    </div>
            </div>

            <div>  
                <p style={{fontWeight: '300'}}>Kolor zdobienia:</p>
                <div className={style.colorsOverprint}>
                    {ColorImagesOverprint.map((color, idx) => (
                        <div 
                            key={idx} 
                            className={`${style.colorBlockOverprint}`} 
                            onClick={() => setFormData((prevState) => ({...prevState, selectedColorOverprint: color.color }))}>
                                <div className={style.blockWithPhotosColorOverprint}>
                                    <img 
                                        src={color.image} 
                                        alt={color.color} 
                                        className={`${style.colorImageOverprint} ${selectedColorOverprint === color.color ? style.selected : style.notSelected}`} 

                                    />
                                    <label style={{ color: 'black', fontSize: '12px' }} >{color.color}</label>
                                </div>
                        </div> 
                    ))}
                </div>
            {hasErrors && isSaveAttempted && !formData.selectedColorOverprint && <p style={{ color: 'red', marginTop: '5px' }}>Musisz wybrać color!</p>}
                
            </div>
            {!activeTab.key.startsWith('isCameoAndOverprint') &&
                <>
                    <p style={{fontWeight: '300'}}>Położenie:</p>

                    <div className={style.imageOptionsLokalizacjaOverprint}>
                    {imageOptionsLokalizacjaOverprint.map((option, idx) => (
                                <div 
                                    key={idx} 
                                    className={style.imageOptionLokalizacjaOverprint} 
                                    onClick={() => setFormData(prev => ({...prev, overprintLokalizacja: option.label}))}
                                >
                                    <div className={style.containerWithOneElemtLokalizacjiOverprint}>
                                        <img 
                                            src={option.img} 
                                            alt={option.label} 
                                            className={`${style.imgElementLokalizacjiOverprint} ${overprintLokalizacja === option.label ? style.selected : style.notSelected}`}

                                        />
                                        <span style={{marginTop: '4px', fontSize: '12px'}}>{option.label}</span>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </>
                }
                {hasErrors && isSaveAttempted && !overprintLokalizacja && <p style={{ color: 'red', marginTop: '5px'  }}>Musisz wybrać lokalizację!</p>}
        </div>
    );
};


export default Overprint;

