import React, { useContext } from 'react';
import { MainStateModal } from '../../../context/Contex';
import { ColorImagesTloczenie} from '../../../constants/data';
import { localizacjaImages } from '../../../constants/dataTloczenie/dataTloczenieOkladka';
import { maxSignsOklakda } from '../../../constants/dataTloczenie/dataTloczenieOkladka';
import { rozmiarCzcionkiOkladka } from '../../../constants/dataTloczenie/dataTloczenieOkladka';
import style from '../../localizacjaTloczenia/tloczenieOkladka/Tloczenie.module.css'
import dodatkowaOplataInformator from '../../../assets/dodatkowaOplataInformator.png'

const TloczenieOkladka = () => {

    const {dodatkowaOplataAlert, setDodatkowaOplataAlert, setExtraPaymentRequiredOkladka, activeTab, isSaveAttempted, hasErrors, albumSize, disabledColors, formData, setFormData, formDataInvalid, lokalizacja, setLokalizacja} = useContext(MainStateModal)
    const {
        line1,
        line2,
        selectedFont1,
        selectedFont2,
        selectedColor,
    } = formData
    
    const { 
        invalidCharacterLine1,
        invalidCharacterLine2,
    } = formDataInvalid


    const isFontSizeDisabled = (fontSize) => albumSize.size_height === '4' && albumSize.size_width === '4' && (fontSize ==='9mm' || fontSize === '6mm');

    const checkForExtraPayment = (font1, font2) => {
        const stanok1 = font1.split('-')[0];
        const stanok2 = font2.split('-')[0];
        
        return stanok1 !== stanok2;
    }

    const checkForDateOnlyChange = (prevFontId, newFontId) => {
        const prevFont = rozmiarCzcionkiOkladka.find(font => font.id === prevFontId);
        const newFont = rozmiarCzcionkiOkladka.find(font => font.id === newFontId);
      
        return prevFont && !prevFont.onlyDates && newFont.onlyDates;
    } 

    const handleFontSizeChange2 = (selectedFontKey, textLineKey, newFontId, inputText) => {
        const updatedFormData = { ...formData };
        updatedFormData[selectedFontKey] = newFontId;
        
        const extraPaymentRequired = selectedFontKey === 'selectedFont1'
            ? checkForExtraPayment(newFontId, formData.selectedFont2)
            : checkForExtraPayment(formData.selectedFont1, newFontId);
    
        const dateOnlyChange = checkForDateOnlyChange(formData[selectedFontKey], newFontId);
          
        if (dateOnlyChange && inputText && inputText.length > 0) {
            if (window.confirm("Naprawdę chcesz zmienić rozmiar, wszystkie wprowadzone dane zostaną usunięte?")) {
                updatedFormData[textLineKey] = '';
            } else {
                return;
            }
        }
          
        setDodatkowaOplataAlert(extraPaymentRequired);
        setExtraPaymentRequiredOkladka(extraPaymentRequired);
        setFormData(updatedFormData);
    };


    return (
        <div className={style.containerWithTloczneiem}>
           
            <div className={style.mainContainerWithFirstPartOkladki}>
                <div className={style.blockWithinfoTekst}>
                    <p style={{fontWeight: '300'}}>Tekst Linia 1:</p>
                    {<p style={{color: '#989898'}}>{`${line1.length} / ${maxSignsOklakda[`${albumSize.size_height}X${albumSize.size_width}`]} `}</p> }
                </div> 

                <div className={style.containerWithInputAndSelectOkladka}>
                   
                    <div className={style.containerTogetherokladkaInput}>
                        <input placeholder = 'Wpisz tekst...' className = {style.inputsOkladka} type = 'text' value = {line1} onChange={(e) => setFormData((prevState) => ({...prevState, line1: e.target.value}))} maxLength={maxSignsOklakda[`${albumSize.size_height}X${albumSize.size_width}`]}/> 
                        {hasErrors && isSaveAttempted && line1.length <= 0 && <p style={{marginTop: '7px', color:'red'}}>Musisz napisać tekst</p>} 
                        {invalidCharacterLine1 && <p style={{ color: 'red' }}>Nieprawidłowy znak: {invalidCharacterLine1}</p>}
                    </div>
                   
                    <div className={style.containerTogetherokladkaSelect}>
                        <select className={style.selectChoseRozmiarCzcionki} value={selectedFont1 ? selectedFont1 : 'Wybierz rozmiar czcionki'} onChange={(e) => handleFontSizeChange2('selectedFont1', 'line1', e.target.value, line1)}>
                            <option style = {{backgroundColor: '#ffe1c2'}} disabled value="Wybierz rozmiar czcionki">Wybierz rozmiar czcionki</option>
                                {rozmiarCzcionkiOkladka.map((el) => (
                                    <option style = {{backgroundColor: '#ffe1c2'}} key={el.id} value={el.id} disabled={isFontSizeDisabled(el.size)}>
                                        {el.value}
                                    </option>
                                ))}
                        </select> 
                       {hasErrors && isSaveAttempted && !selectedFont1 && <p style={{marginTop: '7px', color:'red'}}>Musisz wybrać rozmiar czcionki</p>} 
                    </div>
                    
                </div>
            </div>

            <div className={style.mainContainerWithFirstPartOkladki}>
                <div className={style.blockWithinfoTekst}>
                    <p style={{fontWeight: '300'}}>Tekst Linia 2:</p>
                    { <p style={{color: '#989898'}}>{`${line2.length} / ${maxSignsOklakda[`${albumSize.size_height}X${albumSize.size_width}`]} `}</p>}
                </div>

                <div className={style.containerWithInputAndSelectOkladka}>
                    <div className={style.containerTogetherokladkaInput}>
                        <input placeholder = 'Wpisz tekst...' className = {style.inputsOkladka} type = 'text' value = {line2} onChange={(e) => setFormData((prevState) => ({...prevState, line2: e.target.value}))} maxLength={maxSignsOklakda[`${albumSize.size_height}X${albumSize.size_width}`]}/>
                        {/* {hasErrors && isSaveAttempted && line2.length <= 0 && <p style={{marginTop: '7px', color:'red'}}>Musisz napisać tekst</p>} */}
                        {invalidCharacterLine2 && <p style={{ color: 'red' }}>Nieprawidłowy znak: {invalidCharacterLine2}</p>}
                    
                    </div>
                    <div className={style.containerTogetherokladkaSelect}>
                        <select className = {style.selectChoseRozmiarCzcionki} value={selectedFont2 ? selectedFont2 : 'Wybierz rozmiar czcionki'} onChange={(e) => handleFontSizeChange2('selectedFont2', 'line2', e.target.value, line2)}>
                            <option style = {{backgroundColor: '#ffe1c2'}} disabled value="Wybierz rozmiar czcionki">Wybierz rozmiar czcionki</option>
                            {rozmiarCzcionkiOkladka.map((el) => (
                                <option style = {{backgroundColor: '#ffe1c2'}} key={el.id} value={el.id} disabled={isFontSizeDisabled(el.size)}>
                                    {el.value}
                                </option>
                            ))}
                        </select> 
                        {hasErrors && isSaveAttempted && !selectedFont2 && <p style={{marginTop: '7px', color:'red'}}>Musisz wybrać rozmiar czcionki2</p>}
                    </div>
                    
                    
                </div>
                
                {dodatkowaOplataAlert && 
                    <div style={{display: 'flex', alignItems: 'center', marginTop: '8px'}}>
                        <img style = {{marginRight: '5px'}} src = {dodatkowaOplataInformator} alt = ''/>
                        <p style={{margin: '0px', color: 'rgb(245, 186, 99)'}}>Będzie wymagana dodatkowa dopłata za wybrany rozmiar czcionki!</p>
                    </div>}
            </div> 

                <div className={style.blockPersonalizacjaColoru}>
                    <p style={{fontWeight: '300'}}>Kolor tłoczenia:</p>
                   <div className={style.colorContainer}>
                        {ColorImagesTloczenie.map((colorItem, idx) => (
                            <div 
                                key={idx} 
                                className={`${style.colorBlock} ${disabledColors.includes(colorItem.color) ? style.disabled : ''}`} 
                                onClick={() => !disabledColors.includes(colorItem.color) && setFormData((prevState) => ({...prevState, selectedColor: colorItem.color }))}
                            >
                                <div className={style.blockWithPhotosColor}>
                                    <img 
                                        src={colorItem.image} 
                                        alt={colorItem.color} 
                                        className={`${style.colorImage} ${formData.selectedColor === colorItem.color ? style.selected : style.notSelected}`}  
                                    />
                                    <label style={{ color: 'black', fontSize: '12px' }} >{colorItem.color}</label>
                                </div>
                            </div>
                        ))}
                        
                    </div>
                    
                    {hasErrors && isSaveAttempted && !selectedColor && <p style={{ color: 'red' }}>Musisz wyprać kolor okładki</p>}
                </div>

                <div >
                {!activeTab.key.startsWith('isCameoAndTloczenie') &&
                    <>
                        <p style={{fontWeight: '300'}}>Położenie tłoczenia:</p>
                        <div className={style.imageContainer} >
                            {localizacjaImages.map((imageObj, idx) => (
                                <div key={idx} className={`${style.imageWrapper} ${lokalizacja === imageObj.name ? style.selected : style.notSelected}`} onClick={() => setLokalizacja(imageObj.name)}>
                                    <img src={imageObj.src} alt={imageObj.name} />
                                    <span style={{marginTop: '5px', fontSize: '12px'}}>{imageObj.name}</span> 
                                </div>
                            ))}
                        </div>  
                    </>
                    
                    }   
                    {hasErrors && isSaveAttempted && !lokalizacja && <p style={{ color: 'red' }}>Musisz wyprać lokalizację</p>}
                </div>
            
        </div>
    );
};

export default TloczenieOkladka;
