import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import style from './MatrycaWizualizator.module.css'
import { AppContext, MainStateModal } from '../../../../context/Contex';
import { matrixSizes,matrixSizesFor10x10,colorsMatrycaWizualizator, lokalizacjaMatrycaWizualizator } from '../../../../constants/dataMatryca/dataMatryca';
import { fontsMappingWizualizator } from '../../../../constants/data';
import { useResponsiveFontSize } from '../../../../hooks/useResponsiveFontSize';

 
const MatrycaWizualizator = () => {

    const {lineHeightMatryca, formData, setIsPopupVisable} = useContext(MainStateModal)
    const {albumSize} = useContext(AppContext)

    const {lineMatryca1,  lineMatryca2, lineMatryca3, lokalizacjaMatryca, selectedColorMatryca, selectedRozmiar, matrycaFontLine1, matrycaFontLine2, matrycaFontLine3} = formData
    const refContainerMatryca = useRef(null)

    const [selectedSizeInPx, setSelectedSizeInPx] = useState({ width: 0, height: 0 });
    const [odstepTexstMatryca, setOdstepTexstMatryca] = useState(0);
    const[szerokoscKontainerMatryca, setSzerokoscKontainerMatryca] = useState({width : ''})
    const [topOffset, setTopOffset] = useState('25%');


    const [fontSizeLineMatryca1, setFontSizeLineMatryca1] = useState("20px");
    const [fontSizeLineMatryca2, setFontSizeLineMatryca2] = useState("20px");
    const [fontSizeLineMatryca3, setFontSizeLineMatryca3] = useState("20px");

    // для того чтобы попап не показывался постоянно как мы уменьшаем текст .
    const [canShowPopupMatryca, setCanShowPopupMatryca] = useState(true);
    const containerRefMatrycaPopup = useRef(null);
    const textBlockRefMatrycaPopup = useRef(null);
    const [isInitialRenderMatryca, setIsInitialRenderMatryca] = useState(true)


    useEffect(() => {
        if(!refContainerMatryca.current) return;

        const containerWidth = refContainerMatryca.current.offsetWidth;
        const albumSizeInCM = albumSize.size_width * 2.50;
        const oneCM = containerWidth / (albumSizeInCM + 1);

        let selectedSize;
        if (albumSizeInCM === 10 ) {
            selectedSize = matrixSizesFor10x10[selectedRozmiar];
        } else {
            selectedSize = matrixSizes[selectedRozmiar];
        }

        if (selectedSize) {
            setSelectedSizeInPx({
                width: selectedSize.width * oneCM,
                height: selectedSize.height * oneCM,
            });
        }

    }, [albumSize, selectedRozmiar, refContainerMatryca, refContainerMatryca.current?.offsetWidth]);
    
    useEffect(() => {
        const updateContainerWidth = () => {
            if (refContainerMatryca.current) {
                const albumSizeInPX = albumSize.size_width * 2.50;
                const containerWidth = refContainerMatryca.current.offsetWidth;
                const oneCM = containerWidth / (albumSizeInPX + 1);
    
                let paddingFactorOkladka;
                switch (albumSizeInPX) {
                    case 30: paddingFactorOkladka = 3.5; break;
                    case 25: paddingFactorOkladka = 3; break;
                    case 20: paddingFactorOkladka = 2; break;
                    case 15: paddingFactorOkladka = 1; break;
                    case 10: paddingFactorOkladka = 1; break;
                    default: paddingFactorOkladka = 1;
                }
                
                const calculatedPaddingMatryca = oneCM * paddingFactorOkladka;
       
                setOdstepTexstMatryca(calculatedPaddingMatryca)

                const odstepOdGlownegoKontainera = 2;
                
                const calculatedOdstępContaineraContent = oneCM * odstepOdGlownegoKontainera
                const szerokoscKontaineraZContentem = containerWidth - calculatedOdstępContaineraContent
                setSzerokoscKontainerMatryca({
                    width: szerokoscKontaineraZContentem
                })
            }
        }
    
        updateContainerWidth();
    
        const resizeObserver = new ResizeObserver(updateContainerWidth);
        resizeObserver.observe(refContainerMatryca.current);
    
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [albumSize, refContainerMatryca]);

    useEffect(() => {
        if (selectedRozmiar === '5x5') {
            switch (albumSize.size_width * 2.5) {
                case 30: setTopOffset('27%'); break;
                case 25: setTopOffset('32%'); break;
                case 20: setTopOffset('37%'); break;
                default: setTopOffset('25%');
            }
        }
    }, [albumSize, selectedRozmiar]);
    
    useLayoutEffect(() => {
        if (isInitialRenderMatryca) {
            setIsInitialRenderMatryca(false);
            setCanShowPopupMatryca(false);
            return;
        }
    
        if (!lineMatryca1 ||!lineMatryca2 ||!lineMatryca3 ||!containerRefMatrycaPopup.current || !textBlockRefMatrycaPopup.current) {
            return;
        }
    
        const checkTextOverflow = () => {
            const container = containerRefMatrycaPopup.current;
            const textBlock = textBlockRefMatrycaPopup.current;
    
            if (!container || !textBlock) {
                return;
            }
    
            if (textBlock.offsetWidth > container.offsetWidth || textBlock.offsetHeight > container.offsetHeight) {
                if (canShowPopupMatryca) {
                    setIsPopupVisable(prev => ({...prev, isPopupVisibleMatryca: true}));
                    setCanShowPopupMatryca(false);
                }
            } else {
                setCanShowPopupMatryca(true);
            }
        };
    
        checkTextOverflow();
    
        const resizeObserver = new ResizeObserver(checkTextOverflow);
        resizeObserver.observe(containerRefMatrycaPopup.current);
        resizeObserver.observe(textBlockRefMatrycaPopup.current);
    
        return () => resizeObserver.disconnect();
    
    }, [canShowPopupMatryca, isInitialRenderMatryca, lineMatryca1, lineMatryca2, lineMatryca3]);


    useResponsiveFontSize(
        refContainerMatryca, 
        [formData.rozmiarCzcionkiLine1, formData.rozmiarCzcionkiLine2, formData.rozmiarCzcionkiLine3], 
        [setFontSizeLineMatryca1, setFontSizeLineMatryca2, setFontSizeLineMatryca3]
    );
    

    const colorsMatryca = colorsMatrycaWizualizator[selectedColorMatryca] || '';
    const lokalizacjaMatrycaWizualizatorr = lokalizacjaMatrycaWizualizator[lokalizacjaMatryca] || 'Middle_Center';
    const topCenterStyle = (lokalizacjaMatryca === 'Top Center' && selectedRozmiar === '5x5') ? { top: topOffset } : {};

    const styles = {
        ...topCenterStyle,
        marginRight: odstepTexstMatryca,
        marginBottom: odstepTexstMatryca,
        width: selectedRozmiar ? `${selectedSizeInPx.width}px` : 'auto',
        height: selectedRozmiar ? `${selectedSizeInPx.height}px` : 'auto',
        border: selectedRozmiar ? '1px solid black' : '',
    }

    const chosenMatrycaFontLine1 = fontsMappingWizualizator[matrycaFontLine1] || '';
    const chosenMatrycaFontLine2 = fontsMappingWizualizator[matrycaFontLine2] || '';
    const chosenMatrycaFontLine3 = fontsMappingWizualizator[matrycaFontLine3] || '';

    return (
        <div className={style.container_matryca} ref = {refContainerMatryca}>
            <div style={{width: szerokoscKontainerMatryca.width}} className={style.containerContent}>
                <div ref = {containerRefMatrycaPopup } className={`${style[lokalizacjaMatrycaWizualizatorr]} ${style[colorsMatryca]}`} style={styles}>
                    <div ref = {textBlockRefMatrycaPopup} className={style.containerText}>
                        <p className={style[chosenMatrycaFontLine1]} style={{ margin: '0px', fontSize: fontSizeLineMatryca1 ,  lineHeight: lineHeightMatryca }}>{lineMatryca1} </p>
                        <p className={style[chosenMatrycaFontLine2]} style={{ margin: '0px', fontSize: fontSizeLineMatryca2 ,  lineHeight: lineHeightMatryca }}>{lineMatryca2} </p>
                        <p className={style[chosenMatrycaFontLine3]} style={{ margin: '0px', fontSize: fontSizeLineMatryca3 ,  lineHeight: lineHeightMatryca }}>{lineMatryca3} </p>
                    </div> 
                </div>
            </div>
            
        </div>
    );
};

export default MatrycaWizualizator;
