import photoVC from '../../assets/MatrycaC.png';
import photoBC from '../../assets/MatrycaBC.png';
import photoBR from '../../assets/MatrycaBR.png';
import photoMR from '../../assets/MatrycaMR.png';
import photoTC from '../../assets/MatrycaTC.png';
import photoMC from '../../assets/MatrycaC.png';
import kolorGoldTłoczenie from '../../assets/embossing_color_gold.png'
import kolorSilverTłoczenie from '../../assets/embossing_color_silver.png'
import kolorWhiteTłoczenie from '../../assets/embossing_color_white.png'

export const overprintFonts = ['Autumn In November (S,G)', 'Ballerina Script', 'Anna Clara', 'Charlotte', 'Endolitta', 'Gardenia', 'Janda Happy Day', 'Kristen ITC', 'My Silly Willy Girl', 'Weather Sunday', 'Peoni Pro', 'Spring is Coming', 'Rouge Script', 'Claudia', 'Hesterica', 'Juice ITP', 'March Lovers', 'King Basil Lite', 'Nattalia', 'Avocado & Lime', 'Champagne & Limousines', 'Argentinian Night', 'Dutch801 Xbd BT', 'You make me smile', 'Monday Line', 'Harrington', 'Munira Script', 'October Moon', 'Salsabilla', 'Exotic850 Bd Bt', 'NsimSun', 'Stylus BT', 'High Tower Text', 'A Gentle Touch', 'KG Neatly Printed', 'KG Neneath - Your Beaoutiful Chunk', 'Love Moment', 'Optimus Princeps']

export const ColorsOverprint = ['Gold', 'Silver', 'White']

export const ColorImagesOverprint = [
  { color: 'White', image: kolorWhiteTłoczenie },
  { color: 'Gold', image: kolorGoldTłoczenie },
  { color: 'Silver', image: kolorSilverTłoczenie },
];

export const overprintColorsWizualizator = {
  Gold: 'gold',
  Silver: 'silver',
  White: 'white',
}
export const ColorCodesOverprint = {
  'White': 'white',
  'Gold': '#c6aa6a',
  'Silver': '#babbbe',
}

export const imageOptionsLokalizacjaOverprint = [
  { img: photoVC, label: 'Visual Center' },
  { img: photoBC, label: 'Bottom Center' },
  { img: photoBR, label: 'Bottom Right' },
  { img: photoMR, label: 'Right Center' },
  { img: photoTC, label: 'Top Center' },
  // { img: photoMC, label: 'Middle Center' }
];

export const lokalizacjaOverprintWizualizator = {
  // 'Middle Center': 'Middle_Center',
  'Visual Center': 'Visual_Center',
  'Top Center': 'Top_Center',
  'Bottom Center': 'Bottom_Center',
  'Bottom Right': 'Bottom_Right',
  'Right Center': 'Right_Center',
}