import React, { useState } from "react";
import { mainInfoAlbum } from "./constants/data";
import { AppContext } from "./context/Contex";
import Effects from "./requests/Effects";
import Modal from './components/modal/Modal'

function App() {

  const [active, setActive] = useState(false);
  const [albumSize, setAlbumSize] = useState({
    size_height: '8',
    size_width: '8'
  });
  
  const [data, setData] = useState(mainInfoAlbum);
  const[showButtonKonfiguracji, setShowButtonKonfiguracji] = useState(false)
  
  console.log('sssss')
  return (
    <AppContext.Provider value = {{albumSize, data, active, setActive, setData, setAlbumSize, setShowButtonKonfiguracji,showButtonKonfiguracji}}>
        <Modal/>
        <Effects/>
        {showButtonKonfiguracji && <button id = 'product-configurator'>Personalizacja albumu</button>}
    </AppContext.Provider>
  );
}

export default App;






