export const photoSizesForCropper = {
    20: { width: 20.5 * 118, height: 26 * 118 },
    25: { width: 25.5 * 118, height: 31 * 118 },
    30: { width: 30.5 * 118, height: 36 * 118 }
};

export const photoSizesForWizualizator = {
    20: { width: 18 * 118, height: 21 * 118 },
    25: { width: 23 * 118, height: 26 * 118 },
    30: { width: 28 * 118, height: 31 * 118 }
};