import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AppContext, MainStateModal } from '../../../../context/Contex';
import style from './CameoAndMatrycaWizualizator.module.css'
import { matrixSizes,matrixSizesFor10x10,colorsMatrycaWizualizator } from '../../../../constants/dataMatryca/dataMatryca';
import {  windowDetails } from '../../../../constants/data'
import { fontsMappingWizualizator } from '../../../../constants/data';
import { useResponsiveFontSize } from '../../../../hooks/useResponsiveFontSize';

const CameoAndMatrycaWizualizator = () => {

    const {formData,windowStyle, urlCameo,setWindowStyle, setIsPopupVisable, rozmiarCzcionkiLine1, rozmiarCzcionkiLine2, rozmiarCzcionkiLine3, lineHeightMatryca} = useContext(MainStateModal)
    const {albumSize} = useContext(AppContext)
    const resizeObserver = useRef(null);
    const containerRefComboMatryca = useRef(null)
    const {
        lineMatryca1, 
        lineMatryca2, 
        lineMatryca3,
        selectedColorMatryca, 
        selectedRozmiar, 
        selectedOpcjaOkienka, 
        selectedtypeCameoOkienko,
        matrycaFontLine1, 
        matrycaFontLine2,
        matrycaFontLine3
    } = formData
    

    const [fontSizeLineMatrycaCaombo1, setFontSizeLineMatrycaCaombo1] = useState("20px");
    const [fontSizeLineMatrycaCaombo2, setFontSizeLineMatrycaCaombo2] = useState("20px");
    const [fontSizeLineMatrycaCaombo3, setFontSizeLineMatrycaCaombo3] = useState("20px");
    
    const [selectedSizeInPx, setSelectedSizeInPx] = useState({ width: 0, height: 0 });
    const [imageStyleZRamka, setImageStyleZRamka] = useState('')
    const [paddingHalfCm, setPaddingHalfCm] = useState(0);

    // для того чтобы попап не показывался постоянно как мы уменьшаем текст .
    const [canShowPopupMatrycaAndCameo, setCanShowPopupMatrycaAndCameo] = useState(true);
    const containerRefMatrycaAndCameoPopup = useRef(null);
    const textBlockRefMatrycaAndCameoPopup = useRef(null);

    //стейт чтобы при первой загрузке компонента не показывался попап, 
    //пока состояние или размеры элементов не изменятся.
    const [isInitialRender, setIsInitialRender] = useState(true)

    const [szerokoscKontaineraWithContensMatryca, setSzerokoscKontaineraWithContentsMatryca] = useState({
        width:0
    })
    const [odstepOdGoryDlaTekstaMatryca, setOdstepOdGoryDlaTekstaMatryca] = useState({
        top: 0,
    })

    const calculateDetails = (windowDetails, albumSizeKey, windowType, oneCM, frameModification = 0, marginModification = 0) => {
        if (!windowDetails[albumSizeKey] || !windowDetails[albumSizeKey][windowType]) {
            return {};
        }
    
        return {
            dimensions: {
                width: (windowDetails[albumSizeKey][windowType].dimensions.width + frameModification) * oneCM,
                height: (windowDetails[albumSizeKey][windowType].dimensions.height + frameModification) * oneCM,
            },
            margins: {
                top: (windowDetails[albumSizeKey][windowType].margins.top - marginModification) * oneCM,
                left: (windowDetails[albumSizeKey][windowType].margins.left - marginModification) * oneCM,
            }
        };
    }
    //liczy odstepy wewnatrz okienka od photo dla z ramką
    const calculateImageMarginsWithFrame = (halfCm) => {
        return {
            top: halfCm / 2,
            left: halfCm / 2,
        };
    }
    //rozmiar okienka z ramka liczy
    const calculateImageSizeWithFrame = (rozmiarOkienka, halfCm) => {
        return {
            width: rozmiarOkienka.width - halfCm,
            height: rozmiarOkienka.height - halfCm,
        };
    }

    const getDimensionsAndMargins = (windowType, albumSize, containerWidth, frameOption) => {
        const albumSizeInPX = albumSize.size_width * 2.50;  
        const albumSizeKey = `${albumSizeInPX}x${albumSizeInPX}`; 
        const oneCM = containerWidth / (albumSizeInPX + 1);
        const halfCm = oneCM / 2;
    
        let frameModification = 0;
        let marginModification = 0;
    
        let details = calculateDetails(windowDetails, albumSizeKey, windowType, oneCM, frameModification, marginModification);
    
        if (frameOption === 'Bez ramki') {
            frameModification = 0; 
            details = calculateDetails(windowDetails, albumSizeKey, windowType, oneCM, frameModification, marginModification);
        }
    
        if (frameOption === 'Z ramką') {
            frameModification = -1; 
            const rozmiarOkienkaImg = calculateImageSizeWithFrame(details.dimensions, halfCm);
            const marginsImg = calculateImageMarginsWithFrame(halfCm);
        
            return { 
                rozmiarOkienka: details.dimensions, 
                margins: details.margins, 
                rozmiarOkienkaImg, 
                marginsImg, 
                halfCm 
            };
        }
    
        return { 
            rozmiarOkienka: details.dimensions, 
            margins: details.margins, 
            halfCm, 
        };
    };

   //liczenie odstepu dla tekstu od samej gory 
    const calculateOverprintPosition = (windowType, albumSize, containerWidth) => {
        const albumSizeInPX = albumSize.size_width * 2.50;  
        const albumSizeKey = `${albumSizeInPX}x${albumSizeInPX}`;
        const oneCM = containerWidth / (albumSizeInPX + 1);
        if (!windowDetails[albumSizeKey] || !windowDetails[albumSizeKey][windowType]) {
            return {}; 
        }
        const top = windowDetails[albumSizeKey][windowType].margins.top * oneCM + windowDetails[albumSizeKey][windowType].dimensions.height * oneCM + 1.5 * oneCM; 
        return {top: `${top}px`};
    };

    useEffect(() => {
        if(!containerRefComboMatryca.current) return

        const updateDimensions = () => {
            if(!containerRefComboMatryca.current) return

            const containerWidth = containerRefComboMatryca.current.offsetWidth;
            const {rozmiarOkienka, margins, rozmiarOkienkaImg, marginsImg, halfCm  } = getDimensionsAndMargins(selectedtypeCameoOkienko, albumSize, containerWidth, selectedOpcjaOkienka);

            //zapisujemy odstepy top left i rozmiary dla okienka
            setWindowStyle({
                    width: `${rozmiarOkienka.width}px`,
                    height: `${rozmiarOkienka.height}px`,
                    marginTop: `${margins.top}px`,
                    marginLeft: `${margins.left}px`,
                    
                }); 
    
                setOdstepOdGoryDlaTekstaMatryca(calculateOverprintPosition(selectedtypeCameoOkienko, albumSize, containerWidth));
    
                if (rozmiarOkienkaImg && marginsImg) {
                    setImageStyleZRamka({
                        width: `${rozmiarOkienkaImg.width}px`,
                        height: `${rozmiarOkienkaImg.height}px`,
                        marginTop: `${marginsImg.top}px`,
                        marginLeft: `${marginsImg.left}px`,
                    });
                } else {
                    setImageStyleZRamka({
                        width: `calc(100% + ${2 * halfCm}px)`,
                        height: `calc(100% + ${2 * halfCm}px)`,
                        position: 'absolute',
                        top: `-${halfCm}px`,
                        left: `-${halfCm}px`,
                        
                    });
                }
                setPaddingHalfCm(halfCm);
            }
        updateDimensions();

        
        if ('ResizeObserver' in window) {
            resizeObserver.current = new ResizeObserver(updateDimensions);
            resizeObserver.current.observe(containerRefComboMatryca.current);
        }

        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }
        };

   },[selectedtypeCameoOkienko, albumSize, containerRefComboMatryca, selectedOpcjaOkienka])
    

   ///////////MATRYCA
    useEffect(() => {
            if (!containerRefComboMatryca.current) return;
        
            const containerWidth = containerRefComboMatryca.current.offsetWidth;
            const albumSizeInCM = albumSize.size_width * 2.50;
            const oneCM = containerWidth / (albumSizeInCM + 1); 
            const odstepOdGlownegoKontaineraMatrcya = 2;

            const calculatedOdstępContaineraContent = oneCM * odstepOdGlownegoKontaineraMatrcya;
            const szerokoscKontaineraZContentemMatryca = containerWidth - calculatedOdstępContaineraContent;

            setSzerokoscKontaineraWithContentsMatryca({
                width: szerokoscKontaineraZContentemMatryca
            })

            let selectedSize;
            if (albumSizeInCM === 10 ) {
                selectedSize = matrixSizesFor10x10[selectedRozmiar];
            } else {
                selectedSize = matrixSizes[selectedRozmiar];
            }
    
            if (selectedSize) {
                setSelectedSizeInPx({
                    width: selectedSize.width * oneCM,
                    height: selectedSize.height * oneCM,
                });
            }

    }, [albumSize, selectedRozmiar, containerRefComboMatryca, containerRefComboMatryca.current?.offsetWidth]);

    const colorsMatryca = colorsMatrycaWizualizator[selectedColorMatryca] || '';
    

    useLayoutEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            setCanShowPopupMatrycaAndCameo(false);
            return;
        }

        if (!lineMatryca1 ||!lineMatryca2 ||!lineMatryca3 || !containerRefMatrycaAndCameoPopup.current || !textBlockRefMatrycaAndCameoPopup.current) {
            return;
        }
    
        const checkTextOverflow = () => {
            const container = containerRefMatrycaAndCameoPopup.current;
            const textBlock = textBlockRefMatrycaAndCameoPopup.current;
    
            if (!container || !textBlock) {
                return;
            }
    
            if (textBlock.offsetWidth > container.offsetWidth || textBlock.offsetHeight > container.offsetHeight) {
                if (canShowPopupMatrycaAndCameo) {
                    setIsPopupVisable(prev => ({...prev, isPopupVisibleMatrycaAndCameo: true}));
                    setCanShowPopupMatrycaAndCameo(false);
                }
            } else {
                setCanShowPopupMatrycaAndCameo(true);
            }
        };
    
        const resizeObserver = new ResizeObserver(checkTextOverflow);
        resizeObserver.observe(containerRefMatrycaAndCameoPopup.current);
        resizeObserver.observe(textBlockRefMatrycaAndCameoPopup.current);
    
        return () => resizeObserver.disconnect();
    }, [canShowPopupMatrycaAndCameo, isInitialRender, lineMatryca1,  lineMatryca2, lineMatryca3]);
    
    // liczenie rozmiaru w kontainerze tekstu przy skalowaniu 
    useResponsiveFontSize(
        containerRefComboMatryca, 
        [formData.rozmiarCzcionkiLine1, formData.rozmiarCzcionkiLine2, formData.rozmiarCzcionkiLine3], 
        [setFontSizeLineMatrycaCaombo1, setFontSizeLineMatrycaCaombo2, setFontSizeLineMatrycaCaombo3]
    );

    const chosenMatrycaFontLine1 = fontsMappingWizualizator[matrycaFontLine1] || '';
    const chosenMatrycaFontLine2 = fontsMappingWizualizator[matrycaFontLine2] || '';
    const chosenMatrycaFontLine3 = fontsMappingWizualizator[matrycaFontLine3] || '';

   

    return (
        <div className={style.cameoMatryca_container} ref = {containerRefComboMatryca}>
            <div className={style.cameoAndMatrycaContentContainer} style={{width: `${szerokoscKontaineraWithContensMatryca.width}px`}}>
                <div className={style.okienko_cameo} style={{...windowStyle, backgroundColor: selectedtypeCameoOkienko ? 'white' : '', overflow: selectedtypeCameoOkienko ? 'hidden' : ''}}>
                    {urlCameo ? 
                        <img className={style.img_Cameo} src={urlCameo} alt="Uploaded" style={imageStyleZRamka}/>
                        : selectedtypeCameoOkienko  ? <div style={{backgroundColor: 'white', width: '100%', height: '100%'}} /> : null
                    }
                </div>

                <div  ref = {containerRefMatrycaAndCameoPopup} className={`${style.matrycaTextContainer}`} style={{...odstepOdGoryDlaTekstaMatryca, width: selectedRozmiar ? `${selectedSizeInPx.width}px` : '', height: selectedRozmiar ? `${selectedSizeInPx.height}px` : '', border: selectedRozmiar ?'1px solid orange' : ''}}>
                    <div className={`${style[colorsMatryca]}`} ref = {textBlockRefMatrycaAndCameoPopup}>
                        <p className={style[chosenMatrycaFontLine1]} style={{ margin: '0px', fontSize: fontSizeLineMatrycaCaombo1 ,  lineHeight: lineHeightMatryca }}>{lineMatryca1} </p>
                        <p className={style[chosenMatrycaFontLine2]} style={{ margin: '0px', fontSize: fontSizeLineMatrycaCaombo2 ,  lineHeight: lineHeightMatryca }}>{lineMatryca2} </p>
                        <p className={style[chosenMatrycaFontLine3]} style={{ margin: '0px', fontSize: fontSizeLineMatrycaCaombo3 ,  lineHeight: lineHeightMatryca }}>{lineMatryca3} </p>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default CameoAndMatrycaWizualizator;

